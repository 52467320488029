import React, { useEffect, useRef } from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import {useImmer} from 'use-immer'
import {Search} from '@styled-icons/fa-solid'

import rippleGIF from '../images/ripple.gif'

// import SearchGroup from '../search/SearchGroup'

import {_getFieldName} from '../helpers'

const onFocus = ({groupList,thisState,setThisState}) => {
  if(groupList){
    const regex = new RegExp(`^.*${thisState.text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')}.*$`, `i`);
    const s = thisState.text ? groupList.filter(v => regex.test(v)) : groupList
    setThisState((d) => {
      d.suggestions = s
      d.show = !0
      d.go = !1
      d.l = s.length - 1
    });
  }
}

const onBlur = ({setThisState}) => {
  setTimeout(function(){
    setThisState((d) => {
      d.show = !1
    });
  },130)
}

const onTextChange = ({e,groupList,setThisState}) => {
  const regex = new RegExp(`${e.target.value.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')}`, `i`);
  const s = e.target.value.length > 0 ? groupList.filter(v => regex.test(v)) : groupList
  setThisState((d) => {
    d.suggestions = s
    d.text = e.target.value
    d.show = !0
    d.go = !1
    d.l = s.length - 1
  });
}

const Typeahead = () => {

  const {map} = useSelector(s=>s.map)
  const {activeTool,toolData} = useSelector(s=>s.tool)
  const app = useSelector(s=>s.app)
  const {featData} = useSelector(s=>s.dat)
  const setApp = useDispatch()
  const {rLang, lang} = useSelector(s=>s.lang)

  const [thisState,setThisState] = useImmer({
    suggestions: [],
    text:'',
    show: !1,
    go:!1,
    h:-1,
    l:0
  });
  const [groupList,setGroupList] = useImmer([])

  const boxLoadRef = useRef();
  const boxLoadedRef = useRef();

  useEffect(()=>{
    if(activeTool !== 'aoiDRAW' && activeTool !== 'aoiZIP' && activeTool !== 'aoi'){
      boxLoadRef.current.style.display = '';
      boxLoadedRef.current.style.display = 'none';
      if(map && toolData.gcList.length){
        // boxLoadRef.current.style.display = '';
        // boxLoadedRef.current.style.display = 'none';
        const col = _getFieldName(activeTool);
        boxLoadRef.current.style.display = 'none';
        boxLoadedRef.current.style.display = '';
      	var d = toolData.gcList,gc = [];
        var i=d.length - 1;
        while (i>=0) {
      		var g = d[i][col];
          if(g.trim() !== '' && g !== null && gc.indexOf(g) === -1){
            gc.push(g);
          }
          i--;
        }

        var aT = activeTool,
        t = (lang === 0 ? 'All ' : 'Semua ') +aT.replace(/_/g,' ')+'s';
        t = aT === 'country' ? (lang === 0 ? 'All ' : 'Semua ')+'countries' : t;
        t = aT === 'luzN' || aT === 'luzF' || aT === 'luzP' || aT === 'luzPB' ? (lang === 0 ? 'All ' : 'Semua ')+'classes' : t;

        if(aT === 'mill'){
          gc.unshift('Loreal');
        }
        gc.sort();
        if(t){
          gc.unshift(t);
        }

        aT = 0;t = 0;
        setGroupList(dr=>dr=gc)
      }
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[map,toolData.gcList])

  useEffect(()=>{
    if(activeTool !== 'aoiDRAW' && activeTool !== 'aoiZIP' && activeTool !== 'aoi'){
      boxLoadRef.current.style.display = 'none';
      boxLoadedRef.current.style.display = '';
    }
    setThisState((d)=>{
      d.text = ''
      d.suggestions = []
      d.show = !1
      d.go = !1
    })
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[featData])

  const suggestionSelected = (value) => {
    if(app.goSearch !== value){
      boxLoadRef.current.style.display = '';
      boxLoadedRef.current.style.display = 'none';
      setThisState((d)=>{
        d.text = value
        d.suggestions = []
        d.show = !1
        d.go = value
        d.h = -1
        d.l = 0
      })
      batch(()=>{
        setApp({type:'RESET_SINGLE_DATA'})
        setApp({type:'_APP',payload:['aSearch',null]})
        setApp({type:'_APP',payload:['single',null]})
        setApp({type:'_APP',payload:['mapClicked',null]})
        setApp({type:'_APP',payload:['viewMap',null]})
        setApp({type:'SEARCH',payload:value})
        setApp({type:'SET_DEFAULT_TAB'})
        setApp({type:'_LOADER',payload:['mapLoader',1]})
      })
    }else{
      setThisState((d)=>{
        d.text = ''
        d.suggestions = []
        d.show = !1
        d.h = -1
        d.l = 0
      })
      batch(()=>{
        setApp({type:'RESET_SINGLE_DATA'})
        setApp({type:'_APP',payload:['aSearch',null]})
        setApp({type:'_APP',payload:['single',null]})
        setApp({type:'_APP',payload:['mapClicked',null]})
        setApp({type:'_APP',payload:['viewMap',null]})
        setApp({type:'_APP',payload:['sgs','group']})
      })

    }
   }

  const renderSuggestions = () => {
    if (!thisState.show) {
      return null;
    }
    const { suggestions } = thisState;
    return (
      <div className="tt-menu" style={{position: 'absolute',top: '100%',left: 0,zIndex: 100}}>
        <div className="tt-dataset tt-dataset-basic-search">
          {
            suggestions.map((g,i) => {
            return (
              <div role="presentation" key={g}
                onClick={(e)=>suggestionSelected(g)}
                className={`tt-suggestion ${thisState.h === i ? 'tt-hover' : ''}`}
              >{g}</div>
            )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <>
      {
        activeTool === 'aoiDRAW' || activeTool === 'aoiZIP' || activeTool === 'aoi' ? (<br/>) : null
      }
      <div id="searchbox-block" style={{display:`${activeTool === 'aoiDRAW' || activeTool === 'aoiZIP' || activeTool === 'aoi' ? 'none' : ''}`}}>
        <div ref={boxLoadRef} id="atlasSbox-loader" className={`margin-top-15px color-white`}><img width="30" height="30" alt="" src={rippleGIF}/> <i>Loading . . .</i></div>
        <div ref={boxLoadedRef} id="the-basics">
          <div className="typeahead-block" style={{position: 'relative',display: 'inline-block',width:'100%'}}>
            <input
              id="atlasSbox"
              onChange={(e)=>onTextChange({e,groupList,setThisState})} autoComplete="off"
              onFocus={() => onFocus({groupList,thisState,setThisState})}
              onBlur={()=>onBlur({setThisState})}
              className="typeahead" type="text"
              placeholder={
                `${lang === 0 ? 'Enter ' : 'Masukkan '}${activeTool === 'oilpalm' || activeTool === 'pulpwood' || activeTool === 'logging' || activeTool === 'mining'
                || activeTool === 'mill' ? (lang === 0 ? 'company name...' : 'nama perusahaan...') :
                (activeTool.indexOf('luz') > -1  ? `${rLang.LandUseZone[lang]}...` :
                ( activeTool === 'road' ? (lang === 0 ? 'road...' : 'nama jalan...') :
                ( activeTool === 'protected_area' ? `${rLang.ProtectedArea[lang]}...` :
                ( activeTool === 'country' || activeTool === 'province' || activeTool === 'district' ? `${rLang[activeTool.charAt(0).toUpperCase() + activeTool.slice(1)][lang]}...` : '...')

                )))}
              `}
              value={thisState.text}
              style={{position: 'relative',verticalAlign: 'top'}}
              onKeyDown={(e)=>{
                if (e.keyCode === 38 && thisState.h > 0) {
                  setThisState( d => {
                    d.h = thisState.h - 1
                  })
                } else if (e.keyCode === 40 && thisState.h < thisState.l) {
                  setThisState( d => {
                    d.h = thisState.h + 1
                  })
                }
                if(e.keyCode === 13){
                  if(thisState.suggestions[thisState.h]){
                    suggestionSelected(thisState.suggestions[thisState.h])
                  }else{
                    suggestionSelected(e.target.value)
                  }
                }
              }}
              />
            {renderSuggestions()}
          </div>
          <button // eslint-disable-line jsx-a11y/control-has-associated-label
           id="company-search-button"><Search size="14"/></button>
          <div id="the-group-list" style={{display:'none'}}></div>
        </div>
        <span role="presentation" id="advance-search-button"
        onClick={()=>{
          batch(()=>{
            setApp({type:"_POPUP_ON_MAP",payload:['title',!1]})
            setApp({type:"_TAB",payload:['activeTab','map']})
            setApp({type:'OPEN_ADVANCED_SEARCH'})
            setApp({type:"INIT_ADVANCED_SEARCH"})
            setApp({type:"RESET_ADVANCED_SEARCH_STATE"})
            setApp({type:"RESET_ADVANCED_SEARCH_FORM"})
          })
        }}
        >+ {rLang.Advancedsearch[lang]}</span>
        <div className="clearfix"></div>
      </div>
    </>
  )

}

export default Typeahead
