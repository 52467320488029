import React,{useEffect} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import axios from 'axios'

import SearchedLayer from './layers/SearchedLayer'

import {equalTo} from 'ol/format/filter'
import {WFS} from 'ol/format'
import * as extent from 'ol/extent'

import {_getFieldName} from '../helpers'
import {getGladData} from '../actions/getGladData'
import {getStatData} from '../actions/getStatData'
import {getRiskData} from '../actions/getRiskData'
import {getTSData} from '../actions/getTSData'

import config from '../config'
import {isPin,togglePin,_flyTo,_hasGroup,_isABoundary} from '../helpers'

const SearchGroup = () => {

  const {hasLink} = useSelector(s=>s.share)
  const setApp = useDispatch()
  const {map} = useSelector(s=>s.map)
  const {activeTool} = useSelector(s=>s.tool)
  const {single,goSearch} = useSelector(s=>s.app)
  const {searchData} = useSelector(s=>s.search)
  const {layers} = useSelector(s=>s.layers)
  const {apiP8080,apiRPC} = config

  useEffect(() => {
    if(goSearch){
      setApp({type:'_LOADER',payload:['mapLoader',true]})
      let wGF = {
        srsName: 'EPSG:4326',
        featurePrefix: 'atlas-workspace',
        featureTypes: [layers[activeTool].sLayer],
        outputFormat: 'application/json',
        // propertyNames: ['cid','areaha','name'],
      };
      if(goSearch.indexOf('All ') < 0 && goSearch.indexOf('Semua ') < 0){
        wGF.filter = equalTo(_getFieldName(activeTool), goSearch);
        if(activeTool === 'mill' && goSearch === 'Loreal'){
          wGF.filter = equalTo('loreal2019', 'Yes');
        }
      }

      axios.post( apiP8080 + 'geoserver/wfs',
      new XMLSerializer().serializeToString(new WFS().writeGetFeature(wGF)),{headers:{'Content-Type': 'text/xml'}})
      .then(function(r){
        console.log('WFS,',r.data);
        if(r.data.features.length){
          let rdf = r.data.features,
          i = rdf.length-1,
          AreaHA = 0,
          cIDs = [],
          ext = new extent.createEmpty();

          setApp({type:'_LOADER',payload:['mapLoader',false]})

          while(i>=0){
            let p = rdf[i].properties;
            extent.extend(ext,rdf[i].bbox)
            AreaHA = AreaHA + p.areaha;
            cIDs.push(p.cid);
            i--;
          }

          batch(()=>{
            setApp({type:'_APP',payload:['cIDs',cIDs]})
            setApp({type:'_APP',payload:['areaha',AreaHA]})
            setApp({type:'_SEARCH',payload:['searchData',r.data.features]})
            setApp({type:'SET_FEAT_DATA',payload:{
              group : {
                areaha: AreaHA,
                id:activeTool,
                featTitle:layers[activeTool].title,
                type: rdf[0].properties.type ? rdf[0].properties.type : (activeTool === 'mining' ? rdf[0].properties.commodity : !1)
              }
            }})
            // setApp({type:'SET_SINGLE_CLICK',payload:data})
            setApp(getGladData({apiRPC,single,sgs:'group',activeTool,goSearch,cIDs,layers}))
            setApp(getStatData({apiRPC,single,sgs:'group',activeTool,goSearch,cIDs,layers}))
            setApp(getRiskData({apiRPC,single,sgs:'group',activeTool,goSearch,cIDs,layers}))
            setApp(getTSData({apiRPC,single,sgs:'group',activeTool,goSearch,cIDs,layers}))
          })

          if(!hasLink && map.getView().getZoom() > 4){
            _flyTo(extent.getCenter(ext), map.getView().getZoomForResolution(map.getView().getResolutionForExtent(ext)) - 0.25, map.getView())
          }else{
            map.getView().animate({
              center: !hasLink ? extent.getCenter(ext) : hasLink.center,
              zoom: !hasLink ? map.getView().getZoomForResolution(map.getView().getResolutionForExtent(ext)) - 0.25 : hasLink.zoom,
              duration: 2500
            },function(){
              // onLoaded()
              if(!isPin()){
                togglePin(map,1)
              }
            });
            if(hasLink){
              setApp({type:'_SHARE',payload:['hasLink',!1]})
            }
          }

        }else{
          console.log('FAILED ON RES',r);
          batch(()=>{
            setApp({type:'_LOADER',payload:['mapLoader',false]})
            setApp({type:'_DATA',payload:['featData',{}]})
          })
        }
      }).catch((e)=>{
        if(e.response){
          batch(()=>{
            setApp({type:'_LOADER',payload:['mapLoader',false]})
            setApp({type:'_DATA',payload:['featData',{}]})
          })
        }
      })

    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[goSearch])

  return !searchData.length || !goSearch ? null :
  (<SearchedLayer map={map} name={'SearchedLayer'} layer={layers[activeTool]} lstyle={'SelectCyan'} params={`${_hasGroup(activeTool) ? 'groupcom' : (_isABoundary(activeTool) ? activeTool : 'name')} IN ('${goSearch}')`}/>)
}
export default SearchGroup
