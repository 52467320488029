import { useEffect } from "react";
import {useSelector,useDispatch} from 'react-redux'
import OLTileLayer from "ol/layer/Tile";
import XYZ from 'ol/source/XYZ'
import {getRenderPixel} from 'ol/render'
import axios from 'axios'
import $ from 'jquery'

import {_getLayer} from  '../../helpers'
import config from '../../config'

let thisLayer = null

const AvsLayer1 = ({ avSwipe, date, type }) => {

  const map = useSelector(s=>s.map.map);
  const {activeTool} = useSelector(s=>s.tool)
  const setApp = useDispatch()

  useEffect(() => {
    if (!map) return;
    setApp({type:'_LOADER',payload:['mapLoader',true]})
    thisLayer = _getLayer(map,'avLayer1');
    if(thisLayer){
      map.removeLayer(thisLayer);
    }

    axios.get(`${config.api}/cgi-bin/geeprocessing.py?date=${date}&vistype=${type}`)
    .then(function(r){

      const id = JSON.parse(r.data.replace(/'/g,"\"").replace(/"s/g,"'").split(', "token"')[0]+"}").mapid;
      // const id = `projects/earthengine-legacy/maps/b0819aaf074ece9a4a5d3deeb680012c-6efcffa733190380833db17f0e762eaf`
      thisLayer = new OLTileLayer({
          source: new XYZ({
          url: `https://earthengine.googleapis.com/v1alpha/${id}/tiles/{z}/{x}/{y}`
        }),
        className:'l-avLayer1',
        name: 'avLayer1'
      });
      thisLayer.on('prerender', function (e) {
        map.updateSize();
        var c = e.context,
        mS = map.getSize(),
        width = parseInt($('#swipeAvs').css('left').toString().replace('px','')),
        gRP = getRenderPixel,
        tl = gRP(e, [width, 0]),
        tr = gRP(e, [mS[0], 0]),
        bl = gRP(e, [width, mS[1]]),
        br = gRP(e, mS);
        c.save();
        c.beginPath();
        c.moveTo(tl[0], tl[1]);
        c.lineTo(bl[0], bl[1]);
        c.lineTo(br[0], br[1]);
        c.lineTo(tr[0], tr[1]);
        c.closePath();
        c.clip();
      });
      thisLayer.on('postrender', function (e) {
        e.context.restore();
      });
      setTimeout(()=>{
        setApp({type:'_LOADER',payload:['mapLoader',false]})
      },2000)

      // $('#avSwipe').fadeIn();

      const l = _getLayer(map,activeTool);
      map.addLayer(thisLayer);
      thisLayer.setZIndex(l ? l.getZIndex() - 1 : 800);

      let h = $('#swipeAvs'),
      mapWidth = $('#ol-map').width(),
      rightPos = (mapWidth/2) + (mapWidth/4),
      leftPos = (mapWidth/2) - (mapWidth/4);
      h.fadeIn().trigger('mousedown').animate({'left':leftPos},500,'swing',function(){
        h.trigger('mousedown').animate({'left':rightPos},500,'swing',function(){
          h.trigger('mousedown').animate({'left':mapWidth/2},500,'swing',function(){

          });
        });
      });
    });
    return () => {
      if (map && thisLayer) {
        map.removeLayer(thisLayer);
      }
    };


  },/*eslint-disable-line react-hooks/exhaustive-deps*/ [map,date,type]);
  return null;
};
export default AvsLayer1;
