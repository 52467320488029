import {useEffect} from "react"
import {useSelector} from 'react-redux'
import XYZ from 'ol/source/XYZ'
import Tile from 'ol/layer/Tile'

import {_getLayer} from '../../helpers'

function toggleZoomLayer(map,showLayers,layer,show){
  if(showLayers.indexOf(layer) > -1){
    _getLayer(map,layer).setVisible(show)
  }
}

const ZoomedLayer = () => {

  const {map} = useSelector(s=>s.map)
  const {showLayers} = useSelector(s=>s.aLayers)

  useEffect(()=>{

    const zoomedLayer = new Tile({
      source: new XYZ({
        attributions: "Tiles © <a rel='noopener' href='https://services.arcgisonline.com/ArcGIS/World_Imagery/MapServer'>ArcGIS</a>",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      }),
      name: 'zoomedLayer'
    })
    const bMap = _getLayer(map,'bMap')

    map.addLayer(zoomedLayer)
    zoomedLayer.setZIndex(0)
    zoomedLayer.setVisible(false)
    // map.on('moveend', function(e) {
    //   console.log('ZOOM',map.getView().getZoom());
    // });

    map.getView().on('change',()=>{
      if(map.getView().getZoom() >= 14){
        zoomedLayer.setVisible(true)
        zoomedLayer.setZIndex(0)
        bMap.setVisible(false)

        toggleZoomLayer(map,showLayers,'landCover',false)
        toggleZoomLayer(map,showLayers,'landcover_transmigration',false)
        toggleZoomLayer(map,showLayers,'landcover_mining',false)
        toggleZoomLayer(map,showLayers,'landcover_plantation',false)
        toggleZoomLayer(map,showLayers,'base_water',false)
        toggleZoomLayer(map,showLayers,'base_road',false)

      }else{
        bMap.setVisible(true)
        bMap.setZIndex(0)
        zoomedLayer.setVisible(false)
        toggleZoomLayer(map,showLayers,'landCover',true)
        toggleZoomLayer(map,showLayers,'landcover_transmigration',true)
        toggleZoomLayer(map,showLayers,'landcover_mining',true)
        toggleZoomLayer(map,showLayers,'landcover_plantation',true)
        toggleZoomLayer(map,showLayers,'base_water',true)
        toggleZoomLayer(map,showLayers,'base_road',true)
      }

    })

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[])


  return null
}
export default ZoomedLayer
