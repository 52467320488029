import axios from 'axios'

export const getTSData = (_t) => {
  return (dispatch) => {

    let cid = '{'+ _t.cIDs.concat() +'}',
    u = _t.layers[_t.single ? _t.single.id : _t.activeTool].unit;

    axios.post(`${_t.apiRPC}atlas_getannualdatabyid`,{unit:u,datatype:'df',cid})
    .then((df)=>{
      axios.post(`${_t.apiRPC}atlas_getannualdatabyid`,{unit:u,datatype:'ip',cid})
      .then((ip)=>{
        axios.post(`${_t.apiRPC}atlas_getannualdatabyid`,{unit:u,datatype:'rd',cid})
        .then((rd)=>{
          dispatch({type:'SET_TS_DATA',payload:_t.sgs === 'single' ? {single:{
            df,ip,rd
          }} : {group:{
            df,ip,rd
          }}})
        })
      })
    })

    // Promise.all([
    //   axios.post(`${_t.apiRPC}atlas_getannualdatabyid`,{unit:u,datatype:'df',cid}),
    //   axios.post(`${_t.apiRPC}atlas_getannualdatabyid`,{unit:u,datatype:'ip',cid}),
    //   axios.post(`${_t.apiRPC}atlas_getannualdatabyid`,{unit:u,datatype:'rd',cid})
    // ])
    // .then(function ([df, ip, rd]) {
    //   dispatch({type:'SET_TS_DATA',payload:_t.sgs === 'single' ? {single:{
    //     df,ip,rd
    //   }} : {group:{
    //     df,ip,rd
    //   }}})
    // });

  }
}
