const yearArray = (i,t,r) => {
  let d=[]
  while(i<=t){
    d.push(r ? i : 0)
    i++
  }
  return d
}

const startYear = 2001
const endYear = 2020

const inits = {
  startYear,
  endYear,
  ycArray: yearArray(startYear,endYear,false),
  cCats: yearArray(startYear,endYear,true),
  y:startYear,
  play: false,
  sat:false,
  tsRasterDF: !1,
  tsRasterIP: !1,
  tsRasterWater: !1,
  tsSatLayer: !1
}

const TsReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_TS':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_TS_STATE':
      return { ...s,
        y:payload.y,
        sat:payload.sat
      }

    default:
      return s;
  }
};
export default TsReducer
