import moment from 'moment'
import config from "../config.js"

let gDt = new Date(),
dpS = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? gDt.getFullYear() -  2 + '-01-01' : gDt.getFullYear() -  1 + '-01-01',
dpE = moment().subtract(14, "days").format('YYYY-MM-DD'),
dSD = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? moment().subtract(100, "days").format('YYYY-MM-DD') : gDt.getFullYear() + '-01-01';

const inits = {
  ...config,
  mapRef: !1,
  sbRef: !1,
  dpS,
  dpE,
  dSD
}

const StaticReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_STATIC':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default StaticReducer
