import {batch} from 'react-redux'
import axios from 'axios'

import {getAdvancedSearchData} from './getAdvancedSearchData'
import aoiResult from './aoiResult'
import {_getFieldName,_isABoundary,_isInt} from '../helpers'
import config from '../config'

let isAvs = !1

function _renameLayers(d,layers) {
  let i = 0, t = d.length,
  l = [],
  l2 = []
  while(i<t){
    if(d[i].id === 'avLayer'){
      isAvs = 1
    }
    if(
      d[i].id && d[i].id !== 'bMap' && d[i].id !== 'shl' && d[i].id !== 'hl-nih' && d[i].id !== 'avLayer'
    ){

      if(d[i].id === 'protectedarea'){
        d[i].id = 'protected_area'
      }
      if(d[i].id === 'millop'){
        d[i].id = 'millPoint'
      }
      if(d[i].id === 'millopbuffer'){
        d[i].id = 'mill'
      }
      if(d[i].id.indexOf('Concession') > 3 ){
        d[i].id = d[i].id.replace('Concession','').toLowerCase()
      }
      if(d[i].id.indexOf('Boundary') > 3 ){
        d[i].id = d[i].id.replace('Boundary','').toLowerCase()
      }
      if(layers[d[i].id]){
        l = [...l,d[i].id]
        l2 = [...l,{id:d[i].id,o:d[i].o}]
      }
    }
    i++;
  }
  return [l,l2]
}

export const setInitData = ({defaultLayers,layers,tool,map,hasLink,dashboard,alert,aoi}) => {
  document.getElementById('atlasSbox-loader').style.display = ''
  document.getElementById('the-basics').style.display = 'none'
  return (dispatch) => {
    const {apiP1} = config
    const col = _getFieldName(tool);
    const sel = _isABoundary(tool) ? tool : 'name'

    dispatch({type:'_TOOL_SCREEN',payload:['tool',!1]})

    if(tool === 'aoi'){

      dispatch(aoiResult({defaultLayers,layers,map,aoi}))

    }else if(tool === 'aoiZIP' || tool === 'aoiDRAW'){
      batch(()=>{
        dispatch({type:'INIT_AVS'})
        dispatch({type:'INIT_DATA'})
        dispatch({type:'INIT'})
        dispatch({type:'SET_START'})
        dispatch({type:'_TOOL',payload:['activeTool',tool]})
        dispatch({type:'_TAB',payload:['activeTab',!hasLink ? 'map' : hasLink.activeTab]})
      })

      setTimeout(()=>{
        if(!hasLink){
          dispatch({type:'SET_START_LAYERS',payload: defaultLayers.default})
        }else{
          // dispatch({type:'SET_START_LAYERS_FROM_LINK',payload: defaultLayers.default})
        }
      },100)

    }else{
      let get = [
        axios.get(`${apiP1}${layers[tool].sLayer}?select=${sel}&order=${sel}.desc&${sel}=neq.null`),
        axios.get(`${apiP1}${layers[tool].sLayer || layers[tool].sLayer}?select=${col}&order=${col}.desc&${col}=neq.null`),
        axios.get(`${apiP1}${layers[tool].sLayer}?select=country&country=neq.null&order=country.desc`)
      ]

      if(tool !== 'country' && tool !== 'province'){
        get = [...get,axios.get(`${apiP1}${layers[tool].sLayer}?select=country,province,district&province=neq.null&district=neq.null&order=district.desc`)]
      }

      if(tool !== 'country'){
        get = [...get,
          axios.get(`${apiP1}${layers[tool].sLayer}?select=province,country&province=neq.null&country=neq.null&order=province.desc`),
        ]
      }

      if(tool === 'protected_area'){
        get = [...get,axios.get(`${apiP1}${layers[tool].sLayer}?select=type&type=neq.null&order=type.desc`)]

      }

      Promise.all(get)
      .then(function (r) {

        const d = {
          nameList: r[0].data,
          gcList: r[1].data,
          countries: r[2].data
        }

        if(tool !== 'country' && tool !== 'province'){
          d.districts = r[3].data
        }

        if(tool !== 'country'){
          if(tool === 'province'){
            d.provinces = r[3].data
          }else{
            d.provinces = r[4].data
          }
        }

        if(tool === 'protected_area'){
          d.types = r[5].data
        }

        batch(()=>{
          dispatch({type:'INIT_DATA'})
          dispatch({type:'SET_TOOL_DATA',payload: d})
          dispatch({type:'INIT'})
          dispatch({type:'SET_START'})
          dispatch({type:'_TOOL',payload:['activeTool',tool]})
          dispatch({type:'_TAB',payload:['activeTab',!hasLink ? 'map' : hasLink.activeTab]})

          if(!hasLink){
            dispatch({type:'INIT_AVS'})
            dispatch({type:'SET_START_LAYERS',payload: defaultLayers.default.concat(defaultLayers[tool])})
          }else{
            dispatch({type:'_BASEMAP',payload:hasLink.bMap})
            dispatch({type:'SET_GLAD_STATE',payload:[
              hasLink.alert.from,
              hasLink.alert.to,
              hasLink.alert.unconfirmed
            ]})
            dispatch({type:'SET_RISK_STATE',payload:{
              from: hasLink.risk[0],
              to: hasLink.risk[1]
            }})
            const sat = hasLink.ts.m ? (hasLink.ts.m.indexOf('tl-sat.jpg') > 3 ? false : (hasLink.ts.m.indexOf('tl-map.jpg') ? true : hasLink.ts.m)) : hasLink.ts.sat
            dispatch({type:'SET_TS_STATE',payload:{
              y: hasLink.ts.y,
              sat
            }})
            const ll = _renameLayers(hasLink.layers,layers)
            dispatch({ type:'SET_START_LAYERS',payload: ll[0] })
            dispatch({ type:'_A_LAYERS',payload: ll[1] })
            if(!hasLink.select.stat) {
              dispatch({type:'_LOADER',payload:['appLoader',false]})
            }
          }

          document.getElementById('atlasSbox-loader').style.display = 'none'
          document.getElementById('the-basics').style.display = ''
        })

        if(hasLink){
          setTimeout(()=>{
            if(hasLink.select.group) {
              dispatch({ type:'_APP',payload:['goSearch',hasLink.select.group] })
            }
            if(hasLink.select.stat) {
              dispatch({type:"_APP",payload:['aSearch',1]})
              dispatch({type:'_SEARCH',payload:['aSearchLoader',true]})
              const a = hasLink.select.stat
              const groupcom = a.groupcom ? a.groupcom.replace('{','').replace('}','').replace(/"/g,'').split(",") :['*']
              const name = a.name ? a.name.replace('{','').replace('}','').replace(/"/g,'').split(",") : []
              const types = a.type ? a.type.replace('{','').replace('}','').replace(/"/g,'').split(",") : ['*']
              const countries = a.country ? a.country.replace('{','').replace('}','').replace(/"/g,'').split(",") : ['*']
              const provinces = a.province ? a.province.replace('{','').replace('}','').replace(/"/g,'').split(",") : ['*']
              const districts = a.district ? a.district.replace('{','').replace('}','').replace(/"/g,'').split(",") : ['*']
              let peatProp = [0,100]
              if(a.peatareaprop){
                const pP = a.peatareaprop.replace('{','').replace('}','').replace(/"/g,'').split(",")
                peatProp = [parseInt(pP[0]),parseInt(pP[1])]
              }
              let forProp = [0,100]
              if(a.remforestprop){
                const fP = a.remforestprop.replace('{','').replace('}','').replace(/"/g,'').split(",")
                forProp = [parseInt(fP[0]),parseInt(fP[1])]
              }

              const aForm = {
                groupcom: groupcom,
                name: name,
                types: a.types || types,
                countries: a.countries || countries,
                regions: a.regions || ['*'],
                provinces: a.provinces || provinces,
                districts: a.districts || districts,
                rpso: a.rpso || !1,
                ispo: a.ispo || !1,
                mspo: a.mspo || !1,
                peatProp: a.peatProp || peatProp,
                forProp: a.forProp ||forProp
              }
              dispatch({type:'SET_ADVANCED_SEARCH_FORM',payload:aForm})
              dispatch(getAdvancedSearchData(aForm,tool,layers,map,hasLink.center,hasLink.zoom))
            }
            if(isAvs){
              dispatch({type:'_AVS',payload:['open1',true]})
              dispatch({type:'_AVS',payload:['type1',(hasLink.ver.b === 'true')]})
              dispatch({type:'_AVS',payload:['date1',hasLink.ver.d]})
              dispatch({type:'_AVS',payload:['hide1',hasLink.ver.i]})
            }
            if(hasLink.avs){
              dispatch({type:'SET_AVS',payload:hasLink.avs})
            }
          },250)
        }

        if(dashboard){
          dispatch({type:'_TAB',payload:['activeTab',alert]})
          if(_isInt(dashboard)){
            dispatch({type:"_APP",payload:['aSearch',!1]})
            dispatch({type:"_APP",payload:['goSearch',!1]})
            dispatch({type:'_APP',payload:['viewMap',dashboard]})
            dispatch({type:'_SEARCH',payload:['min',true]})
          }else{
            dispatch({type:'OPEN_ADVANCED_SEARCH'})
            dispatch({type:"_APP",payload:['aSearch',1]})
            dispatch({type:"_APP",payload:['goSearch',!1]})
            dispatch({type:"_APP",payload:['single',!1]})
            dispatch({type:"SET_ADVANCED_SEARCH_FORM",payload:dashboard})
            dispatch({type:'_LOADER',payload:['appLoader',true]})
            dispatch({type:'_SEARCH',payload:['aSearchLoader',true]})
            dispatch(getAdvancedSearchData(dashboard,tool,layers,map))
          }
        }

      })
      .catch((e)=>{
        console.log(e);
      });
    }

  }
}
