const inits = {
  title:null,
  body:null
}

const PopupLargeReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_POPUP_LARGE':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_POPUP_LARGE':
      return { ...s, ...payload }

    default:
      return s;
  }
};
export default PopupLargeReducer
