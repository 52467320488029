import {useEffect} from 'react'
import TileLayer from 'ol/layer/Tile';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS'

import config from '../../config.js'
import {_getLayer} from  '../../helpers'

const WMTSLayer = ({map, layer, zIndex, WMTSCapabilities}) => {
  useEffect(()=>{
    let tl = _getLayer(map,layer.id)
    if(map && layer){
      if(!tl){
        const opt = optionsFromCapabilities(WMTSCapabilities, {
          layer: layer.layer
        });

        tl = new TileLayer({
          source: new WMTS({
            layer: layer.layer,
            url:`${config.api}${layer.url}`,
            matrixSet: 'EPSG:4326',
            format: 'image/png',
            projection: opt.projection,
            tileGrid: opt.tileGrid
          }),
          opacity: layer.opacity,
          className: 'l-'+layer.id,
          name: layer.id
        });
        if(zIndex){
          tl.setZIndex(zIndex)
        }
        map.addLayer(tl);
      }else{
        tl.setVisible(1);
      }



    }

    return () => {
      if(tl){
        tl.setVisible(!1);
      }
    }
  },/* eslint-disable-line react-hooks/exhaustive-deps */[layer,map,zIndex])
  return null

}
export default WMTSLayer
