import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux'

const MapLoader = () => {
  const {lang} = useSelector(s=>s.lang)
  const mapLoader = useSelector(s=>s.loader.mapLoader)
  const mapRef = useSelector(s=>s.static.mapRef)
  const [load,setLoad] = useState(!1)

  useEffect(() => {
    if(mapLoader){
      setLoad(1);
    }else{
      setTimeout(()=>{
        setLoad(0);
      },250)
    }
  },[mapLoader])

  return (
    <>
      <div style={{display:`${mapLoader ? '' : 'none'}`, width:'100vw',height:'100vh',position:'fixed',top:0,left:0,zIndex: 1000}}/>
      <div id="maploader" style={{
        display: `${load ? '' : 'none'}`,
        width: mapRef?.current?.offsetWidth || 0,right:0,left:'auto',
        opacity:`${mapLoader ? 1 : 0}`
      }}>
        <table className="width-100 height-100"><tbody><tr><td className="vertical-middle text-center">
          {
            mapLoader ? (
              <div className="lds-ripple"><div/><div/></div>
            ): null
          }
          <h3>{lang ? 'SEDANG MEMUAT' : 'LOADING'}</h3>
        </td></tr></tbody></table>
      </div>
    </>
  )
}
export default MapLoader
