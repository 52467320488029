import {useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {Image as ImageLayer, Tile as TileLayer, Group as GroupLayer} from 'ol/layer'
import {optionsFromCapabilities} from 'ol/source/WMTS'

import {_getLayer,_flyTo} from '../../helpers'

function riskOp(ps,d) {
  let p = ps[0];
  if (p[3]) {
    let pv = p[0],
    ratio=pv/100,
    min=0.68,
    max=0.75,
    stretchMin=0.0,
    stretchMax=1.0

    if (ratio<=min){
	    ratio=stretchMin
    }else if(ratio>=max){
	     ratio=stretchMax
    }else{
	     ratio=((ratio-min)*((stretchMax-stretchMin)/(max-min)))
    }

    let r = Math.max(Math.min(255,2*255*ratio),60)
    let g = Math.min(255,2*255*(1-ratio))

    if (r<255){
	     g = Math.min(170,2*255*(1-ratio))
    }
    const b = 0

    if (pv <= d.lm  && pv >= d.lmin) {
      p[0] = r;p[1] = g;p[2] = b;
    }else {
      p[3] = 0;
    }

  }return p;
}

let thisLayer = null

const RiskLayer = () => {

  const {map} = useSelector(s=>s.map)
  const {WMTSCapabilities,_xyz,ll} = useSelector(s=>s.layers)
  const setApp = useDispatch()

  useEffect(()=>{
    if(typeof document !== undefined){
      const c = map.getView().getCenter()
      if( (c[0] < 130.6 || c[0] > 141) ){
        _flyTo([136,-4.269928], 6.5, map.getView())
      }
      thisLayer = _getLayer(map,'riskLayer')
      if(!thisLayer){
        const {Raster,XYZ} = require('ol/source')
        const tsHSTileGrid = optionsFromCapabilities(WMTSCapabilities, {
          layer: 'atlas-workspace:IDNMYSBorneo_SRTMHS30WGS_2000'
        }).tileGrid

        const tsHS = new TileLayer({
          source: new XYZ({
            ..._xyz,
            tileGrid: tsHSTileGrid,
            tileUrlFunction: function(c) {
              return `${ll}IDNMYSBorneo_SRTMHS30WGS_2000/${c[0]}/${c[1]}/${(Math.pow(2, c[0]) - c[2] - 1)}.png`
            }
          }),
        });

        const probdfTileGrid = optionsFromCapabilities(WMTSCapabilities, {
          layer: 'atlas-workspace:RGBProbabilityDF'
        }).tileGrid

        const probdf = new TileLayer({
          source: new XYZ({
            ..._xyz,
            tileGrid: probdfTileGrid,
            tileUrlFunction: function(c) {
              return `${ll}RGBProbabilityDF/${c[0]}/${c[1]}/${(Math.pow(2, c[0]) - c[2] - 1)}.png`
            }
          }),
        });
        probdf.on('prerender', function (e) {
          e.context.imageSmoothingEnabled = false;
      		e.context.msImageSmoothingEnabled = false;
          e.context.webkitImageSmoothingEnabled = false;
      		e.context.mozImageSmoothingEnabled = false;
        });

        const riskRaster = new Raster({
          sources: [probdf],operation: riskOp,
        });
        riskRaster.on('beforeoperations', function (e) {
          e.data.lm = document.querySelector('#risk-slider .s-thumb-1').getAttribute('aria-valuenow');
          e.data.lmin = document.querySelector('#risk-slider .s-thumb-0').getAttribute('aria-valuenow');
    	  });

        thisLayer = new GroupLayer({
    			layers:[
    				tsHS,
            new ImageLayer({
        			opacity: 0.8,source: riskRaster
        		})
    			],
          name: 'riskLayer'
    		});

        map.addLayer(thisLayer)
        thisLayer.setZIndex(800)
        setApp({type:'_LAYERS',payload:['riskRaster',riskRaster]})
      }else{
        thisLayer.setZIndex(800)
        thisLayer.setVisible(true)
      }

    }

    return () => {
      if(thisLayer){
        thisLayer.setVisible(false)
      }
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[])

  return null
}
export default RiskLayer
