const inits = {
  hasLink: !1,
  done: !1,
  share: !1
}

const ShareReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_SHARE':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default ShareReducer
