import React from 'react'
import {useSelector,useDispatch} from 'react-redux'

import {setInitData} from '../../actions/initAction'
import {isPin,togglePin} from '../../helpers'

const setStart = ({setApp,defaultLayers,layers,tool,map,aoiLayerResult}) => {
  if(aoiLayerResult){
    map.removeLayer(aoiLayerResult)
  }
  setApp(setInitData({defaultLayers,layers,tool}))
  if(!isPin()){
    togglePin(map)
  }
}

const TSaoi = () => {
  const {map} = useSelector(s=>s.map)
  const {rLang, lang} = useSelector(s=>s.lang)
  const toolScreen = useSelector(s=>s.toolScreen)
  const setApp = useDispatch()
  const { layers, defaultLayers } = useSelector(s=>s.layers);
  const {aoiLayerResult} = useSelector(s=>s.aoi)

  return (
    <>
      <div id="tool-info-mill" className="tool-info" style={toolScreen.tool !== 'aoi' ? css.hide : css.show}>
        <div className="tool-bg"/>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                <div className="tool-info-title">
                  {rLang.ToolAoiTitle[lang]}
                </div>

                <div className="tool-info-desc">
                  {rLang.ToolAoiDesc[lang]}
                </div>

                <button onClick={()=>{
                  setStart({setApp,tool:'aoiZIP',layers,defaultLayers,map,aoiLayerResult})
                }} className="start" style={{padding:15,marginRight:30}}>{rLang.TaoiZIP[lang]}</button>

                <button onClick={()=>{
                  setStart({setApp,tool:'aoiDRAW',layers,defaultLayers,map,aoiLayerResult})
                }} className="start" style={{padding:15}}>{rLang.TaoiDRAW[lang]}</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const css = {
  show: {
    opacity:1,
    zIndex:1,
    height:'auto',
    overflow:'auto'
  },
  hide: {
    opacity:0,
    zIndex:-1,
    height:0,
    overflow:'hidden'
  }
}

export default TSaoi
