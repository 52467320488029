import React, { useRef, useEffect } from 'react'
import {useSelector,useDispatch} from 'react-redux'

import SbSubTitle from './SbSubTitle'
import Typeahead from './Typeahead'
import ToolMenu from './menus/ToolMenu'
import Tabs from './tabs/Tabs'
import PinIcon from './parts/PinIcon'

import flagEN from '../images/flag-en.png'
import flagID from '../images/flag-id.png'

const Sidebar = ({store,loc}) => {

  const {rLang,lang} = useSelector(s=>s.lang)
  const setApp = useDispatch()

  const sbRef = useRef()

  useEffect(()=>{
    setApp({type: '_STATIC',payload:['sbRef',sbRef]})
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[]);

  return (
    <>
      <div id="sidebar" ref={sbRef}>
        <div id="active-tool-title">
          <span style={{fontSize:`${lang ? '15px' : 'inherit'}`}}>{rLang.AppName[lang]}</span>
          <PinIcon/>
        </div>

        <div id="sb-content">
          <div className="position-relative height-100">
            <div id="sidebar-content" className="this-overflow">
              <div id="sb-content-top">
                <div className="display-table width-100">
                  <div className="table-cell vertical-top">
                    <div className="sidebar-map-tool">
                      <h1 id="sb-map-tool-sub" className="sb-main-title">
                        <SbSubTitle/>
                      </h1>
                    </div>
                  </div>
                  <div className="table-cell vertical-top text-right">
                    <div id="lang-switcher">
                      <b role="presentation" onClick={()=>{
                        setApp({type:'_LANG',payload:['lang',0]})
                      }} className={`${lang === 0 ? 'active' : ''}`}><img src={flagEN} alt="" style={{marginRight:12}}/></b>
                      <b role="presentation" onClick={()=>{
                        setApp({type:'_LANG',payload:['lang',1]})
                      }} className={`${lang === 1 ? 'active' : ''}`}><img src={flagID} alt=""/></b>
                    </div>
                  </div>

                </div>

                <Typeahead/>

              </div>
            </div>
          </div>
        </div>

        <ToolMenu/>

        <div id="sb-bottom-menu">
          <ul className="list-inline">
            <li role="presentation" onClick={()=>setApp({type:'_POPUP',payload:['popup','about']})} className="bottom-menu">{rLang.About_Map[lang]}</li>
            <li role="presentation" onClick={()=>setApp({type:'_POPUP',payload:['popup','howto']})} className="bottom-menu">{rLang.How_to[lang]}</li>
            <li role="presentation" onClick={()=>setApp({type:'_POPUP',payload:['popup','feedback']})} className="bottom-menu">{rLang.Feedback[lang]}</li>
            <li role="presentation" onClick={()=>setApp({type:'_POPUP',payload:['popup','sharethis']})} className="bottom-menu">{rLang.Share_this[lang]}</li>
            <li role="presentation" onClick={()=>setApp({type:'_POPUP',payload:['popup','termsofuse']})} className="bottom-menu">{rLang.Terms_of_use[lang]}</li>
          </ul>
        </div>



        <Tabs/>
      </div>

    </>
  )

}
export default Sidebar;
