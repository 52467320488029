const inits = {
  groupcom: ['*'],
  name: [],
  types: ['*'],
  countries: ['*'],
  provinces: ['*'],
  districts: ['*'],
  regions: ['*'],
  rpso: !1,
  ispo: !1,
  mspo: !1,
  peatProp: [0,100],
  forProp:[0,100]
}

const ASearchReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_ADVANCED_SEARCH':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_ADVANCED_SEARCH_FORM':
      return { ...s, ...payload}

    case 'RESET_ADVANCED_SEARCH_FORM':
      return { ...s, ...inits}

    default:
      return s;
  }
};
export default ASearchReducer
