const config = {
  "name": "Indonesian Atlas",
  "email": "info@reggarantai.com",
  "title": [
    "Atlas of Deforestation and Industrial Plantations in Indonesia",
    "Atlas Deforestasi dan Perkebunan Industri di Indonesia"
  ],
  "desc": [
    "This online atlas is designed to help organizations like the ISPO and RSPO improve transparency and accountability of oil palm and pulpwood plantations, as well as help nations and companies keep better track of their zero net deforestation commitments.",
    "Atlas online ini dirancang untuk membantu organisasi seperti ISPO dan RSPO untuk meningkatkan transparansi dan akuntabilitas perkebunan kelapa sawit dan kayu pulp, serta membantu negara dan perusahaan untuk melacak komitmen nol deforestasi mereka dengan lebih baik."
  ],
  // "base_url": "https://nusantara-atlas.com/",
  // "base_url": "https://dev.nusantara-atlas.com/",
  // "base_url": "http://atlas.localhost.com:8888/",
  "base_url": "https://atlas.reggarantai.com/",
  "api": "https://nusantara-atlas.org",
  "apiP1": "https://nusantara-atlas.org/dbrest/",
  "apiP2": "https://nusantara-atlas.org/dbrestrw/",
  "apiP8080": "https://nusantara-atlas.org/",
  "apiRPC": "https://nusantara-atlas.org/dbrest/rpc/",
  // "apiLink": "https://nusantara-atlas.org/",
  // "apiLink": "https://api.nusantara-atlas.org/",
  "apiLink": "https://atlas.reggarantai.com/",
  // "apiLink": "http://atlas.localhost.com:8888/",
  "lang": 0,
  "mapOptions": {
    "center": [128.56172445299114,-0.7020556141259279],
  	"zoom": 5,
  	"maxZoom": 15,
  	"projection": "EPSG:4326"
  },
  "hlStyle": {
    "f": "rgba(255, 255, 255, 0)",
    "s": "rgba(0, 232, 255, 1)",
    "w": 1.5
  },
  "shlStyle": {
    "f": "rgba(255, 255, 255, 0)",
    "s": "rgba(222, 97, 50, 1)",
    "w": 1.5
  }
}
export default config
