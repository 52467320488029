import React,{useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useImmer} from 'use-immer'
// import shp from 'shpjs'
import axios from 'axios'

const loadShpZip = ({v,setState}) => {

  // fetch('/AOISample.zip')
  // .then(function (response) {
  //       return Promise.resolve(response.blob());
  //   // console.log('response.blob()',response.blob());                     // 2) filter on 200 OK
  //   // if (response.status === 200 || response.status === 0) {
  //   //     return Promise.resolve(response.blob());
  //   // } else {
  //   //     return Promise.reject(new Error(response.statusText));
  //   // }
  // })
  // .then(function success(text) {
  //   // console.log('response.blob()',text.arrayBuffer())                    // 5) display the result
  //     shp(text.arrayBuffer()).then(function(geojson){
  //       console.log('geojson',geojson);  //see bellow for whats here this internally call shp.parseZip()
  //     });
  // })
  // axios.get('/AOISample.zip')
  // .then((r)=>{
  //   console.log('r.data',r);
  // })
  // if(typeof Buffer !== undefined){
  //   shp('/AOISample.zip').then(function(geojson){
  //     console.log('geojson',geojson);  //see bellow for whats here this internally call shp.parseZip()
  //   });
  // }

  // var reader = new FileReader();
  // reader.onload = function() {
  //
  //   var arrayBuffer = this.result,
  //     array = new Uint8Array(arrayBuffer),
  //     binaryString = String.fromCharCode.apply(null, array);
  //
  //   console.log('arrayBuffer',shp.parseZip(arrayBuffer));
  //   // console.log('arrayBuffer',arrayBuffer);
  //
  //   shp(arrayBuffer).then(function(geojson){
  //     console.log('geojson',geojson);  //see bellow for whats here this internally call shp.parseZip()
  //   });
  //
  // }
  // reader.readAsArrayBuffer(v);

}

const AoiZip = () => {

  const {activeTool} = useSelector(s=>s.tool)
  const [state,setState] = useImmer({
    uploaded: false,
    error: false
  })

  useEffect(()=>{
    if(activeTool === 'aoiZIP'){



      return () => {
        setState(d=>{
          d.uploaded = false
          d.error = false
        })
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[activeTool])

  return (
    <>
    <div id="uzip" className="udraw" style={{display:activeTool === 'aoiZIP' ? '' : "none"}}>
      <div className="udraw-text">
        Select a shapefile and related files (e.g *.dbf, *.prj, *.shx) compressed in zip format.
      </div>
      <div className="form-group-lg">
        <input type="email" id="uzip-email" placeholder="your@email.com" className="form-control" style={{display:state.uploaded ? '' : 'none'}}/>
      </div>
      <div id="uzip-error" style={{display:state.error ? '' : "none"}} className="alert alert-danger">{state.error || ''}</div>
      <div id="uzip-submit" className="btn btn-default" style={{display:state.uploaded ? '' : 'none'}}>
        Submit for processing
      </div>
      <form encType="multipart/form-data" method="post" id="uzip-btn">
        <label htmlFor="uzip-file" className="file-upload btn btn-default">
          <span id="uzip-text">Load</span> shapefile <input onChange={(e)=>{
            loadShpZip({v:e.target.files[0],setState});
          }} id="uzip-file" type="file" name="file"/>
        </label>
      </form>
    </div>

    </>
  )
}
export default AoiZip
