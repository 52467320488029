import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import {Fill, Stroke, Style} from 'ol/style';

import {_getLayer} from  '../../helpers'

const ClickedLayer = ({map,name,geom}) => {

  const {activeLayers} = useSelector(s=>s.aLayers)
  // const setApp = useDispatch()

  useEffect(()=>{
    if((name || geom) && typeof window !== undefined){

      let tl = _getLayer(map,'ClickedLayer')
      if(activeLayers.indexOf('ClickedLayer') > -1){
        // setApp({type:'_A_LAYERS',payload:['activeLayers',[...activeLayers.filter(t => t !== 'ClickedLayer')]]})
        if(tl){
          map.removeLayer(tl);
        }
      }

      if(geom.type === 'MultiPolygon'){
        const L = new VectorLayer({
          name: name,
          source: new VectorSource({
            features: [new Feature({
              geometry: new MultiPolygon(geom.coordinates),
            })]
          }),
          style:new Style({
            fill: new Fill({color: 'rgba(255, 255, 255, 0)'}),
            stroke: new Stroke({color: 'rgba(255, 247, 0, 1)',width: 3})
          }),
        });

        map.addLayer(L);
        L.setOpacity(1)
        L.setZIndex(2000);



        if(activeLayers.indexOf(name) === -1){
          // setApp({type:'_A_LAYERS',payload:['activeLayers',[...activeLayers,name]]})
        }

      }

    }

    return () => {
      let tl = _getLayer(map,'ClickedLayer')
      if(tl){
        // setApp({type:'_A_LAYERS',payload:['activeLayers',[...activeLayers.filter(t => t !== 'ClickedLayer')]]})
        map.removeLayer(tl);
      }
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[map,name,geom])
  return null

}
export default ClickedLayer
