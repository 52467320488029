import { useEffect } from "react";
import {useSelector,useDispatch} from 'react-redux'
import OLTileLayer from "ol/layer/Tile";
import XYZ from 'ol/source/XYZ'
import axios from 'axios'

import {_getLayer} from  '../../helpers'
import config from '../../config'

let thisLayer = null

const AvsLayer2 = ({ avSwipe, date, type }) => {

  const map = useSelector(s=>s.map.map);
  const {activeTool} = useSelector(s=>s.tool)
  const setApp = useDispatch()

  useEffect(() => {
    if (!map) return;
    setApp({type:'_LOADER',payload:['mapLoader',true]})
    thisLayer = _getLayer(map,'avLayer2');
    if(thisLayer){
      map.removeLayer(thisLayer);
    }

    axios.get(`${config.api}/cgi-bin/geeprocessing.py?date=${date}&vistype=${type}`)
    .then(function(r){

      const id = JSON.parse(r.data.replace(/'/g,"\"").replace(/"s/g,"'").split(', "token"')[0]+"}").mapid;
      // const id = `projects/earthengine-legacy/maps/3ba1c98abe5093cf7871e7e18468c571-6bea4fc1d8dfea2665914dd49cd0b3cb`
      thisLayer = new OLTileLayer({
          source: new XYZ({
          url: `https://earthengine.googleapis.com/v1alpha/${id}/tiles/{z}/{x}/{y}`
        }),
        className:'l-avLayer2',
        name: 'avLayer2'
      });
      thisLayer.on('prerender', function (e) {
        map.updateSize();
        var c = e.context,
        mS = map.getSize(),
        width = parseInt(avSwipe.css('left').toString().replace('px',''))
        c.save();
        c.beginPath();
        c.rect(0, 0, width, mS[1]);
        c.clip();
      });
      thisLayer.on('postrender', function (e) {
        e.context.restore();
      });
      setTimeout(()=>{
        setApp({type:'_LOADER',payload:['mapLoader',false]})
      },2000)

      const l = _getLayer(map,activeTool);
      map.addLayer(thisLayer);
      thisLayer.setZIndex(l ? l.getZIndex() - 1 : 800);

    });
    return () => {
      if (map && thisLayer) {
        map.removeLayer(thisLayer);
      }
    };
  },/*eslint-disable-line react-hooks/exhaustive-deps*/ [map,date,type]);
  return null;
};
export default AvsLayer2;
