import React from 'react'
const TimeLapseIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 28.11">
      <g id="Group_256" data-name="Group 256" transform="translate(-480.578 -253.89)">
        <g id="Group_179" data-name="Group 179" transform="translate(522 -859)">
          <g id="Rectangle_109" data-name="Rectangle 109" style={{stroke:color,strokeLinecap: 'round',strokeLinejoin: 'round',strokeWidth: '0.7px'}} transform="translate(-39 1124)">
            <rect className="cls-4" width="4" height="8"/>
            <rect fill="none" x="0.35" y="0.35" width="3.3" height="7.3"/>
          </g>
          <g id="Rectangle_110" data-name="Rectangle 110" style={{stroke:color,strokeLinecap: 'round',strokeLinejoin: 'round',strokeWidth: '0.7px'}} transform="translate(-34 1126)">
            <rect className="cls-4" width="4" height="6"/>
            <rect fill="none" x="0.35" y="0.35" width="3.3" height="5.3"/>
          </g>
          <g id="Rectangle_111" data-name="Rectangle 111" style={{stroke:color,strokeLinecap: 'round',strokeLinejoin: 'round',strokeWidth: '0.7px'}} transform="translate(-29 1121)">
            <rect className="cls-4" width="4" height="11"/>
            <rect fill="none" x="0.35" y="0.35" width="3.3" height="10.3"/>
          </g>
          <g id="Rectangle_112" data-name="Rectangle 112" style={{stroke:color,strokeLinecap: 'round',strokeLinejoin: 'round',strokeWidth: '0.7px'}} transform="translate(-24 1116)">
            <rect className="cls-4" width="4" height="16"/>
            <rect fill="none" x="0.35" y="0.35" width="3.3" height="15.3"/>
          </g>
          <path id="Path_79" data-name="Path 79" style={{stroke:color,fill:'none',strokeLinecap: 'round',strokeLinejoin: 'round',strokeWidth: '0.7px'}} d="M-39,1121l5.743,1.454,3.308-3.368,2.887-1.564,2.1-3.248"/>
          <path id="Path_80" data-name="Path 80" fill={color} d="M-26.173,1113.519l2.384,1.457-.2-2.086Z"/>
        </g>
        <text id="_2001-Now" data-name="2001-Now" style={{fill:color,fontSize: '6px',fontFamily: 'Roboto Condensed'}} transform="translate(492.578 281)"><tspan x="-11.424" y="0">2001-Now</tspan></text>
      </g>
    </svg>
  )
}
export default TimeLapseIcon
