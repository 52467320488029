import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

import config from '../../config.js'
import {_getLayer,_hasGroup,_isABoundary} from  '../../helpers'

// function imagePostFunction(image, src) {
// var img = image.getImage();
// if (typeof window.btoa === 'function') {
//   var xhr = new XMLHttpRequest();
//   //GET ALL THE PARAMETERS OUT OF THE SOURCE URL
//   var dataEntries = src.split("&");
//   var url;
//   var params = "";
//   for (var i = 0 ; i< dataEntries.length ; i++){
//       if (i===0){
//       url = dataEntries[i];
//       }
//       else{
//       params = params + "&"+dataEntries[i];
//       }
//   }
//   xhr.open('POST', url, true);
//
//   xhr.responseType = 'arraybuffer';
//   xhr.onload = function(e) {
//     if (this.status === 200) {
//       var uInt8Array = new Uint8Array(this.response);
//       var i = uInt8Array.length;
//       var binaryString = new Array(i);
//       while (i--) {
//         binaryString[i] = String.fromCharCode(uInt8Array[i]);
//       }
//       var data = binaryString.join('');
//       var type = xhr.getResponseHeader('content-type');
//       if (type.indexOf('image') === 0) {
//         img.src = 'data:' + type + ';base64,' + window.btoa(data);
//       }
//     }
//   };
//   //SET THE PROPER HEADERS AND FINALLY SEND THE PARAMETERS
//   xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
//   // xhr.setRequestHeader("Content-length", params.length);
//   // xhr.setRequestHeader("Connection", "close");
//   xhr.send(params);
// } else {
//   img.src = src;
// }
// }

let tl = !1

const SearchedLayer = ({map, layer, params, lstyle, zIndex, name, advanced, activeTool}) => {

  const {activeLayers} = useSelector(s=>s.aLayers)
  const s = useSelector(s=>s.aSearch)
  // const setApp = useDispatch()

  useEffect(()=>{
    if(map && layer){
      tl = _getLayer(map,'SearchedLayer')
      if(activeLayers.indexOf('SearchedLayer') > -1){
        // setApp({type:'_LAYERS',payload:['activeLayers',[...activeLayers.filter(t => t !== 'SearchedLayer')]]})
        if(tl){
          map.removeLayer(tl);
        }
      }

      tl = new TileLayer({
        source: new TileWMS({
          url:`${config.apiP8080}${layer.url}`,
          params: {'LAYERS': `${layer.layer}v`, 'TILED': true, 'VERSION': '1.1.0'},
          serverType: 'geoserver',
          crossOrigin: 'anonymous',
          // tileLoadFunction: function(image, src) {
          //   imagePostFunction(image, src);
          // }
        }),
        opacity: layer.opacity,
        visible:1,
        className: 'l-'+layer.id+' l-search',
        name
      })
      tl.setOpacity(1)
      if(zIndex){
        tl.setZIndex(zIndex)
      }
      if(advanced){

        let filter = [],
        rm = []
        if(s.groupcom.length){
          rm = [...s.groupcom.filter(t => t !== '*')]
          if(rm.length){
            if(rm.indexOf('Loreal') > - 1){
              rm = [...s.groupcom.filter(t => t !== 'Loreal')]
              filter = [...filter,['loreal2019','Yes']]
            }
            if(rm.length){
              filter = [...filter,[_hasGroup(activeTool) ? 'groupcom' : (_isABoundary(activeTool) ? activeTool : 'name'),rm]]
            }
          }
        }
        if(activeTool === 'protected_area' && s.types.length){
          rm = [...s.types.filter(t => t !== '*')]
          if(rm.length){
            filter = [...filter,['type',rm]]
          }
        }
        if(s.name.length){
          filter = [...filter,['name',s.name]]
        }
        if(s.countries.length){
          rm = [...s.countries.filter(t => t !== '*')]
          if(rm.length){
            filter = [...filter,['country',rm]]
          }
        }

        if(s.regions.length){
          rm = [...s.regions.filter(t => t !== '*')]
          if(rm.length){
            filter = [...filter,['region',rm]]
          }
        }

        if(s.provinces.length){
          rm = [...s.provinces.filter(t => t !== '*')]
          if(rm.length){
            filter = [...filter,['province',rm]]
          }
        }
        if(s.districts.length){
          rm = [...s.districts.filter(t => t !== '*')]
          if(rm.length){
            filter = [...filter,['district',rm]]
          }
        }
        if(s.peatProp[0] > 0 || s.peatProp[1] < 100){
          filter = [...filter,['peatareaprop',s.peatProp]]
        }
        if(s.forProp[0] > 0 || s.forProp[1] < 100){
          filter = [...filter,['remforestprop',s.forProp]]
        }

        let w = filter.length - 1,
        thisFilter = '';

        while(w>=0){
          if(Array.isArray(filter[w][1])){
            if(filter[w][0] === 'peatareaprop' || filter[w][0] === 'remforestprop'){
              thisFilter += `${filter[w][0]} BETWEEN ${filter[w][1][0]} AND ${filter[w][1][1]}`
            }else{
              thisFilter += `${filter[w][0]} IN (${filter[w][1].map(d => `'${d}'`).join(',')})`
            }
          }else{
            thisFilter += `${filter[w][0]}='${filter[w][1]}'`
          }
          if(w>0) {
            thisFilter += ' AND '
          }
          w--;
        }

        let p = tl.getSource().getParams();
        p = thisFilter ? {...p,CQL_FILTER:thisFilter,STYLES:lstyle} : {...p,STYLES:lstyle};
        tl.getSource().updateParams(p);
        tl.setZIndex(1000);

      }else{
        if(params){
          let p2 = tl.getSource().getParams();
          p2 = params.indexOf('All ') === -1 ? {...p2,CQL_FILTER:params,STYLES:lstyle} : {...p2,STYLES:lstyle};
          tl.getSource().updateParams(p2);
          tl.setZIndex(1000);
        }
      }

      map.addLayer(tl);
      if(activeLayers.indexOf(name) === -1){
        // setApp({type:'_LAYERS',payload:['activeLayers',[...activeLayers,name]]})
      }

    }

    return () => {
      if(tl){
        map.removeLayer(tl);
      }
      // setApp({type:'_LAYERS',payload:['activeLayers',[...activeLayers.filter(t => t !== 'SearchedLayer')]]})
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[layer,map,params,lstyle,zIndex])
  return null

}
export default SearchedLayer
