import {batch} from 'react-redux'
import * as ol from "ol";
import axios from 'axios'
import {ZoomSlider,OverviewMap,MousePosition,ScaleLine} from 'ol/control';
import Tile from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import WMTSCapabilities from 'ol/format/WMTSCapabilities'
import {createStringXY} from 'ol/coordinate'

import {setInitData} from '../actions/initAction'

import config from '../config'
import {_getUrlParameter} from '../helpers'

function getAllLayers(layers){
  let all = {}
  layers.map(function(l){
    return all[l.id] = {...l,show: !1}
  })
  return all;
}

const loadMap = (_t) => {
  return (dispatch) => {

    let init = [
      axios.get(`${config.apiLink}layers`)
    ],hasLink = !1

    const sLink = _getUrlParameter('s',window.location)
    if(sLink){
      init = [...init,axios.get(`${config.apiP2}sharelink?select=share&id=eq.${sLink}`)]
    }

    Promise.all(init)
    .then((res) => {

      if(sLink && res[1]){
        if(res[1].data.length){
          hasLink = JSON.parse(res[1].data[0].share)
          console.log('sLink',hasLink);
          dispatch({type:'_SHARE',payload:['hasLink',hasLink]})
        }else{
          // dispatch({type:'_SHARE',payload:['hasLink',!1]})
        }
      }


      if(res[0].data){
        const r = res[0]
        let mapObject = new ol.Map({
          view: new ol.View({
            center: [118.56172445299114,-0.9020556141259279],
            zoom: 5,
            maxZoom: 20,
            projection: 'EPSG:4326'
          }),
          layers: [
            new Tile({
              source: new XYZ({
                attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/Canvas/World_Dark_Gray_Base/MapServer">ArcGIS</a>',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}'
              }),
              zIndex: 0,
              name: 'bMap'
            })
          ],
          overlays: [],
        });

        mapObject.setTarget('ol-map');

        mapObject.addControl(new ZoomSlider())
        mapObject.addControl(new OverviewMap({
          layers: [new Tile({
             source: new XYZ({url:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'})
          })],
          collapsed: false,
          collapseLabel: '»',
          label: '«'
        }))

        let curCord = new MousePosition({
          coordinateFormat: createStringXY(3),
          projection: 'EPSG:4326',
          target: document.getElementById('cursorCord'),
          undefinedHTML: 'Coordinates of cursor'
        });mapObject.addControl(curCord);

        mapObject.addControl(new ScaleLine({
          units: 'metric',
          bar: true,
          steps: 4,
          text: false,
          target: document.getElementById('scalebar')
        }))

        mapObject.on('singleclick',function(e){
          dispatch({type:'_APP',payload:['mapClicked',e]});
        })

        Promise.all([
          axios.get(`${config.apiP8080}geoserver/gwc/service/wmts/1.0.0/?REQUEST=getcapabilities`),
          axios.get(`${config.apiP1}vregionlist`)
        ])
        .then((r2)=>{

          batch(()=>{
            dispatch({type:'SET_DASHBOARD_REGION_LIST',payload:r2[1].data})
            dispatch({type:'SET_LAYER_PARSER',payload:[
              new WMTSCapabilities().read(r2[0].data),
              {
                matrixSet: 'EPSG:4326',
                format: 'image/png',
                projection: 'EPSG:4326',
                crossOrigin: 'anonymous',
              },
              `${config.apiP8080}geoserver/gwc/service/tms/1.0.0/atlas-workspace:`
            ]})

            dispatch({type:'_MAP',payload:['map',mapObject]});
            dispatch({type:'_STATIC',payload:['mapRef',_t.mapRef]});
            dispatch({type:'SET_LAYERS',payload:r.data})

            if(_t.aoi){
              console.log('AOI_DATA',_t.aoi);
              dispatch(setInitData({
                defaultLayers: r.data.init,
                layers: getAllLayers(r.data.layers),
                tool: 'aoi',
                map: mapObject,
                aoi: _t.aoi
              }))
            }else{
              dispatch(setInitData({
                defaultLayers:r.data.init,
                layers:getAllLayers(r.data.layers),
                tool:!hasLink ? 'oilpalm' : hasLink.activeTool.replace('-','_'),
                map:mapObject,
                hasLink
              }))
              if(!hasLink && !_getUrlParameter('embed',window.location)){
                dispatch({type:'_WELCOME',payload:['welcomeScreen',true]})
              }
              if(!hasLink){
                dispatch({type:'_LOADER',payload:['appLoader',false]})
              }
            }


          })

        });

      }
    })

  }
}

export const setMap = (_t) => {
  return (dispatch) => {

    dispatch(loadMap(_t))

  }
}
