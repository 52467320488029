const inits = {
  user: !1,
  token: !1,
  csrf: []
}

const AuthReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_AUTH':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default AuthReducer
