import {useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import $ from 'jquery'

import {optionsFromCapabilities} from 'ol/source/WMTS'
import {Tile as TileLayer} from 'ol/layer'
import {XYZ} from 'ol/source'
// import {getRenderPixel} from 'ol/render'

import {is_mobile} from '../../helpers'

const S73Layer = () => {

  const {map} = useSelector(s=>s.map)
  const {activeTab} = useSelector(s=>s.tab)
  const {WMTSCapabilities,_xyz,ll,_73Layer} = useSelector(s=>s.layers)
  const {activeLayers} = useSelector(s=>s.aLayers)
  const setApp = useDispatch()

  useEffect(()=>{

    if(map && typeof document !== undefined && typeof window !== undefined){
      let isLC = activeLayers.indexOf('landCover') > -1 ? 1 : !1

      if(!isLC){
        document.querySelector(`#llandCover > label`).click()
      }
      if(!_73Layer){
        const tg = optionsFromCapabilities(WMTSCapabilities, {
          layer: 'atlas-workspace:LC1970HS'
        }).tileGrid

        const l = new TileLayer({
          source: new XYZ({
            ..._xyz,
            tileGrid: tg,
            tileUrlFunction: function(c) {
              return `${ll}LC1970HS/${c[0]}/${c[1]}/${(Math.pow(2, c[0]) - c[2] - 1)}.png`
            }
          }),
          className: 'l-73',
          name: '_73'
        });

        l.on('prerender', function (e) {
          if(activeTab === 'deforestation' || (is_mobile() && activeTab === 'showmap')){
            map.updateSize();
            let c = e.context,
            mS = map.getSize(),
            width = parseInt($('#swipe73').css('left').toString().replace('px',''))

            // let gRP = getRenderPixel,
            // tl = gRP(e, [width, 0]),
            // tr = gRP(e, [mS[0], 0]),
            // bl = gRP(e, [width, mS[1]]),
            // br = gRP(e, mS);
            c.save();
            c.beginPath();
            c.rect(0, 0, width, mS[1]);
            c.clip();
          }
        });

        l.on('postrender', function (e) {
          if(activeTab === 'deforestation' || (is_mobile() && activeTab === 'showmap')){
            e.context.restore();
          }
        });

        setApp({type:'_LAYERS',payload:['_73Layer',l]})
        map.addLayer(l)
        l.setZIndex(100);

      }else{
        _73Layer.setZIndex(100);
        _73Layer.setVisible(true)
      }

      return () => {
        if(_73Layer){
          _73Layer.setVisible(false)
        }
        if(!isLC){
          document.querySelector(`#llandCover > label`).click()
        }
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[])

  useEffect(()=>{
    if(activeTab === 'deforestation' || (is_mobile() && activeTab === 'showmap')){
      let h = $('#swipe73'),
      mapWidth = $('#ol-map').width(),
      rightPos = (mapWidth/2) + (mapWidth/4),
      leftPos = (mapWidth/2) - (mapWidth/4);
      h.fadeIn().trigger('mousedown').animate({'left':leftPos},500,'swing',function(){
        h.trigger('mousedown').animate({'left':rightPos},500,'swing',function(){
          h.trigger('mousedown').animate({'left':mapWidth/2},500,'swing',function(){

          });
        });
      });
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[activeTab])

  return null
}
export default S73Layer
