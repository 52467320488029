import "typeface-roboto"
import "typeface-roboto-condensed"
import "../style.css";
import "../styles/main.css";
import "../styles/fluid.css";

import React,{useEffect,useState,lazy, Suspense} from "react"
import {useSelector,useDispatch} from 'react-redux'

import Seo from '../components/Seo'
import OLMap from '../components/OLMap'
import Sidebar from '../components/Sidebar'

import RankList from '../components/popups/RankList'
import SearchResult from '../components/popups/search/SearchResult'
import PopupLarge from '../components/popups/PopupLarge'
import PopupInfo from '../components/popups/PopupInfo'

import AppLoader from '../components/loaders/AppLoader'

const About = lazy(() => import('../components/popups/About'));
const HowTo = lazy(() => import('../components/popups/HowTo'));
const Feedback = lazy(() => import('../components/popups/Feedback'));
const ShareThis = lazy(() => import('../components/popups/ShareThis'));
const TermsOfUse = lazy(() => import('../components/popups/TermsOfUse'));
const PopupDownloadExcel = lazy(() => import('../components/popups/PopupDownloadExcel'));

const BaseMapList = lazy(() => import('../components/popups/BaseMapList'));
const LayerList = lazy(() => import('../components/popups/LayerList'));

const renderLoader = () => <AppLoader/>;

export default function Home() {
  const dispatch = useDispatch()
  const {lang} = useSelector(s=>s.lang)

  const [location,setLocation] = useState({})


  useEffect(()=>{
    if(typeof window !== undefined){
      setLocation(window.location)
      if(window.location.hash === '#id'){
        if(lang !== 1){
          dispatch({type:'_LANG',payload:['lang',1]})
        }
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[])

  return (
    <div id="atlas">
      <Seo
        lang={location.hash === '#id' || lang ? 'id_ID' : 'en_US'}
        link={[{
          rel: 'stylesheet',
          href: '//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css'
        }]}
      />
      <OLMap/>
      <Sidebar/>
      <RankList/>
      <SearchResult/>
      <PopupInfo/>


      <Suspense fallback={renderLoader()}>
        <BaseMapList/>
      </Suspense>
      <Suspense fallback={renderLoader()}>
        <LayerList/>
      </Suspense>

      <PopupLarge/>

      <Suspense fallback={renderLoader()}>
        <About/>
      </Suspense>
      <Suspense fallback={renderLoader()}>
        <HowTo/>
      </Suspense>
      <Suspense fallback={renderLoader()}>
        <Feedback/>
      </Suspense>
      <Suspense fallback={renderLoader()}>
        <ShareThis/>
      </Suspense>
      <Suspense fallback={renderLoader()}>
        <TermsOfUse/>
      </Suspense>
      <Suspense fallback={renderLoader()}>
        <PopupDownloadExcel/>
      </Suspense>

      <AppLoader/>
    </div>
  )

}
