import React from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'

import TsCharts from '../charts/TsCharts'
import DownloadPie from '../charts/DownloadPie'
import {InfoCircle} from '@styled-icons/fa-solid'

import rippleGIF from '../../images/ripple.gif'

const showInfo = (setApp,url) => {
  batch(()=>{
    setApp({type:'_POPUP',payload:['popup','popupinfo']})
    setApp({type:'_POPUP',payload:['url',url]})
  })
}

const TsTab = () => {

  const app = useSelector(s=>s.app)
  const {goSearch,single} = app
  const {activeTool} = useSelector(s=>s.tool)
  const {activeTab} = useSelector(s=>s.tab)
  const {rLang,lang} = useSelector(s=>s.lang)
  const {tsTabData,featData} = useSelector(s=>s.dat)
  const y = useSelector(s=>s.ts.y)
  const setApp = useDispatch()

  return (
    <div style={{display:`${activeTab === 'timelapse' ? 'block' : 'none'}`,height:activeTool.indexOf('aoi') === 0 ? 'calc(100vh - 175px)' : ''}} className="tab-pane" id="timelapse-panel">
      <h2 className="tab-label">{rLang.TabTsHead[lang]}</h2>
      <div className="tl-bg">
        {
          (!tsTabData.single && !tsTabData.group) ?
          (<div style={{padding:20}}><img width="30" height="30" alt="" src={rippleGIF}/> <i>Loading . . .</i></div>) :
          (
            <>
            <h4 className="sb-chart-title" style={{marginTop:0}}>
              <b>{rLang.sbc1[lang]} {y} (Ha) [{single ? featData.single.name : goSearch || 'Statistics of result'}]</b>
              {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                showInfo(setApp,'popupts/i0')
              }}><InfoCircle size="14"/></b>
              <DownloadPie/>
            </h4>
            <TsCharts/>
            </>
          )
        }
      </div>
    </div>
  )
}
export default TsTab
