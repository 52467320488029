import React,{useRef,useEffect,useState} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import axios from 'axios'
import rippleGIF from '../../images/ripple.gif'

import {InfoCircle,Times} from '@styled-icons/fa-solid'

function closeThis(setApp,ref){
  ref.current.style.opacity = 0;
  setTimeout(()=>{
    setApp({type:'_POPUP',payload:['popup',null]})
  },250)
}

const imgLoader = `<img width="40" height="40" alt="" src=${rippleGIF}> <i>Loading...</i>`

const PopupInfo = () => {

  const {lang} = useSelector(s=>s.lang)
  const {popup,url} = useSelector(s=>s.popup)
  const {apiLink} = useSelector(s=>s.static)
  const setApp = useDispatch()
  const pInfoRef = useRef()
  const [html,setHtml] = useState(imgLoader)

  useEffect(()=>{
    if(popup === 'popupinfo'){
      axios.post(`${apiLink}${url}`,{lang})
      .then((r)=>{
        setHtml(r.data)
      })
    }

    return () => {
      if(popup === 'popupinfo'){
        setHtml(imgLoader)
      }
    }
  },/* eslint-disable-line react-hooks/exhaustive-deps */[popup])

  return (
    <div ref={pInfoRef} className="popup" style={{display:`${popup === 'popupinfo' ? '' : 'none'}`,opacity:`${popup === 'popupinfo' ? 1 : 0}`}}>
      <div role='presentation' className="popup-bg" onClick={()=>{
        closeThis(setApp,pInfoRef)
      }}></div>
      <div>
        <div>
          <div className="popup-header">
            <div><InfoCircle size="10"/></div>
            <div role='presentation' onClick={()=>closeThis(setApp,pInfoRef)} className="cursor-pointer"><Times size="14"/></div>
          </div>
          <div className="popup-inner" dangerouslySetInnerHTML={{__html:html}}/>
        </div>
      </div>
    </div>
  )
}
export default PopupInfo
