import React,{useEffect,useState} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import axios from 'axios'
import WFS from 'ol/format/WFS'
import {equalTo} from 'ol/format/filter'
import * as extent from 'ol/extent'

import ClickedLayer from './layers/ClickedLayer'

import {_getLayer,isPin,togglePin,_isInt,_flyTo} from '../helpers'
import {getGladData} from '../actions/getGladData'
import {getStatData} from '../actions/getStatData'
import {getRiskData} from '../actions/getRiskData'
import {getTSData} from '../actions/getTSData'

const MapOnClick = () => {

  const {hasLink} = useSelector(s=>s.share)
  const setApp = useDispatch()
  const {map} = useSelector(s=>s.map)
  const {activeTool} = useSelector(s=>s.tool)
  const {single,goSearch,aSearch,mapClicked,viewMap} = useSelector(s=>s.app)
  const {apiRPC,apiP8080} = useSelector(s=>s.static)
  const {layers} = useSelector(s=>s.layers)
  const [geom,setGeom] = useState(!1)
  const [initLink, setInitLink] = useState(!1)

  useEffect(()=>{
    if(hasLink){
      if(hasLink.select.single){
        setTimeout(()=>{
          setInitLink(hasLink)
          setApp({type:'_SHARE',payload:['hasLink',!1]})
        },250)
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[hasLink])


  useEffect(()=>{

    if((activeTool !== 'aoiDRAW' && activeTool !== 'aoiZIP')){

      if(mapClicked || viewMap || initLink){


        if(mapClicked || (initLink && (initLink.select.single.url || initLink.select.single.mapClicked) )){
          if(viewMap){
            setApp({type:'_APP',payload:['viewMap',!1]})
          }
          let ls = [];

          if(!initLink || initLink.select.single.mapClicked){
            const mC = mapClicked || initLink.select.single.mapClicked
            map.forEachLayerAtPixel(mC.pixel, function(layer) {
              if(layer.getClassName().indexOf('l-search') > -1 && layer.get('name') !== 'SearchedLayer' && layer.get('name') !== 'ClickedLayer'){
                let url = layer.getSource().getFeatureInfoUrl(
                  mC.coordinate,map.getView().getResolution(),'EPSG:4326',{
                    'INFO_FORMAT': 'application/json',
                     // 'propertyName': [
                     //   'geom','cid','name','areaha', 'groupcom','skno','prevgroup','acqyear','rspo',
                     //   'country','province','district',
                     //   'baavail','ba2019',
                     //   // 'locstatus','doclink','type'
                     // ]
                  }
                )
                if(url){
                  ls = [...ls,{url,id:layer.get('name')}];
                  url = 0;
                }
              }
            });
          }else{
            if(initLink.select.single.url){
              ls = [...ls,{
                url:initLink.select.single.url,
                id: initLink.select.single.id.indexOf('Concession') > 3 ? initLink.select.single.id.replace('Concession','').toLowerCase() :
                (initLink.select.single.id.indexOf('Boundary') > 3 ? initLink.select.single.id.replace('Boundary','').toLowerCase() : initLink.select.single.id)
              }];
            }
          }

          if(ls.length){
            if(!isPin()){
              togglePin(map)
              // setTimeout(()=>{
              //   setApp({type:'_APP',payload:['pin',1]})
              // },250)
            }
            const l = _getLayer(map,'ClickedLayer')
            if(l){
              map.removeLayer(l)
              // setApp({type:'_A_LAYERS',payload:['activeLayers',[...activeLayers.filter(t => t !== 'ClickedLayer')]]})
            }
            axios.get(ls[0].url).then(r=>{
              if(r.data.features.length){

                const data = {...r.data.features[0],id:ls[0].id}

                setGeom(r.data.features[0].geometry)
                batch(()=>{
                  setApp({type:'SET_FEAT_DATA',payload:{
                    single : {...data.properties,featTitle:layers[ls[0].id].title}
                  }})
                  setApp({type:'SET_SINGLE_CLICK',payload:data})
                  setApp({type:'SET_DEFAULT_TAB'})
                  setApp(getGladData({apiRPC,single:data,sgs:'single',activeTool,goSearch,cIDs:[data.properties.cid],layers}))
                  setApp(getStatData({apiRPC,single:data,sgs:'single',activeTool,goSearch,cIDs:[data.properties.cid],layers}))
                  setApp(getRiskData({apiRPC,single:data,sgs:'single',activeTool,goSearch,cIDs:[data.properties.cid],layers}))
                  setApp(getTSData({apiRPC,single:data,sgs:'single',activeTool,goSearch,cIDs:[data.properties.cid],layers}))
                  if(initLink){
                    setApp({type:'_LOADER',payload:['appLoader',false]})
                    map.getView().animate({
                      center: initLink.center,
                      zoom: initLink.zoom,
                      duration: 2500
                    });
                  }
                })

              }
            })
          }else{
            batch(()=>{
              if(hasLink){
                setApp({type:'_SHARE',payload:['hasLink',!1]})
              }
              setApp({type:'_APP',payload:['single',!1]})
              setApp({type:'_APP',payload:['viewMap',!1]})
              setApp({type:'_APP',payload:['mapClicked',!1]})
              setApp({type:'_APP',payload:['sgs',aSearch?'stat':(goSearch?'group':!1)]})

              setApp({type:'INIT_SINGLE_DATA'})
              if(!goSearch && !aSearch){
                setApp({type:'_TAB',payload:['activeTab','map']})
              }
            })

          }
        }else if(viewMap || (initLink && initLink.select.single.viewMap)){

          if(mapClicked){
            setApp({type:'_APP',payload:['mapClicked',!1]})
          }
          if(_isInt(viewMap)){
            const fR = new WFS().writeGetFeature({
              srsName: 'EPSG:4326',
              featurePrefix: 'atlas-workspace',
              featureTypes: [layers[activeTool].sLayer],
              outputFormat: 'application/json',
              filter: equalTo('cid', initLink ? initLink.select.single.viewMap : viewMap),
            });

            axios.post( apiP8080 + 'geoserver/wfs',new XMLSerializer().serializeToString(fR),{headers:{'Content-Type': 'text/xml'}})
            .then(function(r){

              if(r.data.features.length){

                const data = {...r.data.features[0],id:layers[activeTool].id}
                batch(()=>{
                  setApp({type:'SET_FEAT_DATA',payload:{
                    single : {...data.properties,featTitle:layers[activeTool].title}
                  }})
                  setApp({type:'SET_SINGLE_CLICK',payload:data})
                  setApp({type:'SET_DEFAULT_TAB'})
                  setApp(getGladData({apiRPC,single:data,sgs:'single',activeTool,goSearch,cIDs:[data.properties.cid],layers}))
                  setApp(getStatData({apiRPC,single:data,sgs:'single',activeTool,goSearch,cIDs:[data.properties.cid],layers}))
                  setApp(getRiskData({apiRPC,single:data,sgs:'single',activeTool,goSearch,cIDs:[data.properties.cid],layers}))
                  setApp(getTSData({apiRPC,single:data,sgs:'single',activeTool,goSearch,cIDs:[data.properties.cid],layers}))
                })

                setGeom(r.data.features[0].geometry)

                const ext = new extent.createEmpty();
                extent.extend(ext,r.data.features[0].bbox)

                if(!initLink && map.getView().getZoom() > 4){
                  _flyTo(extent.getCenter(ext), map.getView().getZoomForResolution(map.getView().getResolutionForExtent(ext)) - 0.5, map.getView())
                }else{
                  map.getView().animate({
                    center: !hasLink ? extent.getCenter(ext) : hasLink.center,
                    zoom: !hasLink ? map.getView().getZoomForResolution(map.getView().getResolutionForExtent(ext)) - 0.5 : hasLink.zoom,
                    duration: 2500
                  },function(){

                  })
                }

                if(!isPin()){
                  togglePin(map)
                }

              }

            });
          }else{
            // $('#atlasSbox').val(id);
            // $('#company-search-button').trigger('click');
          }
        }


      }

    }

    return () => {}

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[mapClicked,viewMap,initLink])

  return !single || !geom || (!mapClicked && !viewMap && !initLink) ? null :
  (<ClickedLayer map={map} name={'ClickedLayer'} geom={geom}/>)
}
export default MapOnClick
