import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import config from '../config'

const Seo = ({ description, lang, meta, title,link }) => {
  const language = lang === 'en_US' ? 0 : 1
  const metaDescription = description || config.desc[language]
  const defaultTitle = config.title[language]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={link}
      title={title || defaultTitle}
      titleTemplate={defaultTitle !== title ? `%s | ${defaultTitle}` : (title || defaultTitle)}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@reggarantai`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en_US`,
  meta: [],
  description: ``,
  link: [],
  title: ''
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
