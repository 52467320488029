const inits = {
  mapClicked: null,
  viewMap: null,
  sgs:null,
  cIDs:[],
  single:null,
  goSearch:null,
  aSearch:null,
  areaha: 0,
}

const AppReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_APP':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_START':
      return { ...s,
        goSearch: null,
        sgs: null,
      }

    case 'SET_SINGLE_CLICK':
      return { ...s,
        single: payload,
        sgs: 'single',
      }

    case 'SEARCH':
      return { ...s,
        goSearch: payload,
        sgs: 'group'
      }

    case 'INIT_ADVANCED_SEARCH':
      return {...s,
        sgs:!1,
        cIDs:[],
        single:!1,
        goSearch:!1,
        aSearch:1,
        areaha: 0,
        mapClicked: !1
      }

    case 'INIT':
      return {...s,
        sgs:!1,
        cIDs:[],
        single:!1,
        goSearch:!1,
        aSearch:!1,
        areaha: 0,
        mapClicked: !1
      }


    default:
      return s;
  }
};
export default AppReducer
