import axios from 'axios'

export const getRiskData = (_t) => {
  return (dispatch) => {


    axios.post(_t.apiRPC + 'atlas_getprobdfbyid',{
    cid:'{'+ _t.cIDs.concat() +'}',
    unit: _t.layers[_t.single ? _t.single.id : _t.activeTool].unit
  })
  .then((r)=>{
    r = {...r,title:_t.layers[_t.single ? _t.single.id : _t.activeTool].title}
    dispatch({type:'SET_RISK_DATA',payload:_t.sgs === 'single' ? {single:r} : {group:r}})
  })
  .catch((e)=>{
    console.log(e.response);
  });

  }
}
