const inits = {
  popup: null,
  html: ''
}

const TabReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_POPUP':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default TabReducer
