import React,{useMemo} from 'react'
import {useSelector} from 'react-redux'

import {_fNDec,_hasGroup} from '../../helpers'

const TabHead = () => {

  const {featData} = useSelector(s=>s.dat)
  const {activeTool} = useSelector(s=>s.tool)
  const {goSearch,single,sgs} = useSelector(s=>s.app)
  const {rLang,lang} = useSelector(s=>s.lang)

  return useMemo(()=>{
    const f = featData.single || featData.group
    return !f ? null : (
      <div className="sb-title-pane">
        <div className="titlePane">
          <div className="title">{f.id === 'aoi' ? 'Area of interest' : (sgs === 'stat' ?  'Statistics of result' : f.featTitle[lang])}</div>
        </div>
        <div className="subTitlePane">
          {
            sgs !== 'stat' && (
              <div>
                <div className="subTitlePaneHead">{single ? rLang.Name[lang] : (_hasGroup(activeTool) ? rLang.Group[lang] : rLang.Name[lang])}</div>
                <div className="subTitlePaneBody">{single ? f.name : goSearch}</div>
              </div>
            )
          }
          <div>
            <div className="subTitlePaneHead">AREA</div>
            <div className="subTitlePaneBody">{_fNDec(f.areaha)} Ha</div>
          </div>
          {
            sgs !== 'stat' && (
              <>
                {
                  f.type ? (
                    f.type.trim() ? (
                      <div>
                        <div className="subTitlePaneHead">{lang === 1 ? 'Tipe' : 'Type'}</div>
                        <div className="subTitlePaneBody">{f.type}</div>
                      </div>
                    ):null
                  ):null
                }
                {
                  single && (
                    <>
                      {
                        f.concession === 'Mining' && f.commodity ? (
                          f.commodity.trim() ? (
                            <div>
                              <div className="subTitlePaneHead">{lang === 1 ? 'Tipe' : 'Type'}</div>
                              <div className="subTitlePaneBody">{f.commodity}</div>
                            </div>
                          ):null
                        ):null
                      }
                      {
                        f.groupcom ? (
                          f.groupcom.trim() ? (
                            <div>
                              <div className="subTitlePaneHead">{rLang.Group[lang]}</div>
                              <div className="subTitlePaneBody">{f.groupcom}</div>
                            </div>
                          ):null
                        ):null
                      }
                      <div>
                        <div className="subTitlePaneHead">{rLang.Country[lang]}</div>
                        <div className="subTitlePaneBody">{f.country}</div>
                      </div>
                      <div>
                        <div className="subTitlePaneHead">{rLang.Province[lang]}</div>
                        <div className="subTitlePaneBody">{f.province}</div>
                      </div>
                      <div>
                        <div className="subTitlePaneHead">{rLang.District[lang]}</div>
                        <div className="subTitlePaneBody">{f.district}</div>
                      </div>
                    </>
                  )
                }
              </>
            )
          }
        </div>
      </div>
    )
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[featData,lang])
}
export default TabHead
