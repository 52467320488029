const inits = {
  open1: false,
  hide1: false,
  date1: '',
  type1: true,
  open2: false,
  hide2: false,
  date2: '',
  type2: true,
}

const AvsReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_AVS':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_AVS':
      return { ...s,...payload}

    case 'INIT_AVS':
      return { ...s,...inits}

    default:
      return s;
  }
};
export default AvsReducer
