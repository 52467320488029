import {useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {Image as ImageLayer} from 'ol/layer';

import config from '../../config'
import {DateToJD,_getLayer} from  '../../helpers'

function gladOp(pixels, d) {
  var p = pixels[0];
  if (p[3]) {
  	var pxLC=Math.floor(p[0]/10);
   	 var pxUnconfirmed = p[0]-(pxLC*10);
   	 var h = (p[1]*100)+(p[2]);
  	if(pxUnconfirmed===2 && h <= d.levelmax && h >= d.levelmin && d.unconfirmed){
    	p[0] = 252;p[1] = 3;p[2] = 252;p[3] = 255;
  	}else if (pxUnconfirmed===3 && h <= d.levelmax && h >= d.levelmin) {
    	p[0] = 252;p[1] = 3;p[2] = 252;p[3] = 255;
  	}else {
    	p[3] = 0;
  	}
  }
  return p;
}


let thisLayer = null

const GladLayer = () => {

  const {map} = useSelector(s=>s.map)
  const setApp = useDispatch()

  useEffect(()=>{
    if(typeof document !== undefined){
      thisLayer = _getLayer(map,'gladLayer')
      if(!thisLayer){
        const {ImageWMS,Raster} = require('ol/source')
        let gRs = new ImageLayer({
          source: new ImageWMS({
            url: config.apiP8080  + 'geoserver/atlas-workspace/wms',
            params: {'LAYERS': 'atlas-workspace:atlaslandcover21RGB'},
            serverType: 'geoserver',
            crossOrigin: 'anonymous'
          }),
        });
        gRs.on('prerender', function (e) {
          e.context.imageSmoothingEnabled = false;
      		e.context.msImageSmoothingEnabled = false;
          e.context.webkitImageSmoothingEnabled = false;
      		e.context.mozImageSmoothingEnabled = false;
        });
        let tGladRaster = new Raster({
        	sources: [gRs],
        	operation: gladOp,
        });

        tGladRaster.on('beforeoperations', function (e) {
        	e.data.unconfirmed = document.getElementById('glad-check').checked;
          e.data.levelmax = DateToJD(new Date(document.getElementById('glad-to').value));
        	e.data.levelmin = DateToJD(new Date(document.getElementById('glad-from').value));
        });
        thisLayer = new ImageLayer({
        	opacity: 0.7,
        	source: tGladRaster,
          name: 'gladLayer'
        });
        thisLayer.setZIndex(100)
        map.addLayer(thisLayer)
        setApp({type:'_LAYERS',payload:['gladRaster',tGladRaster]})
      }else{
        thisLayer.setZIndex(100)
        thisLayer.setVisible(true)
      }
    }

    return () => {
      if(thisLayer){
        thisLayer.setVisible(false)
        // map.removeLayer(thisLayer)
        // setApp({type:'_LAYERS',payload:['gladRaster',!1]})
      }
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[])

  return null
}
export default GladLayer
