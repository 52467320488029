import {useEffect} from 'react'
import $ from 'jquery'

const Swipe73 = () => {

  useEffect(()=>{
    if(typeof navigator !== undefined){
      require('jquery-ui/ui/widgets/draggable');

      $( "#swipe73" ).draggable({
    		axis: "x",
    		containment: $('#ol-map'),
    		drag: function( e, ui ) {
    			// __s.swipe = ui.position.left;
    		},
    	});
    }

  },[])

  return null
}
export default Swipe73
