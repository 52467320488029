import React from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'

import lIcon from '../../images/icons/MAP.svg';
import gIcon from '../../images/icons/Alert.svg';
import TimeLapseIcon from '../../images/icons/TimeLapseIcon';
import StatsIcon from '../../images/icons/StatsIcon';
import RiskIcon from '../../images/icons/RiskIcon';
import dIcon from '../../images/icons/Group-216.svg';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import {togglePin} from '../../helpers'

let tsFilterLayer = []

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const setTab = ({setApp,tab,id,activeLayers}) => {
  batch(()=>{
    setApp({type:'_TAB',payload:['activeTab',tab]})
    if(tab !== 'timelapse' || tab !== 'risk'){
      setApp({type:'_A_LAYERS',payload:['showLayers',activeLayers]})
    }
    if(tab === 'timelapse'){
      setApp({type:'_A_LAYERS',payload:['showLayers',activeLayers.filter( ( el ) => !tsFilterLayer.includes( el ) )]})
    }
    if(tab === 'risk'){
      const riskFilterLayers = ['landCover','landcover_plantation','landcover_mining','landcover_transmigration']
      setApp({type:'_A_LAYERS',payload:['showLayers',activeLayers.filter( ( el ) => !riskFilterLayers.includes( el ) )]})
    }
  })
  setTimeout(()=>{
      document.getElementById(id).scrollTop = 0
  },50)
}

const TabMenu = () => {

  const {map} = useSelector(s=>s.map)
  const {activeLayers} = useSelector(s=>s.aLayers)
  const {defaultLayers} = useSelector(s=>s.layers)
  const {activeTab} = useSelector(s=>s.tab)
  const {featData} = useSelector(s=>s.dat)
  const {lang} = useSelector(s=>s.lang)

  const setApp = useDispatch()

  tsFilterLayer = defaultLayers.removeInTimeSeriesTab

  return (
    <ul className="nav nav-tabs">
      <li id="maps-tab" className={`${activeTab === 'map' ? 'active' : ''}`} onClick={()=>{
        setTab({setApp,activeLayers,tab:'map',id:'maps-panel'})
      }} role='presentation'>
        <img alt="" src={lIcon} style={{width:23}}/>
      </li>
      {
        (featData.single || featData.group) && (
          <>
            <BootstrapTooltip title={lang ? 'Lacak per pekan' : 'Track weekly'} placement="right">
              <li id="glad-tab" className={`${activeTab === 'glad' ? 'active' : ''}`} onClick={()=>{
                setTab({setApp,activeLayers,tab:'glad',id:'glad-panel'})
              }} role='presentation'>
                <img alt="" src={gIcon}/>
              </li>
            </BootstrapTooltip>
            <BootstrapTooltip title={lang ? 'Lacak per tahun' : 'Track annually'} placement="right">
              <li id="timelapse-tab" className={`${activeTab === 'timelapse' ? 'active' : ''}`} onClick={()=>{
                setTab({setApp,activeLayers,tab:'timelapse',id:'timelapse-panel'})
              }} role='presentation'>
                <TimeLapseIcon color='#fff'/>
              </li>
            </BootstrapTooltip>
            <BootstrapTooltip title={lang ? 'Tinjauan 2 dekade' : 'Two-decades overview'} placement="right">
              <li id="statistic-tab" className={`${activeTab === 'statistic' ? 'active' : ''}`} onClick={(e)=>{
                setTab({setApp,activeLayers,tab:'statistic',id:'stat-panel'})
              }} role='presentation'>
                <StatsIcon color='#fff'/>
              </li>
            </BootstrapTooltip>
            <BootstrapTooltip title={lang ? 'Tinjauan 4 dekade' : 'Four-decades overview'} placement="right">
              <li id="deforestation-tab" className={`${activeTab === 'deforestation' ? 'active' : ''}`} onClick={()=>{
                setTab({setApp,activeLayers,tab:'deforestation',id:'deforestation-panel'})
              }} role='presentation'>
                <img alt="" src={dIcon}/>
              </li>
            </BootstrapTooltip>
            <BootstrapTooltip title={lang ? 'Resiko kehilangan hutan' : 'Deforestation threat'} placement="right">
              <li id="risk-tab" className={`${activeTab === 'risk' ? 'active' : ''}`} onClick={()=>{
                setTab({setApp,activeLayers,tab:'risk',id:'risk-panel'})
              }} role='presentation'>
                <RiskIcon color='#fff'/>
              </li>
            </BootstrapTooltip>
          </>
        )
      }


      <li id="showmap-tab" className="m-only" onClick={()=>{
        togglePin(map)
      }} role='presentation'>
        SHOW MAP
      </li>

    </ul>
  )
}
export default TabMenu
