import React,{useEffect} from 'react'
import {useSelector} from 'react-redux'
import ReactDOM from 'react-dom'
import C3Chart from 'react-c3js';

import {is_mobile,_f1Dec,_gcData,formatKiloMegaGiga} from '../../helpers'

export const SbPieChart = ({d,area}) => {

  const {y,startYear,endYear,ycArray} = useSelector(s=>s.ts)

  useEffect(() => {
    if(d && typeof document !== undefined){
      const sY = startYear
      const eY = endYear
      const yA = ycArray

      let oilpalm=0,timber=0,nonforest=0,mang=0,peat=0,forest=0,mining=0,trans=0,smallholder=0;

      oilpalm += _gcData({d:d.ip.data,yA,s:1,c:'Oilpalm before 2000'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Forest on mineral soil to oilpalm'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Mangrove forest to oilpalm'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Peat swamp forest to oilpalm'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Non-forest to oilpalm'});

      timber += _gcData({d:d.ip.data,yA,s:1,c:'Pulpwood before 2000'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Forest on mineral soil to pulpwood'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Mangrove forest to pulpwood'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Peat swamp forest to pulpwood'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Non-forest to pulpwood'});

      smallholder += _gcData({d:d.ip.data,yA,s:1,c:'Smallholder before 2000'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Forest on mineral soil to smallholder'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Mangrove forest to smallholder'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Peat swamp forest to smallholder'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Non-forest to smallholder'});

      mining += _gcData({d:d.ip.data,yA,s:1,c:'Mining before 2000'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Forest on mineral soil to mining'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Mangrove forest to mining'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Peat swamp forest to mining'})
        + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Non-forest to mining'});

      trans += _gcData({d:d.ip.data,yA,s:1,c:'Transmigration before 2000'})
      + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Forest on mineral soil to transmigration'})
      + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Mangrove forest to transmigration'})
      + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Peat swamp forest to transmigration'})
      + _gcData({d:d.ip.data,yA,loop:[sY,y],c:'Non-forest to transmigration'});

      forest += (_gcData({d:d.df.data,yA,s:1,c:'Remaining forest on mineral soil'})
      + _gcData({d:d.df.data,yA,loop:[sY,eY],c:'Forest on mineral soil loss'}))
      - (_gcData({d:d.df.data,yA,loop:[sY,y],c:'Forest on mineral soil loss'}));

      mang += (_gcData({d:d.df.data,yA,s:1,c:'Remaining mangrove forest'})
      + _gcData({d:d.df.data,yA,loop:[sY,eY],c:'Mangrove forest loss'}))
      - (_gcData({d:d.df.data,yA,loop:[sY,y],c:'Mangrove forest loss'}));

      peat += (_gcData({d:d.df.data,yA,s:1,c:'Remaining peat swamp forest'})
      + _gcData({d:d.df.data,yA,loop:[sY,eY],c:'Peat swamp forest loss'}))
      - (_gcData({d:d.df.data,yA,loop:[sY,y],c:'Peat swamp forest loss'}));

      nonforest += (area) - (oilpalm+timber+smallholder+nonforest+mang+peat+forest+mining+trans);
      
      const data = {
        columns: [
          ["Industrial oil-palm plantation", oilpalm],
          ["Industrial pulpwood plantation", timber],
          ["Smallholder oil-palm plantation", smallholder],
          ["Mining", mining],
          ["Transmigration", trans],
          ["Forest on mineral soil", forest],
          ["Mangrove forest", mang],
          ["Peat-swamp forest", peat],
          ["Other non-forest", nonforest]
        ],
        colors: {
          "Intact forest": 'RGB( 8,82, 0)',
          "Logged forest": 'RGB( 15, 153, 0)',
          "Regrowth forest": 'RGB( 0, 179, 134)',
          "Non-forest since 1973": 'RGB(178, 230, 178)',
	        "Smallholder oil-palm plantation": 'RGB(230, 230, 0)',
          "Industrial oil-palm plantation": 'RGB(168, 168, 0)',
          "Industrial pulpwood plantation": 'RGB(115, 115, 0)',
          "Other non-forest": 'RGB(215, 215, 158)',
          "Scrub": 'RGB(255, 213, 77)',
          "Water": 'RGB(36, 62, 255)',
          "Forest on mineral soil": '#257301',
          "Mangrove forest": 'rgb(48, 255, 169)',
          "Peat-swamp forest": 'RGB(68, 137, 112)',
          "Mining": 'rgb(255,0,0)',
          "Transmigration": 'RGB(137,90,68)'
        },
        type : 'pie'
      };

      const tooltip = {
        format: {
          value: function (v, ratio, id, index) {
            return (_f1Dec(v) + ' Ha');
          }
        }
      }
      const pie = {
        label: {
          format: function (v, ratio, id) {
            return formatKiloMegaGiga(v)
          }
        }
      }
      const legend = {
        position: 'right'
      }

      // const size = {width: is_mobile() ? "100%" : 332, height:200}
      const size = {height:200}
      if(!is_mobile()){
        size.width = 332
      }

      ReactDOM.render(<C3Chart
        data={data}
        tooltip={tooltip}
        pie={pie}
        legend={legend}
        size={size}
        padding={{
          right: 20,
          left:20
        }}
        />, document.getElementById(`sb-pie`));

    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[d,y])

  return (<div id="sb-pie"/>)
}
export default SbPieChart
