import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import $ from 'jquery';
import {_reorderLayer} from '../../helpers'

import SortableLayer from '../layers/SortableLayer'

const LayersTab = () => {

  const { map } = useSelector(s=>s.map);
  const {activeTool} = useSelector(s=>s.tool)
  const {activeTab} = useSelector(s=>s.tab)
  const {rLang,lang} = useSelector(s=>s.lang)
  const activeLayers = useSelector(s=>s.aLayers.activeLayers);

  useEffect(() => {
    if(!activeLayers) return
    if(typeof navigator !== undefined && map){
      require('jquery-ui/ui/widgets/sortable')
      $('#layers-block').sortable();
      $( "#layers-block" ).bind( "sortupdate", function(event, ui) {
        _reorderLayer(map,activeLayers);
      });
    }
  }, [activeLayers,map]);

  return (
    <div style={{display:`${activeTab === 'map' ? 'block' : 'none'}`,height:activeTool.indexOf('aoi') === 0 ? 'calc(100vh - 175px)' : ''}} className="tab-pane fade active in" id="maps-panel">
      <div className="display-table width-100">
        <div className="display-table width-100">
          <div className="table-cell vertical-top">
            <h2 id="map-span" className="tab-label">{rLang.Mapsandlegend[lang]}</h2>
          </div>
          <div className="table-cell vertical-middle text-right">
            <span id="total-active-layer">{activeLayers.length}</span>
          </div>
        </div>
      </div>

      <div id="opacity-list" className="hide"></div>
      <div id="layers-block">
        {activeLayers && activeLayers.slice(0).reverse().map((id,i) => <SortableLayer
          key={id} idx={activeLayers.length - i } id={id}
        />)}
      </div>
    </div>
  )

}
export default LayersTab
