import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import axios from 'axios'
import qs from 'qs'
import {Download} from '@styled-icons/fa-solid'

import config from '../../config'

const downloadPie = ({dispatch,cid,unit,areaha,title,chartTitle,cInfo,sProp,lang}) => {
  dispatch({type:'_POPUP',payload:['popup','downloadexcel']})
  axios({
    method: 'post',
    url: `${config.apiLink}excel/pie/`,
    data: qs.stringify({
      cid,unit,
      areaha,title,chartTitle,
      cInfo,sProp,
      lang
    }),
    withCredentials: true,
    credentials: 'include',
    crossdomain: true,
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      "Cache-Control": "no-cache",
    }
  })
  .then(function(r){
    document.getElementById('chart-download-alert').innerHTML = r.data.html;
    document.getElementById('dl-chart').click();
  }).catch((e)=>{
    dispatch({type:'_POPUP',payload:['popup',!1]})
    console.log(e.response);
  });

}

const DownloadPie = () => {

  const dispatch = useDispatch()
  const {lang} = useSelector(s=>s.lang)
  const {layers} = useSelector(s=>s.layers)
  const {single,cIDs,goSearch,sgs} = useSelector(s=>s.app)
  const {activeTool} = useSelector(s=>s.tool)
  const {featData} = useSelector(s=>s.dat)
  const f = featData.single || featData.group

  return (
    <b role="presentation" onClick={()=>{
      downloadPie({
        dispatch,
        unit: layers[single ? single.id : activeTool].unit,
        cid: '{'+ cIDs.concat() +'}',
        areaha: f.areaha,
        title: sgs === 'stat' ?  'Statistics of result' : f.featTitle[lang],
        chartTitle: sgs === 'stat' ? 'Statistics of result' : (single ? f.name : goSearch),
        cInfo: '',
        sProp: f.sProp ? f.sProp : (sgs === 'stat' ? 'Statistics of result' : (single ? f.name : goSearch)),
        lang
      });
    }}>{/* eslint-disable-line jsx-a11y/control-has-associated-label */}<Download size="14"/></b>
  )
}
export default DownloadPie
