import {useEffect} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import {Image as ImageLayer, Tile as TileLayer, Group as GroupLayer} from 'ol/layer'
import {optionsFromCapabilities} from 'ol/source/WMTS'

import config from '../../config'

function pxlDF(ps, d) {
  let p = ps[0];
  if (p[3]) {
    var pv = (p[0]*10000)+(p[1]*100)+(p[2]),
    sl = d.lm-2000;
  	if (pv===100 || ((pv-100) > sl && pv<200)) {
      p[0] = 38;p[1] = 115;p[2] = 0;p[3] = 255;
    }else if (pv===400 || ((pv-400) > sl && (pv>=400 && pv<600))) {
      p[0] = 48;p[1] = 255;p[2] = 169;p[3] = 255;
    }else if (pv===600 || ((pv-600) > sl && (pv>=600 && pv<800))) {
      p[0] = 68;p[1] = 137;p[2] = 112;p[3] = 255;
    }else if (pv===0 || pv===10000){
      p[0] = 0;p[1] = 0;p[2] = 0;p[3] = 0;
    }else {
      p[0] = 215;p[1] = 215;p[2] = 158;p[3] = 255;
    }
  }
  return p;
}
function pxlIP(ps, d) {
  let p = ps[0];
  if (p[3]) {
    var pv = (p[0]*10000)+(p[1]*100)+(p[2]),
	  y= pv-(Math.floor(pv/100)*100),
	  sl = d.lm-2000;
    if (pv===0) {
      //nodata
      p[0] = 0;p[1] = 0;p[2] = 0;p[3] = 0;
    }else if (pv===1100 || pv===1300 || pv===1400 || pv===1600 || (y <= sl && pv<2000)) {
      //IOPP
      p[0] = 168;p[1] = 168;p[2] = 0;p[3] = 255;
    }else if (pv===2100 || pv===2300 || pv===2400 || pv===2600 || (y <= sl && pv>2000 && pv<3000)) {
      //ITP
      p[0] = 115;p[1] = 115;p[2] = 0;p[3] = 255;
    }else if (pv===3100 || pv===3300 || pv===3400 || pv===3600 || (y <= sl && pv>3000 && pv<4000)) {
      //Mining
      p[0] = 255;p[1] = 1;p[2] = 1;p[3] = 255;
    }else if (pv===4100 || pv===4300 ||pv===4390 || pv===4400 || pv===4600 || (y <= sl && pv>4000 && pv<5000)) {
      //transmigration
      p[0] = 137;p[1] = 112;p[2] = 68;p[3] = 255;
    }else if (pv===5100 || pv===5300 || pv===5400 || pv===5600 || (y <= sl && pv>5000 && pv<6000)) {
      //smallholder
      p[0] = 230;p[1] = 230;p[2] = 0;p[3] = 255;
    }else {
      //nodata
      p[0] = 0;p[1] = 0;p[2] = 0;p[3] = 0;
    }

  }
  return p;
}

function waterpixel(ps, d) {
  var p = ps[0];
  if (p[3]) {
  	var pv = p[0]+2000,
  	sy = d.lm,
    r = 0,g = 50,b = 200,
    waterthreshold=2019;
    if ((pv <= sy && sy < waterthreshold) || (pv <= sy && sy >= waterthreshold && pv !== 2000)) {
    	p[0] = r;p[1] = g;p[2] = b;
  	}else {
      p[3] = 0;
	  }
  }
  return p;
}


let thisTsLayer = null

const TsLayer = () => {

  const {map} = useSelector(s=>s.map)
  const {WMTSCapabilities,_xyz,ll} = useSelector(s=>s.layers)
  const {endYear,sat,tsSatLayer} = useSelector(s=>s.ts)
  const setApp = useDispatch()

  useEffect(()=>{
    if(typeof document !== undefined){
      const {ImageWMS,Raster,XYZ} = require('ol/source')

      // HS
      const tsHS = new TileLayer({
        source: new XYZ({
          ..._xyz,
          tileGrid: optionsFromCapabilities(WMTSCapabilities, {
            layer: 'atlas-workspace:IDNMYSBorneo_SRTMHS30WGS_2000'
          }).tileGrid,
          tileUrlFunction: function(c) {
            return `${ll}IDNMYSBorneo_SRTMHS30WGS_2000/${c[0]}/${c[1]}/${(Math.pow(2, c[0]) - c[2] - 1)}.png`
          }
        }),
      });

      // DF
      const tsDFs = new TileLayer({
        source: new XYZ({
          ..._xyz,
          tileGrid: optionsFromCapabilities(WMTSCapabilities, {
            layer: 'atlas-workspace:IDNMYSBorneo_FCLossYearRGB_2000to2020'
          }).tileGrid,
          tileUrlFunction: function(c) {
            return `${ll}IDNMYSBorneo_FCLossYearRGB_2000to2020/${c[0]}/${c[1]}/${(Math.pow(2, c[0]) - c[2] - 1)}.png`
          }
        }),
      });
      const tsRasteDF = new Raster({
  			sources: [tsDFs],
  			operation: pxlDF,
  		});
  		tsDFs.on('prerender', function (e) {
        e.context.imageSmoothingEnabled = false;
    		e.context.msImageSmoothingEnabled = false;
        e.context.webkitImageSmoothingEnabled = false;
    		e.context.mozImageSmoothingEnabled = false;
  	  });
  		tsRasteDF.on('beforeoperations', function (e) {
  	    e.data.lm = document.querySelector('#timeSliderBlock input').value;
  			e.data.unconfirmed = true;
  	  });

      // IP
      const tsIPs = new TileLayer({
        source: new XYZ({
          ..._xyz,
          tileGrid: optionsFromCapabilities(WMTSCapabilities, {
            layer: 'atlas-workspace:IDNMYSBorneo_AllExpansionRGB_2000to2020'
          }).tileGrid,
          tileUrlFunction: function(c) {
            return `${ll}IDNMYSBorneo_AllExpansionRGB_2000to2020/${c[0]}/${c[1]}/${(Math.pow(2, c[0]) - c[2] - 1)}.png`
          }
        }),
      });
      const tsRasterIP = new Raster({
  			sources: [tsIPs],
  			operation: pxlIP,
  		});
  		tsIPs.on('prerender', function (e) {
      	e.context.imageSmoothingEnabled = false;
    		e.context.msImageSmoothingEnabled = false;
        e.context.webkitImageSmoothingEnabled = false;
    		e.context.mozImageSmoothingEnabled = false;
  	  });
  		tsRasterIP.on('beforeoperations', function (e) {
  	    e.data.lm = document.querySelector('#timeSliderBlock input').value;
  	  });

      // Water
      const tsWater = new TileLayer({
        source: new XYZ({
          ..._xyz,
          tileGrid: optionsFromCapabilities(WMTSCapabilities, {
            layer: 'atlas-workspace:IDNMYSBorneo_WaterChangeRGB_1984to2020_JRC'
          }).tileGrid,
          tileUrlFunction: function(c) {
            return `${ll}IDNMYSBorneo_WaterChangeRGB_1984to2020_JRC/${c[0]}/${c[1]}/${(Math.pow(2, c[0]) - c[2] - 1)}.png`
          }
        }),
      });
      const tsRasterWater = new Raster({
  			sources: [tsWater],
  			operation: waterpixel,
  		});
  		tsWater.on('prerender', function (e) {
      	e.context.imageSmoothingEnabled = false;
    		e.context.msImageSmoothingEnabled = false;
        e.context.webkitImageSmoothingEnabled = false;
    		e.context.mozImageSmoothingEnabled = false;
  	  });
  		tsRasterWater.on('beforeoperations', function (e) {
  	    e.data.lm = document.querySelector('#timeSliderBlock input').value;
  	  });


      thisTsLayer = new GroupLayer({
  			layers:[
  				tsHS,
          new ImageLayer({
      			opacity: 0.8,
      			source: tsRasteDF,
      		}),
          new ImageLayer({
      			opacity: 0.9,
      			source: tsRasterIP,
      		}),
          new ImageLayer({
      			opacity: 0.8,
      			source: tsRasterWater,
      		})
  			]
  		});

      // SAT
      const tsSatSource = new ImageWMS({
        url: config.apiP8080 + 'geoserver/atlas-workspace/wms',
        params: {
          'LAYERS': 'atlas-workspace:timeseries-landsat',
          'FORMAT': 'image/jpeg'
        },
      })
      const tsSatLayer = new ImageLayer({
        source: tsSatSource,
    		className:'l-tsat',
    		name: 'tsat'
      });

      tsSatSource.on('imageloadend',function(e){
        const play = document.querySelector('#timer-button > span').innerText === 'PLAY' ? false : true;
        const year = parseInt(document.querySelector('#timeSliderBlock input').value);
        if(play && year < endYear){
          setTimeout(()=>{
            setApp({type:'_TS',payload:['y',year + 1]})
          },1000)
        }
    	})

      map.addLayer(thisTsLayer)
      map.addLayer(tsSatLayer)
      thisTsLayer.setZIndex(800)
      tsSatLayer.setZIndex(800)


      if(sat){
        tsSatLayer.setVisible(true)
        thisTsLayer.setVisible(false)
      }else{
        tsSatLayer.setVisible(false)
        thisTsLayer.setVisible(true)
      }

      batch(()=>{
        setApp({type:'_TS',payload:['tsRasterDF',tsRasteDF]})
        setApp({type:'_TS',payload:['tsRasterIP',tsRasterIP]})
        setApp({type:'_TS',payload:['tsRasterWater',tsRasterWater]})
        setApp({type:'_TS',payload:['tsSatLayer',tsSatLayer]})
      })
      // setTimeout(()=>{
      //   setApp({type:'_A_LAYERS',payload:['showLayers',[]]})
      // },100)

      return () => {
        if(thisTsLayer){
          map.removeLayer(thisTsLayer)
          map.removeLayer(tsSatLayer)
          batch(()=>{
            setApp({type:'_TS',payload:['tsRasterDF',!1]})
            setApp({type:'_TS',payload:['tsRasterIP',!1]})
            setApp({type:'_TS',payload:['tsRasterWater',!1]})
            setApp({type:'_TS',payload:['tsSatLayer',!1]})
            // setApp({type:'_A_LAYERS',payload:['showLayers',activeLayers]})
          })
        }
      }

    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[])

  useEffect(()=>{
    if(thisTsLayer && tsSatLayer){
      if(sat){
        tsSatLayer.setVisible(true)
        thisTsLayer.setVisible(false)
      }else{
        tsSatLayer.setVisible(false)
        thisTsLayer.setVisible(true)
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[sat,thisTsLayer])

  return null
}
export default TsLayer
