import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {XYZ,OSM} from "ol/source";

import {_getLayer} from  '../../helpers'

const BaseMapLayer = () => {

  const {map} = useSelector(s=>s.map)
  const {bMap,baseMaps} = useSelector(s=>s.bMap)

  useEffect(()=>{
    const bMapLayer = _getLayer(map,'bMap')
    if(bMapLayer){
      bMapLayer.setSource(
        baseMaps[bMap].type && baseMaps[bMap].type === 'OSM' ? new OSM() : new XYZ({
          attributions: baseMaps[bMap].a,
          url: baseMaps[bMap].url
        })
      )
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[bMap])
  return null

}
export default BaseMapLayer
