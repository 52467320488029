import {useEffect} from 'react'
import TileLayer from 'ol/layer/Tile';
import TileArcGISRest from 'ol/source/TileArcGISRest';

import {_getLayer} from  '../../helpers'

const EsriTileLayer = ({map, layer, zIndex}) => {
  useEffect(()=>{
    let tl = _getLayer(map,layer.id)

      if(!tl){

        tl = new TileLayer({
          source: new TileArcGISRest({
            url:`${layer.url}`
          }),
          opacity: layer.opacity,
          visible:1,
          className: 'l-'+layer.id+' l-search',
          name: layer.id
        })
        if(zIndex){
          tl.setZIndex(zIndex)
        }
        map.addLayer(tl);

      }else{
        tl.setVisible(1);
      }

    return () => {
      if(tl){
        tl.setVisible(!1);
      }
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[layer,map,zIndex])
  return null

}
export default EsriTileLayer
