import axios from 'axios'
import {batch} from 'react-redux'
import * as extent from 'ol/extent'

import {getGladData} from './getGladData'
import {getStatData} from './getStatData'
import {getRiskData} from './getRiskData'
import {getTSData} from './getTSData'
import {_hasGroup,_isABoundary,isPin,togglePin,_flyTo} from '../helpers'
import config from '../config'

export const getAdvancedSearchData = (d,activeTool,layers,map,center,zoom) => {
  return (dispatch) => {
    const {apiRPC,apiP8080} = config
    let a={
      unit: layers[activeTool].unit
    } , a2=[], cGrp = !1,cCtry = !1,cRSPO = !1,cISPO = !1,cCncssn = !1,cPvnc = !1,cDist = !1,cPermit = !1,cType = !1,cReg = !1,
    // industry = d.industry,
    sProp = 'All groups/classes - ',
    inpGrp = d.groupcom || [],
    wfsFilter = [];
    if(inpGrp.length){
      if(inpGrp.indexOf('*') < 0){
        if(_hasGroup(activeTool)){
          wfsFilter = [...wfsFilter,['groupcom',inpGrp]]
          if(inpGrp.indexOf('Loreal') > -1){
            wfsFilter = [...wfsFilter,['loreal2019','Yes']]
          }
          sProp = 'Groups: ';
          a.groupcom = '{' + inpGrp.concat() + '}';
        }else{
          sProp = 'Class: ';
          if(_isABoundary(activeTool)){
            wfsFilter = [...wfsFilter,[activeTool,inpGrp]]
            sProp = _isABoundary(activeTool);
            a[activeTool] = '{' + inpGrp.concat() + '}';
          }else{
            a.name = '{' + inpGrp.concat() + '}';
            wfsFilter = [...wfsFilter,['name',inpGrp]]
          }
        }

        a2.push({id:'as-input-group',v:inpGrp});
        sProp += inpGrp.concat()
        cGrp=1;
      }
    }

    if(activeTool === 'protected_area'){
      var inpType = d.types || [];
      if(inpType.indexOf('*') < 0 && inpType.length){
        sProp += cGrp ? ' - ' : '';
        sProp += inpType.concat();
        a.type = '{' + inpType.concat() + '}';
        a2.push({id:'as-input-type',v:inpType});
        wfsFilter = [...wfsFilter,['type',inpType]]
        cType=1;
      }
    }

    var inpCncsn = d.name || [];
    if(inpCncsn.length > 0){
      wfsFilter = [...wfsFilter,['name',inpCncsn.indexOf('{') > -1 ? [inpCncsn.replace('{','').replace('}','').replace(/"/g,'')] : inpCncsn]]
      sProp += cGrp || cType ? ' - ' : '';
      sProp += inpCncsn.concat();
      a.name = inpCncsn.indexOf('{') > -1 ? inpCncsn : '{"' + inpCncsn.concat() + '"}';
      a2.push({id:'as-input-concession',v:inpCncsn});
      cCncssn=1;
    }

    var inpCtry = d.countries || [];
    if(inpCtry.indexOf('*') < 0 && inpCtry.length){
      wfsFilter = [...wfsFilter,['country',inpCtry]]
      sProp += cGrp || cCncssn || cType ? ' - ' : '';
      sProp += `Country: ${inpCtry.concat()}`;
      a.country = '{' + inpCtry.concat() + '}';
      a2.push({id:'as-input-country',v:inpCtry});
      cCtry=1;
    }

    var inpRegion = d.regions || [];
    if(inpRegion.indexOf('*') < 0 && inpRegion.length){
      wfsFilter = [...wfsFilter,['region',inpRegion]]
      sProp += cGrp || cCncssn || cType || cCtry ? ' - ' : '';
      sProp += `Region: ${inpRegion.concat()}`;
      a.region = '{' + inpRegion.concat() + '}';
      // a2.push({id:'as-input-country',v:inpCtry});
      cReg=1;
    }

    var inpPrvc = d.provinces || [];
    if(inpPrvc.indexOf('*') < 0 && inpPrvc.length){
      wfsFilter = [...wfsFilter,['province',inpPrvc]]
      sProp += cGrp || cCncssn || cCtry || cType || cReg ? ' - ' : '';
      sProp += 'Province: ' + inpPrvc.concat();
      a.province = '{' + inpPrvc.concat() + '}';
      a2.push({id:'as-input-province',v:inpPrvc});
      cPvnc=1;
    }

    var inpDist = d.districts || [];
    if(inpDist.indexOf('*') < 0 && inpDist.length){
      wfsFilter = [...wfsFilter,['district',inpDist]]
      sProp += cGrp || cCncssn || cCtry || cPvnc || cType || cReg ? ' - ' : '';
      sProp += 'District: ' + inpDist.concat();
      a.district = '{' + inpDist.concat() + '}';
      a2.push({id:'as-input-district',v:inpDist});
      cDist=1;
    }

    /*
    if(activeTool== 'oilpalm'){
      if ($('#as-input-rspo').is(":checked")) {
          sProp += cGrp || cCncssn || cCtry || cPvnc || cDist ? ' - ' : '';
          a += "RSPO = 'Yes'";
          sProp += 'RSPO Membership';
          cRSPO = 1;
      }

      var perm = $('#as-input-permit').val();
      if (perm != '*' && perm !== '') {
          if (cGrp || cCncssn || cCtry || cPvnc || cDist || cRSPO) {
            a += ' AND ';
            sProp += ' - ';
          }
          a += "Permit = '" + perm + "'";
          sProp += 'Permit: '+perm;
          cPermit = 1;
      }

    }

    if(activeTool== 'pulpwood'){
      if ($('#as-input-ispo').is(":checked")) {
          sProp += cGrp || cCncssn || cCtry || cPvnc || cDist  || cRSPO ? ' - ' : '';
          a += "ispo = 'Yes'";
          sProp += 'ISPO Membership';
          cISPO = 1;
      }
    }*/

    var peatPropBottom = d.peatProp ? d.peatProp[0] : 0,
    peatPropTop = d.peatProp ? d.peatProp[1] : 100;
    if(peatPropBottom > 0 || peatPropTop < 100){
      wfsFilter = [...wfsFilter,['peatareaprop',[peatPropBottom,peatPropTop]]]
      sProp += cGrp || cCncssn || cCtry || cPvnc || cDist || cRSPO || cISPO || cPermit || cType || cReg ? ' - ' : '';
      sProp += 'Proportion of peatland area in concession greater than or equal to '+ peatPropBottom +'% and less than or equal to ' +peatPropTop+ '%';
      a.peatareaprop = '{'+peatPropBottom+','+peatPropTop+'}';
      a2.push({id:'as-input-peat-proportion-bottom-value',v:peatPropBottom});
      a2.push({id:'as-input-peat-proportion-top-value',v:peatPropTop});
    }

    var forPropBottom = d.forProp ? d.forProp[0] : 0,
    forPropTop = d.forProp ? d.forProp[1] : 100;
    if(forPropBottom > 0 || forPropTop < 100){
      wfsFilter = [...wfsFilter,['remforestprop',[forPropBottom,forPropTop]]]
      sProp += cGrp || cCncssn || cCtry || cPvnc || cDist || cRSPO || cISPO || cPermit || cType || cReg ? ' - ' : '';
      sProp += 'Proportion of remaining forest area in concession greater than or equal to '+ forPropBottom +'% and less than or equal to ' +forPropTop+ '%';
      a.remforestprop = '{'+forPropBottom+','+forPropTop+'}';
      a2.push({id:'as-input-forest-bottom-value',v:forPropBottom});
      a2.push({id:'as-input-forest-top-value',v:forPropTop});
    }
    a2.push({id:'sProp',v:sProp});

    let $xml = `<GetFeature xmlns="http://www.opengis.net/wfs" service="WFS" version="1.1.0" outputFormat="application/json" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd">
      <Query typeName="${layers[activeTool].layer}v" srsName="EPSG:4326"><Filter xmlns="http://www.opengis.net/ogc">`

        let w = wfsFilter.length - 1,
        fLength = wfsFilter.length
        $xml += fLength > 1 ? `<And>` : ''
          while(w>=0){
            if(Array.isArray(wfsFilter[w][1])){

              if(wfsFilter[w][1].length === 1){
                if(wfsFilter[w][0] === 'groupcom' && wfsFilter[w][1][0] === 'Loreal'){
                  $xml += `<PropertyIsEqualTo><PropertyName>loreal2019</PropertyName><Literal>Yes</Literal></PropertyIsEqualTo>`
                }else{
                  $xml += `<PropertyIsEqualTo><PropertyName>${wfsFilter[w][0]}</PropertyName><Literal>${wfsFilter[w][1][0]}</Literal></PropertyIsEqualTo>`
                }
              }else{

                if(wfsFilter[w][0] === 'peatareaprop' || wfsFilter[w][0] === 'remforestprop'){
                  $xml += `<PropertyIsBetween>
                    <PropertyName>${wfsFilter[w][0]}</PropertyName>
                    <LowerBoundary><Literal>${wfsFilter[w][1][0]}</Literal></LowerBoundary>
                    <UpperBoundary><Literal>${wfsFilter[w][1][1]}</Literal></UpperBoundary>
                  </PropertyIsBetween>`
                }else if(wfsFilter[w][0] === 'groupcom' && wfsFilter[w][1][0] === 'Loreal'){
                  $xml += `<PropertyIsEqualTo><PropertyName>loreal2019</PropertyName><Literal>Yes</Literal></PropertyIsEqualTo>`
                }else{
                  $xml += `<Or>`
                  let i0 = wfsFilter[w][1].length - 1
                  while(i0>=0){
                    $xml += `<PropertyIsEqualTo><PropertyName>${wfsFilter[w][0]}</PropertyName><Literal>${wfsFilter[w][1][i0]}</Literal></PropertyIsEqualTo>`
                    i0--;
                  }
                  $xml += `</Or>`
                }
              }


            }else{
              $xml += `<PropertyIsEqualTo><PropertyName>${wfsFilter[w][0]}</PropertyName><Literal>${wfsFilter[w][1]}</Literal></PropertyIsEqualTo>`
            }
            w--;
          }
        $xml += fLength > 1 ? `</And>` : ''

        $xml += `</Filter></Query></GetFeature>`
        axios.post( apiP8080 + 'geoserver/wfs',$xml,{headers:{'Content-Type': 'text/xml'}})

        .then(function(r){

          if(r.data.features.length){
            let rdf = r.data.features,
            i = rdf.length-1,
            AreaHA = 0,
            cIDs = [],
            ext = new extent.createEmpty();

            while(i>=0){
              let p = rdf[i].properties;
              extent.extend(ext,rdf[i].bbox)
              AreaHA = AreaHA + p.areaha;
              cIDs.push(p.cid);
              i--;
            }

            batch(()=>{
              dispatch({type:'_LOADER',payload:['appLoader',false]})
              dispatch({type:'_SEARCH',payload:['aSearchLoader',false]})
              dispatch({type:'_SEARCH',payload:['min',1]})
              dispatch({type:'_SEARCH',payload:['aSearchForm',0]})
              dispatch({type:'_APP',payload:['sgs','stat']})
              dispatch({type:'_APP',payload:['cIDs',cIDs]})
              dispatch({type:'_APP',payload:['areaha',AreaHA]})
              dispatch({type:'_SEARCH',payload:['searchData',r.data.features]})
              dispatch({type:'SET_FEAT_DATA',payload:{
                group : {
                  areaha: AreaHA,
                  id:activeTool,
                  featTitle:layers[activeTool].title,
                  sProp
                }
              }})
              dispatch({type:'SET_DEFAULT_TAB'})
              // dispatch({type:'SET_SINGLE_CLICK',payload:data})
              dispatch(getGladData({apiRPC,single:!1,sgs:'stat',activeTool,goSearch:!1,cIDs,layers}))
              dispatch(getStatData({apiRPC,single:!1,sgs:'stat',activeTool,goSearch:!1,cIDs,layers}))
              dispatch(getRiskData({apiRPC,single:!1,sgs:'stat',activeTool,goSearch:!1,cIDs,layers}))
              dispatch(getTSData({apiRPC,single:!1,sgs:'stat',activeTool,goSearch:!1,cIDs,layers}))
            })

            if(!center && map.getView().getZoom() > 4){
              _flyTo(extent.getCenter(ext), map.getView().getZoomForResolution(map.getView().getResolutionForExtent(ext)) - 0.25, map.getView(),(done)=>{
                if(done && !isPin()){
                  togglePin(map,1)
                }
              })
            }else{
              map.getView().animate({
                center: !center ? extent.getCenter(ext) : center,
                zoom: !zoom ? map.getView().getZoomForResolution(map.getView().getResolutionForExtent(ext)) - 0.25 : zoom,
                duration: 2500
              },function(){
                if(!isPin()){
                  togglePin(map,1)
                }
              });
            }
            if(center){
              dispatch({type:'_SHARE',payload:['hasLink',!1]})
            }

          }else{
            console.log('FAILED ON RES',r);
            batch(()=>{
              dispatch({type:'_LOADER',payload:['appLoader',false]})
              dispatch({type:'_SEARCH',payload:['aSearchLoader',false]})
              dispatch({type:'_DATA',payload:['featData',{}]})
            })
          }

        });



  }
}
