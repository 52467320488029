const inits = {
  initLayers: !1,
  layers: !1,
  defaultLayers: !1,
  WMTSCapabilities: !1,
  _xyz: !1,
  ll:!1,
  _73Layer: !1,
  gladRaster: !1,
  riskRaster: !1
}

function getAllLayers(layers){
  let all = {}
  layers.map(function(l){
    return all[l.id] = {...l,show: !1}
  })
  return all;
}

const LayersReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_LAYERS':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_LAYERS':
      return { ...s,
        initLayers: payload.layers,
        layers: getAllLayers(payload.layers),
        defaultLayers: payload.init,
      }

    case 'SET_LAYER_PARSER':
      return { ...s,
        WMTSCapabilities: payload[0],
        _xyz: payload[1],
        ll: payload[2]
      }

    default:
      return s;
  }
};
export default LayersReducer
