import axios from 'axios'
import {batch} from 'react-redux'

import {_isABoundary} from '../helpers'

let d,
dData = {
  forest:0,mang:0,peat:0,
  forestLoss:[0,0,0],
  forestClr:[0,0,0,0,0,0,0,0,0,0],
  nonForest:[0,0,0],
  planted: [0,0,0],
  _2KPlant:[0,0,0],
  f73:[0,0,0]
};

const setDF = () => {
  let i = d.length - 1;
  while (i>=0) {
    if(d[i].class==="Remaining forest on mineral soil"){d.d.forest += d[i].areaha}
    if(d[i].class==="Remaining mangrove forest"){d.d.mang += d[i].areaha}
    if(d[i].class==="Remaining peat swamp forest"){d.d.peat += d[i].areaha}

    if(d[i].class==="Forest on mineral soil loss"){d.d.forestLoss[0] += d[i].areaha}
    if(d[i].class==="Mangrove forest loss"){d.d.forestLoss[1] += d[i].areaha}
    if(d[i].class==="Peat swamp forest loss" ){d.d.forestLoss[2] += d[i].areaha}

    if(d[i].class==="Non-forest between 1970-1990" || d[i].class==="Non-forest between 1970-1990, tree loss after 2000" || d[i].class==="Non-forest between 1990-2000" || d[i].class==="Non-forest between 1990-2000, tree loss after 2000"){
      d.d.f73[0] += d[i].areaha;
    }

    i--;
  }

}

const setIP = (data) => {
  var d2 = data,i = d2.length - 1;
  while (i>=0) {

    if(d2[i].class==="Forest on mineral soil to oilpalm"){
      d.d.forestClr[0] += d2[i].areaha;d.d.forestClr[9] += d2[i].areaha;
      d.d.planted[0] += d2[i].areaha;
      d.d._2KPlant[0] += d2[i].areaha;
    }
    if(d2[i].class==="Mangrove forest to oilpalm"){
      d.d.forestClr[1] += d2[i].areaha;d.d.forestClr[9] += d2[i].areaha;
      d.d.planted[0] += d2[i].areaha;
      d.d._2KPlant[0] += d2[i].areaha;
    }
    if(d2[i].class==="Peat swamp forest to oilpalm" ){
      d.d.forestClr[2] += d2[i].areaha;d.d.forestClr[9] += d2[i].areaha;
      d.d.planted[0] += d2[i].areaha;
      d.d._2KPlant[0] += d2[i].areaha;
    }

    if(d2[i].class==="Forest on mineral soil to pulpwood"){
      d.d.forestClr[3] += d2[i].areaha;d.d.forestClr[9] += d2[i].areaha;
      d.d.planted[1] += d2[i].areaha;
      d.d._2KPlant[1] += d2[i].areaha;
    }
    if(d2[i].class==="Mangrove forest to pulpwood"){
      d.d.forestClr[4] += d2[i].areaha;d.d.forestClr[9] += d2[i].areaha;
      d.d.planted[1] += d2[i].areaha;
      d.d._2KPlant[1] += d2[i].areaha;
    }
    if(d2[i].class==="Peat swamp forest to pulpwood" ){
      d.d.forestClr[5] += d2[i].areaha;d.d.forestClr[9] += d2[i].areaha;
      d.d.planted[1] += d2[i].areaha;
      d.d._2KPlant[1] += d2[i].areaha;
    }

    if(d2[i].class==="Forest on mineral soil to smallholder"){
      d.d.forestClr[6] += d2[i].areaha;d.d.forestClr[9] += d2[i].areaha;
      d.d.planted[2] += d2[i].areaha;
      d.d._2KPlant[2] += d2[i].areaha;
    }
    if(d2[i].class==="Mangrove forest to smallholder"){
      d.d.forestClr[7] += d2[i].areaha;d.d.forestClr[9] += d2[i].areaha;
      d.d.planted[2] += d2[i].areaha;
      d.d._2KPlant[2] += d2[i].areaha;
    }
    if(d2[i].class==="Peat swamp forest to smallholder" ){
      d.d.forestClr[8] += d2[i].areaha;d.d.forestClr[9] += d2[i].areaha;
      d.d.planted[2] += d2[i].areaha;
      d.d._2KPlant[2] += d2[i].areaha;
    }

    if(d2[i].class==="Non-forest to oilpalm"){
      d.d.nonForest[0] += d2[i].areaha;
      d.d.planted[0] += d2[i].areaha;
      d.d._2KPlant[0] += d2[i].areaha;
    }
    if(d2[i].class==="Non-forest to pulpwood"){
      d.d.nonForest[1] += d2[i].areaha;
      d.d.planted[1] += d2[i].areaha;
      d.d._2KPlant[1] += d2[i].areaha;
    }
    if(d2[i].class==="Non-forest to smallholder"){
      d.d.nonForest[2] += d2[i].areaha;
      d.d.planted[2] += d2[i].areaha;
      d.d._2KPlant[2] += d2[i].areaha;
    }

    if(d2[i].class==="Oilpalm before 2000"){
      d.d.planted[0] += d2[i].areaha;
      d.d.f73[1] += d2[i].areaha;
    }
    if(d2[i].class==="Pulpwood before 2000"){
      d.d.planted[1] += d2[i].areaha;
      d.d.f73[1] += d2[i].areaha;
    }
    if(d2[i].class==="Smallholder before 2000"){
      d.d.planted[2] += d2[i].areaha;
      d.d.f73[1] += d2[i].areaha;
    }

    i--;
  }

  d.d.f73[0] += d.d.forestLoss[0] + d.d.forestLoss[1] + d.d.forestLoss[2];
  d.d.f73[1] += d.d.forestClr[9];
}

const setDispatch = ({dispatch,_t}) => {
  batch(()=>{
    dispatch({type:'SET_STAT_DATA',payload:{
      [_t.sgs==='single' ? _t.sgs : 'group']: {...d,rank: null}
    }})
    dispatch({type:'SET_73_DATA',payload:{
      [_t.sgs==='single' ? _t.sgs : 'group']: {
        d:[
          [d.d.forest + d.d.mang + d.d.peat,d.d.forest,d.d.mang,d.d.peat],
          [d.d.planted[0] + d.d.planted[1] + d.d.planted[2],d.d.planted[0],d.d.planted[1],d.d.planted[2]],
          d.d.f73[0],
          d.d.f73[1]
        ],
        rank: null
      }
    }})
  })
}

export const getStatData = (_t) => {
  return (dispatch) => {

    if(_t.aoi){

      d = _t.aoi.sumalldf;
      d.d = dData;
      setDF();
      setIP(_t.aoi.sumallip);
      setDispatch({dispatch,_t});

    }else{

      axios.post(_t.apiRPC + 'atlas_getsummarybyid',{
        cid:'{'+ _t.cIDs.concat() +'}',
        unit: _t.layers[_t.single ? _t.single.id : _t.activeTool].unit,
        datatype:'df'
      })
      .then(function(r){

        d = r.data;
        d.d = dData;

        setDF();

        axios.post(_t.apiRPC + 'atlas_getsummarybyid',{
          cid:'{'+ _t.cIDs.concat() +'}',
          unit: _t.layers[_t.single ? _t.single.id : _t.activeTool].unit,
          datatype:'ip'
        })
        .then(function(r2){

          setIP(r2.data);

          const baOptions = {
            unit: _t.layers[_t.single ? _t.single.id : _t.activeTool].unit,
            year: 2019
          }
          let allBA = false;
          if(_t.sgs==='group'){
            if(_t.goSearch.indexOf('All') > -1 || _t.goSearch.indexOf('Semua') > -1){
              allBA = true;
            }
          }
          if(_t.sgs==='group' && !allBA && !_isABoundary(_t.activeTool)){
            baOptions.groupcom = `{${_t.goSearch}}`
          }else{
            baOptions.cid = `{${_t.cIDs.concat()}}`
          }
          axios.post(`${_t.apiRPC}${_t.sgs==='group' && !allBA && !_isABoundary(_t.activeTool) ? 'atlas_getbabygroup' : 'atlas_getbabyid'}`,baOptions)
          .then((r3)=>{
            d.d.burnarea = r3.data.length ? r3.data[0].areaha : 0

            setDispatch({dispatch,_t});

            if(_t.sgs==='group' || _t.sgs==='single'){
              if(_t.sgs==='single' || (_t.sgs==='group' && (_t.goSearch.indexOf('All ') < 0 || _t.goSearch.indexOf('Semua ') < 0))){
                axios.post(_t.apiRPC + 'atlas_getrank',{
                  unit: _t.layers[_t.single ? _t.single.id : _t.activeTool].unit,
                  datatype: _t.sgs==='single' ? 'single' : (_t.layers[_t.activeTool].gUrl ? 'group' : 'single'),
                  id: _t.sgs==='single' ? _t.cIDs[0] : (_t.layers[_t.activeTool].gUrl ? _t.goSearch : _t.cIDs[0])
                })
                .then(function(r){
                  if(r.data){
                    axios.post(`${_t.apiRPC}atlas_getrankba`,{
                      unit: _t.layers[_t.single ? _t.single.id : _t.activeTool].unit,
                      datatype: _t.sgs==='single' ? 'single' : (_t.layers[_t.activeTool].gUrl ? 'group' : 'single'),
                      id: _t.sgs==='single' ? _t.cIDs[0] : (_t.layers[_t.activeTool].gUrl ? _t.goSearch : _t.cIDs[0]),
                      year: 2019
                    }).then(function(r2){
                      dispatch({type:'SET_STAT_DATA',payload:{
                        [_t.sgs]: {...d,rank: r.data[0],rankba: r2.data[0]}
                      }})
                    });
                  }
                });

              }
            }

          }).catch((e)=>{
            console.log(e.response);
          })


        });

      })
      .catch((e)=>{
        console.log(e.response);
      });

    }


  }
}
