import React from 'react'
import {useSelector} from 'react-redux'
import {AngleRight} from '@styled-icons/fa-solid'

const SbSubTitle = () => {

  const {rLang, lang} = useSelector(s=>s.lang)
  const {activeTool} = useSelector(s=>s.tool)

  switch (activeTool) {
    case 'oilpalm':
    case 'pulpwood':
    case 'logging':
    case 'mining':
      return (<>{`${rLang.Concessions[lang]}  `} <AngleRight size="13"/>  {`  ${rLang[activeTool.charAt(0).toUpperCase() + activeTool.slice(1)][lang]}`}</>)

      case 'luzN':
      return (<>{`${rLang.LandUseZone[lang]}  `} <AngleRight size="13"/>  {`  ${rLang.National[lang]}`}</>)
      case 'luzF':
      return (<>{`${rLang.LandUseZone[lang]}  `} <AngleRight size="13"/>  {`  ${rLang.ForestZone[lang]}`}</>)
      case 'luzP':
      return (<>{`${rLang.LandUseZone[lang]}  `} <AngleRight size="13"/>  {`  ${rLang.PapuaProvince[lang]}`}</>)
      case 'luzPB':
      return (<>{`${rLang.LandUseZone[lang]}  `} <AngleRight size="13"/>  {`  ${rLang.WestPapuaProvince[lang]}`}</>)

      case 'mill':
      return (<>{rLang.NearMills[lang]}</>)

      case 'protected_area':
      return (<>{rLang.ProtectedArea[lang]}</>)

      case 'road':
      return (<>{rLang.NearRoads[lang]}</>)

      case 'aoiDRAW':
      case 'aoiZIP':
      return (<>{rLang.AreaofInterest[lang]}</>)

      case 'country':
      return (<>{`${rLang.AdministrativeBoundary[lang]}  `} <AngleRight size="13"/>  {` ${rLang.Countries[lang]}`}</>)

      case 'province':case 'district':
      return (<>{`${rLang.AdministrativeBoundary[lang]}  `} <AngleRight size="13"/>  {` ${rLang[activeTool.charAt(0).toUpperCase() + activeTool.slice(1) + 's'][lang]}`}</>)
    default:
      return null
  }
}
export default SbSubTitle
