const inits = {
  title:null,
  body:null
}

const PopupOnMapReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_POPUP_ON_MAP':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default PopupOnMapReducer
