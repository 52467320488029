import React,{useEffect,useRef,lazy, Suspense} from "react"
import {useSelector,useDispatch} from 'react-redux'
import axios from 'axios'

import MapOnClick from './MapOnClick'
import SearchGroup from './SearchGroup'
import Layers from './layers/Layers'
import BaseMapLayer from './layers/BaseMapLayer'
import Swipe73 from './maps/Swipe73'
import AoiDraw from './maps/AoiDraw'
import AoiZip from './maps/AoiZip'
import AlertVerificationSystem from './maps/AlertVerificationSystem'
import WelcomeScreen from './popups/WelcomeScreen'

import MapLoader from './loaders/MapLoader'
import ToolScreen from './toolScreen/ToolScreen'

import {setMap} from '../actions/mapAction'
import {_getUrlParameter} from '../helpers'
import config from '../config'

const TimeSlider = lazy(() => import('./maps/TimeSlider'));
const PopupOnMap = lazy(() => import('./popups/PopupOnMap'));
const DashboardPanel = lazy(() => import('./dashboard/DashboardPanel'));

const renderLoader = () => <MapLoader/>;

const OLMap = () => {
  const {activeTab} = useSelector(s=>s.tab)
  const {map} = useSelector(s=>s.map)
  const {hasLink} = useSelector(s=>s.share)
  const dispatch = useDispatch()
  const mapRef = useRef()

  useEffect(()=>{
    if(!map && typeof window !== undefined){
      const aoiSLink = _getUrlParameter('aoi',window.location)
      if(aoiSLink){

        let aoi = {
          df:!1,
          glad:!1,
          ip:!1,
          probdf:!1,
          sp:!1
        }

        axios.post(`${config.apiLink}aoi/`,{
          id:aoiSLink
        })
        .then((r)=>{

          if(r.data.status){
            aoi = {...aoi,...r.data}
          }
          // dispatch(setInitData({
          //   defaultLayers:r.data.init,
          //   layers:getAllLayers(r.data.layers),
          //   tool: 'aoi',
          //   map:mapObject,
          //   aoiSLink
          // }))
          // _t.aoi = {
          //   df: r.data
          // }



          dispatch(setMap({mapRef,hasLink,aoi}))
        }).catch(()=>{
          dispatch(setMap({mapRef,hasLink}))
        })
      }else{
        dispatch(setMap({mapRef,hasLink}))
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[])
  return (
    <>
      <div ref={mapRef} id="ol-map">
        <div id="cursorCord"/>
        <div id="scalebar"/>
        <div id="swipe73" className={activeTab !== 'deforestation' ? 'hide' : ''}><div className="handle"/></div>
        <AoiDraw/>
        <AoiZip/>
        <Suspense fallback={renderLoader()}>
          <TimeSlider/>
        </Suspense>
        <AlertVerificationSystem/>
        <MapLoader/>

        <Suspense fallback={renderLoader()}>
          <DashboardPanel/>
        </Suspense>
        <ToolScreen/>
        <Suspense fallback={renderLoader()}>
          <PopupOnMap/>
        </Suspense>
        <WelcomeScreen/>
      </div>
      {
        map && (<>
          <BaseMapLayer/>
          <MapOnClick/>
          <SearchGroup/>
          <Layers/>
          <Swipe73/>
        </>)
      }
    </>
  )
}
export default OLMap
