const inits = {
  show:!1,
  regionList: [],
  from:'',
  to:'',
  open: !1,
  active: 'All',
  rIOP: !1,
  rIOPGroup: !1,
  rMills: !1,
  rITP: !1,
  rITPGroup: !1,
  rDistricts: !1,
  rPA: !1 
}

function setRegionList(d){
  var i = 0, t = d.length, r = ['All'];
  while(i<t){
    r = [...r,d[i].region]
    i++;
  }
  return r;
}

const DashboardReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_DASHBOARD':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_DASHBOARD_REGION_LIST':
      return { ...s, regionList: setRegionList(payload) }

    case 'SET_DASHBOARD_STATE':
      return { ...s,
        active: payload[0], open: !1, from: payload[1][0], to: payload[1][1],
        rIOP: !1,
        rIOPGroup: !1,
        rMills: !1,
        rITP: !1,
        rITPGroup: !1,
        rDistricts: !1,
        rPA: !1
      }

    default:
      return s;
  }
};
export default DashboardReducer
