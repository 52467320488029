import React, {useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {AngleRight} from '@styled-icons/fa-solid'

import axios from 'axios'

import imgC from '../../images/tools/Tools-P15-CIFOR.jpg'
import imgP from '../../images/tools/Tools-P17-CIFOR.jpg'
import imgD from '../../images/tools/Tools-P18-CIFOR.jpg'

import {setInitData} from '../../actions/initAction'
import {isPin,togglePin} from '../../helpers'
import config from '../../config'

const createDOMPurify = require('dompurify');
let DOMPurify = null;

const setStart = ({setApp,defaultLayers,layers,tool,map,aoiLayerResult}) => {
  if(aoiLayerResult){
    map.removeLayer(aoiLayerResult)
  }
  setApp(setInitData({defaultLayers,layers,tool}))
  if(!isPin()){
    togglePin(map)
  }
}

const TSadmin = () => {
  const {map} = useSelector(s=>s.map)
  const {rLang, lang} = useSelector(s=>s.lang)
  const toolScreen = useSelector(s=>s.toolScreen)
  const setApp = useDispatch()
  const { layers,defaultLayers } = useSelector(s=>s.layers);
  const {aoiLayerResult} = useSelector(s=>s.aoi)

  useEffect(()=>{
    if(typeof window !== undefined){
      DOMPurify = createDOMPurify(window);
    }
    if(map && typeof document !== undefined){
      /* Country */
      axios.get(`${config.apiP1}${layers.country.sLayer}?select=count`)
      .then(function(r){
        var t = r.data[0].count;
        document.querySelector('#sum-c .sum-t > div').innerText = t;
        document.querySelector('#sum-p .sum-g > div').innerText = t;
        document.querySelector('#sum-d .sum-g2 > div').innerText = t;
      });

      /* Province */
      axios.get(`${config.apiP1}${layers.province.sLayer}?select=count`)
      .then(function(r){
        var t = r.data[0].count;
        document.querySelector('#sum-p .sum-t > div').innerText = t;
        document.querySelector('#sum-d .sum-g > div').innerText = t;
      });

      /*District*/
      axios.get(`${config.apiP1}${layers.district.sLayer}?select=count`)
      .then(function(r){
        document.querySelector('#sum-d .sum-t > div').innerText = r.data[0].count;
      });

    }


  },[map])

  return (
    <>
      <div id="tool-info-admin" className="tool-info" style={toolScreen.tool !== 'admin' ? css.hide : css.show}>
        <div className="tool-bg"/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">

              {
                DOMPurify && (
                  <>
                    <div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolAdminTitle[lang])}}/>
                    <div className="tool-info-desc" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolAdminDesc[lang])}}/>
                    <div className="tool-info-option-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.AnalyzeIn[lang])}}/>
                  </>
                )
              }

              <button className="btn btn-primary btn-oilpalm go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'country',bg:imgC}})} style={{marginLeft:0}}><span>{rLang.Countries[lang]}</span><span><AngleRight className="d-angle"/></span></button>
              <button className="btn btn-primary btn-pulpwood go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'province',bg:imgP}})}><span>{rLang.Provinces[lang]}</span><span><AngleRight className="d-angle"/></span></button>
              <button className="btn btn-primary btn-logging go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'district',bg:imgD}})}><span>{rLang.Districts[lang]}</span><span><AngleRight className="d-angle"/></span></button>

            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-country" style={toolScreen.tool !== 'country' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                {DOMPurify && (<div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolCTitle[lang])}}/>)}
                <div id="sum-c" className="sum-d">
                  <div className="sum-t">
                    <div></div>
                    <span>{rLang.Countries[lang]}</span>
                  </div>
                </div>
                {DOMPurify && (<div className="tool-info-desc" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolAdminDesc[lang])}}/>)}
                <button onClick={()=>{
                  setStart({setApp,tool:'country',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-province" style={toolScreen.tool !== 'province' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                {DOMPurify && (<div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolPTitle[lang])}}/>)}
                <div id="sum-p" className="sum-d">
                  <div className="sum-t">
                    <div></div>
                    <span>{rLang.Provinces[lang]}</span>
                  </div>
                  <div className="sum-g">
                    <div></div>
                    <span>{rLang.Countries[lang]}</span>
                  </div>
                </div>
                {DOMPurify && (<div className="tool-info-desc" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolAdminDesc[lang])}}/>)}
                <button onClick={()=>{
                  setStart({setApp,tool:'province',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-district" style={toolScreen.tool !== 'district' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                {DOMPurify && (<div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolDTitle[lang])}}/>)}
                <div id="sum-d" className="sum-d">
                  <div className="sum-t">
                    <div></div>
                    <span>{rLang.Districts[lang]}</span>
                  </div>
                  <div className="sum-g">
                    <div></div>
                    <span>{rLang.Provinces[lang]}</span>
                  </div>
                  <div className="sum-g2">
                    <div></div>
                    <span>{rLang.Countries[lang]}</span>
                  </div>
                </div>
                {DOMPurify && (<div className="tool-info-desc" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolAdminDesc[lang])}}/>)}
                <button onClick={()=>{
                  setStart({setApp,tool:'district',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const css = {
  show: {
    opacity:1,
    zIndex:1,
    height:'auto',
    overflow:'auto'
  },
  hide: {
    opacity:0,
    zIndex:-1,
    height:0,
    overflow:'hidden'
  }
}

export default TSadmin
