import React from 'react'
import {useImmer} from 'use-immer'

const onFocus = ({groupList,thisState,setThisState,minChar}) => {
  if(groupList){
    if((minChar && thisState.text.length >= minChar) || !minChar){
      const regex = new RegExp(`^.*${thisState.text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')}.*$`, `i`);
      const s = thisState.text ? groupList.filter(v => regex.test(v)) : groupList
      setThisState((d) => {
        d.suggestions = s
        d.show = !0
        d.go = !1
        d.l = s.length - 1
      });
    }else{
      setThisState((d) => {
        d.show = !1
      })
    }
  }
}

const onBlur = ({thisState,setThisState,minChar}) => {
  if((minChar && thisState.text.length >= minChar) || !minChar){
    setTimeout(function(){
      setThisState((d) => {
        d.show = !1
      });
    },130)
  }
}

const onTextChange = ({e,groupList,setThisState,callback,minChar}) => {
  callback(e.target.value);
  setThisState((d) => {
    if((minChar && e.target.value.length >= minChar) || !minChar){
      const regex = new RegExp(`${e.target.value.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')}`, `i`);
      const s = e.target.value.length > 0 ? groupList.filter(v => regex.test(v)) : groupList
      d.suggestions = s
      d.show = !0
      d.l = s.length - 1
    }else{
      d.show = !1
    }
    d.text = e.target.value
  });
}

const TypeAheadComponent = (props) => {
  const [thisState,setThisState] = useImmer({
    suggestions: [],
    text:props.value || '',
    show: !1,
    go:!1,
    h:-1,
    l:0
  });
  const groupList = props.data

  const suggestionSelected = (value) => {
    props.callback(value);
    setThisState((d)=>{
      d.text = value
      d.suggestions = []
      d.show = !1
      d.go = value
      d.h = -1
      d.l = 0
    })
  }

  const renderSuggestions = () => {
    if (!thisState.show) {
      return null;
    }
    const { suggestions } = thisState;
    return (
      <div className="tt-menu" style={{position: 'absolute',top: '100%',left: 0,zIndex: 100}}>
        <div className="tt-dataset tt-dataset-basic-search">
          {
            suggestions.map((g,i) => {
            return (
              <div role="presentation" key={g}
                onClick={(e)=>suggestionSelected(g)}
                className={`tt-suggestion ${thisState.h === i ? 'tt-hover' : ''}`}
              >{g}</div>
            )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <div className="typeahead-block" style={{position: 'relative',display: 'inline-block',width:'100%'}}>
      <input
        id={props.id ? props.id : ''}
        onChange={(e)=>{
          onTextChange({e,groupList,setThisState,callback:props.callback,minChar:props.minChar})
        }}
        autoComplete="off"
        onFocus={() => onFocus({groupList,thisState,setThisState,minChar:props.minChar})}
        onBlur={()=>onBlur({thisState,setThisState,minChar:props.minChar})}
        className={`atlas-typeahead ${props.className}`}
        type="text"
        placeholder={props.placeholder ? props.placeholder : ''}
        value={props.value || thisState.text}
        style={{position: 'relative',verticalAlign: 'top'}}
        onKeyDown={(e)=>{
          if (e.keyCode === 38 && thisState.h > 0) {
            setThisState( d => {
              d.h = thisState.h - 1
            })
          } else if (e.keyCode === 40 && thisState.h < thisState.l) {
            setThisState( d => {
              d.h = thisState.h + 1
            })
          }
          if(e.keyCode === 13){
            if(thisState.suggestions[thisState.h]){
              suggestionSelected(thisState.suggestions[thisState.h])
            }else{
              suggestionSelected(e.target.value)
            }
          }
        }}
        />
      {renderSuggestions()}
    </div>
  )

}

export default TypeAheadComponent
