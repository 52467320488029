import React, {useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {AngleRight} from '@styled-icons/fa-solid'

import axios from 'axios'

import imgConIOP from '../../images/tools/Tools-P3-CIFOR.jpg'
import imgConITP from '../../images/tools/Tools-P4-CIFOR.jpg'
import imgConLogging from '../../images/tools/Tools-P5-CIFOR.jpg'
import imgConMining from '../../images/tools/Tools-P6-CIFOR.jpg'

import {setInitData} from '../../actions/initAction'
import {isPin,togglePin} from '../../helpers'

const createDOMPurify = require('dompurify');
let DOMPurify = null;

const setStart = ({setApp,defaultLayers,layers,tool,map,aoiLayerResult}) => {
  if(aoiLayerResult){
    map.removeLayer(aoiLayerResult)
  }
  setApp(setInitData({defaultLayers,layers,tool}))
  if(!isPin()){
    togglePin(map)
  }
}

const TSconcessions = () => {


  const toolScreen = useSelector(s=>s.toolScreen)
  const {map} = useSelector(s=>s.map)
  const {rLang, lang} = useSelector(s=>s.lang)
  const setApp = useDispatch()
  const { layers, defaultLayers } = useSelector(s=>s.layers);
  const {aoiLayerResult} = useSelector(s=>s.aoi)

  useEffect(()=>{
    if(typeof window !== undefined){
      DOMPurify = createDOMPurify(window);
    }
    if(map && typeof document !== undefined){
      /* IOP */
      axios.get('https://nusantara-atlas.org/dbrest/' + layers.oilpalm.sLayer + '?select=count')
      .then(function(r){
        document.querySelector('#sum-IOP .sum-t > div').innerText = r.data[0].count;
      });
      axios.get('https://nusantara-atlas.org/dbrest/' + layers.oilpalm.gUrl + '?select=count')
      .then(function(r){
        document.querySelector('#sum-IOP .sum-g > div').innerText = r.data[0].count;
      });

      /* ITP */
      axios.get('https://nusantara-atlas.org/dbrest/' + layers.pulpwood.sLayer + '?select=count')
      .then(function(r){
        document.querySelector('#sum-ITP .sum-t > div').innerText = r.data[0].count;
      });
      axios.get('https://nusantara-atlas.org/dbrest/' + layers.pulpwood.gUrl + '?select=count')
      .then(function(r){
        document.querySelector('#sum-ITP .sum-g > div').innerText = r.data[0].count;
      });

      /*Logging*/
      axios.get('https://nusantara-atlas.org/dbrest/' + layers.logging.sLayer + '?select=count')
      .then(function(r){
        document.querySelector('#sum-log .sum-t > div').innerText = r.data[0].count;
      });
      axios.get('https://nusantara-atlas.org/dbrest/' + layers.logging.gUrl + '?select=count')
      .then(function(r){
        document.querySelector('#sum-log .sum-g > div').innerText = r.data[0].count;
      });

      /*Mining*/
      axios.get('https://nusantara-atlas.org/dbrest/' + layers.mining.sLayer + '?select=count')
      .then(function(r){
        document.querySelector('#sum-mining .sum-t > div').innerText = r.data[0].count;
      });
      axios.get('https://nusantara-atlas.org/dbrest/' + layers.mining.gUrl + '?select=count')
      .then(function(r){
        document.querySelector('#sum-mining .sum-g > div').innerText = r.data[0].count;
      });

    }



  },/*eslint-disable-line react-hooks/exhaustive-deps*/[map])

  return (
    <>
      <div id="tool-info-concession" className="tool-info" style={toolScreen.tool !== 'concession' ? css.hide : css.show}>
        <div className="tool-bg"/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              {
                DOMPurify && (
                  <>
                    <div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolConTitle[lang])}}/>
                    <div className="tool-info-desc" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolConDesc[lang])}}/>
                    <div className="tool-info-option-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.AnalyzeIn[lang])}}/>
                  </>
                )
              }

              <button className="btn btn-primary btn-oilpalm go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'concession-oilpalm',bg:imgConIOP}})} style={{marginLeft:0}}><span>{rLang.Oilpalm[lang]}</span><span><AngleRight className="d-angle"/></span></button>
              <button className="btn btn-primary btn-pulpwood go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'concession-pulpwood',bg:imgConITP}})}><span>{rLang.Pulpwood[lang]}</span><span><AngleRight className="d-angle"/></span></button>
              <button className="btn btn-primary btn-logging go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'concession-logging',bg:imgConLogging}})}><span>{rLang.Logging[lang]}</span><span><AngleRight className="d-angle"/></span></button>
              <button className="btn btn-primary btn-mining go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'concession-mining',bg:imgConMining}})}><span>{rLang.Mining[lang]}</span><span><AngleRight className="d-angle"/></span></button>

            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-concession-oilpalm" style={toolScreen.tool !== 'concession-oilpalm' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">

                {DOMPurify && (<div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolIOPTitle[lang],{ALLOWED_TAGS: ['br']})}}/>)}
                <div id="sum-IOP" className="sum-d">
                  <div className="sum-t">
                    <div></div>
                    <span>{rLang.Concessions[lang]}</span>
                  </div>
                  <div className="sum-g">
                    <div></div>
                    <span>{rLang.TWgroup[lang]}</span>
                  </div>
                </div>
                {DOMPurify && (<div className="tool-info-desc" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolIOPDesc[lang],{ALLOWED_TAGS: ['br']})}}/>)}
                <button onClick={()=>{
                  setStart({setApp,tool:'oilpalm',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-concession-pulpwood" style={toolScreen.tool !== 'concession-pulpwood' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                <div id="gl-tc-5" className="tool-info-title"></div>
                <div id="sum-ITP" className="sum-d">
                  <div className="sum-t">
                    <div></div>
                    <span>{rLang.Concessions[lang]}</span>
                  </div>
                  <div className="sum-g">
                    <div></div>
                    <span>{rLang.TWgroup[lang]}</span>
                  </div>
                </div>
                <div id="gl-tc-6" className="tool-info-desc"></div>
                <button onClick={()=>{
                  setStart({setApp,tool:'pulpwood',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-concession-logging" style={toolScreen.tool !== 'concession-logging' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                <div id="gl-tc-7" className="tool-info-title"></div>
                <div id="sum-log" className="sum-d">
                  <div className="sum-t">
                    <div></div>
                    <span>{rLang.Concessions[lang]}</span>
                  </div>
                  <div className="sum-g">
                    <div></div>
                    <span>{rLang.TWgroup[lang]}</span>
                  </div>
                </div>
                <div id="gl-tc-8" className="tool-info-desc"></div>
                <button onClick={()=>{
                  setStart({setApp,tool:'logging',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-concession-mining" style={toolScreen.tool !== 'concession-mining' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                <div id="gl-tc-9" className="tool-info-title">
                </div>
                <div id="sum-mining" className="sum-d">
                  <div className="sum-t">
                    <div></div>
                    <span>{rLang.Concessions[lang]}</span>
                  </div>
                  <div className="sum-g">
                    <div></div>
                    <span>{rLang.TWgroup[lang]}</span>
                  </div>
                </div>
                <div id="gl-tc-10" className="tool-info-desc"></div>
                <button onClick={()=>{
                  setStart({setApp,tool:'mining',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const css = {
  show: {
    opacity:1,
    zIndex:1,
    height:'auto',
    overflow:'auto'
  },
  hide: {
    opacity:0,
    zIndex:-1,
    height:0,
    overflow:'hidden'
  }
}

export default TSconcessions
