const inits = {
  activeTab: 'map',
  defaultTab: 'glad',
}

const TabReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_TAB':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_DEFAULT_TAB':
      return s.activeTab === 'map' ? { ...s,
        activeTab: s.defaultTab
      } : s

    default:
      return s;
  }
};
export default TabReducer
