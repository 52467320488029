import React, {useEffect} from "react"
import {useSelector,useDispatch} from 'react-redux'
import {useImmer} from 'use-immer'

import ZoomedLayer from './ZoomedLayer'
import WMTSLayer from './WMTSLayer'
import WMSLayer from './WMSLayer'
import EsriTileLayer from './EsriTileLayer'
import GladLayer from './GladLayer'
import TsLayer from './TsLayer'
import S73Layer from './S73Layer'
import RiskLayer from './RiskLayer'

const Layers = () => {

  const {layers,WMTSCapabilities} = useSelector(s=>s.layers)
  const {showLayers,linkLayers} = useSelector(s=>s.aLayers)
  const {map} = useSelector(s=>s.map)
  const {gladTabData,tsTabData,_73TabData,riskTabData} = useSelector(s=>s.dat)
  const {activeTab} = useSelector(s=>s.tab)
  const dispatch = useDispatch()
  const [tLayers,setTlayers] = useImmer({l:showLayers})

  useEffect(()=>{
    if(linkLayers.length){
      setTlayers(d=>{d.l = linkLayers})
      dispatch({type:'_A_LAYERS',payload:['linkLayers',[]]})
    }else{
      if(showLayers.length){
        setTlayers(d=>{d.l = showLayers})
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[showLayers,linkLayers])

  return (
    <>
    {
      map ? (<ZoomedLayer/>) : null
    }
    {

      tLayers.l.map((d,i)=>{
        const ln = d.id || d
        const zI = d.i || !1
        if(ln === 'SearchedLayer' || ln === 'ClickedLayer')return null
        if(layers[ln].layerType === 'wmts'){
          return <WMTSLayer map={map} key={layers[ln].id} WMTSCapabilities={WMTSCapabilities} layer={layers[ln]} zIndex={zI || i}/>
        }
        if(layers[ln].layerType === 'TileWMS'){
          return <WMSLayer map={map} key={layers[ln].id} layer={layers[ln]} zIndex={zI || i}/>
        }
        if(layers[ln].layerType === 'EsriTile'){
          return <EsriTileLayer map={map} key={layers[ln].id} layer={layers[ln]} zIndex={zI || i}/>
        }
        return null
      })
    }
    {
      (activeTab === 'glad' && (gladTabData.group || gladTabData.single)) && (<GladLayer/>)
    }
    {
      (activeTab === 'deforestation' && (_73TabData.group || _73TabData.single)) && (<S73Layer/>)
    }
    {
      (activeTab === 'risk' && (riskTabData.group || riskTabData.single)) && (<RiskLayer/>)
    }
    {
      (activeTab === 'timelapse' && (tsTabData.group || tsTabData.single)) && (<TsLayer/>)
    }
    </>
  )
}
export default Layers;
