import React,{useEffect} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import {useImmer} from 'use-immer'
import ReactSlider from 'react-slider'
import $ from 'jquery'

import TypeAheadComponent from '../../TypeAheadComponent'
import SearchedLayer from '../../layers/SearchedLayer'

import {getAdvancedSearchData} from '../../../actions/getAdvancedSearchData'
import {_getFieldName,_reloadCustomSelect,_checkCountry,_isABoundary,_hasGroup} from '../../../helpers'
import config from '../../../config'

import imgRipple from '../../../images/ripple.gif'
import imgFilter from '../../../images/filter.png'

function getAsearch(d,setApp,apiRPC,apiP8080,activeTool,layers,map){
  setApp(getAdvancedSearchData(d,activeTool,layers,map))
}

const membershipTool = [
  'oilpalm','mill'
]

const AdvancedSearch = () => {

  const setApp = useDispatch()
  const {activeTool,toolData} = useSelector(s=>s.tool)
  const {searchData,aSearchForm,aSearchLoader} = useSelector(s=>s.search)
  const s = useSelector(s=>s.aSearch)
  const {map} = useSelector(s=>s.map)
  const {rLang,lang} = useSelector(s=>s.lang)
  const {aSearch,cIDs} = useSelector(s=>s.app)
  const {layers} = useSelector(s=>s.layers)
  const {apiRPC,apiP8080} = config
  const [state,setState] = useImmer({
    nameList: [],
    groupList:[],
    countries:[],
    provinces:[],
    districts:[],
    types:[]
  })

  useEffect(()=>{

    if(typeof document !== undefined){
      if(!$.SumoSelect){
        $.SumoSelect = require('sumoselect')
      }

      if(toolData.gcList.length){

        const n = _isABoundary(activeTool) ? activeTool : 'name'
        let d = toolData.nameList,nH = [],
        i=d.length - 1;
        while (i>=0) {
      		var nL = d[i][n];
          if(nL.trim() !== '' && nL !== null && nH.indexOf(nL) === -1){
            nH = [...nH,nL]
          }
          i--;
        }

        if(nH.length){
          setState(dr=>{dr.nameList = nH})
        }

        const col = _getFieldName(activeTool)
        d = toolData.gcList
        let gc = []
        i=d.length - 1;
        while (i>=0) {
      		var g = d[i][col];
          if(g.trim() !== '' && g !== null && gc.indexOf(g) === -1){
            gc = [...gc,g]
          }
          i--;
        }

        var aT = activeTool,
        t = (lang === 0 ? 'All ' : 'Semua ') +aT.replace(/_/g,' ')+'s';
        t = aT === 'country' ? (lang === 0 ? 'All ' : 'Semua ')+'countries' : t;
        t = aT === 'luzN' || aT === 'luzF' || aT === 'luzP' || aT === 'luzPB' ? (lang === 0 ? 'All ' : 'Semua ')+'classes' : t;

        if(aT === 'mill'){
          gc.unshift('Loreal');
        }
        gc.sort();
        if(t){
          gc.unshift(t);
        }

        aT = 0;t = 0;

        if(gc.length){
          setState(dr=>{dr.groupList = gc})
          setTimeout(()=>{
            $('#as-input-groupcom').val(s.groupcom).SumoSelect({search: true, searchText: 'Search here...',nativeOnDevice: []});
            _reloadCustomSelect(setApp);
            $('#as-input-groupcom').css('display','').next().css('display','')
          },20)
        }

        if(activeTool === 'protected_area'){
          d = toolData.types
          let tH = []
          i=d.length - 1;
          while (i>=0) {
            if(d[i].type.trim() !== '' && d[i].type !== null && tH.indexOf(d[i].type) === -1){
              tH = [...tH,d[i].type]
            }
            i--;
          }
          if(tH.length){
            setState(dr=>{dr.types = tH})
            setTimeout(()=>{
              $('#as-input-types').val(s.types).SumoSelect({search: true, searchText: 'Search here...',nativeOnDevice: []});
              $('#as-input-types').css('display','').next().css('display','')
            },10)
          }
        }

        d = toolData.countries
        let c = [],cH=''
        i=d.length - 1;
        while (i>=0) {
          if(c.indexOf(d[i].country) < 0 && d[i].country.trim() !== ''){
            c = [...c,d[i].country];
            cH = [...cH,d[i].country]
          }
          i--;
        }
        if(cH.length){
          setState(dr=>{dr.countries = cH})
          setTimeout(()=>{
            $('#as-input-countries').val(s.countries).SumoSelect({search: true, searchText: 'Search here...',nativeOnDevice: []});
            _checkCountry(setApp);_reloadCustomSelect(setApp);
            $('#as-input-countries').css('display','').next().css('display','')
          },10)
        }

        d = toolData.provinces
        let p = [],pH = []
        i=d.length - 1;
        while (i>=0) {
          if(p.indexOf(d[i].province) < 0 && d[i].province.trim() !== ''){
            p = [...p,d[i].province]
            pH = [...pH,{c:d[i].country.replace(/\s+/g, '-').toLowerCase(),p:d[i].province}]
          }
          i--;
        }

        if(pH.length){
          setState(dr=>{dr.provinces = pH})
          setTimeout(()=>{
            $('#as-input-provinces').val(s.provinces).SumoSelect({search: true, searchText: 'Search here...',nativeOnDevice: []});
            _checkCountry(setApp);_reloadCustomSelect(setApp);
            $('#as-input-provinces').css('display','').next().css('display','')
          },10)
        }

        d = toolData.districts
        let ds = [], dsH=[]
        i=d.length - 1;
        while (i>=0) {
          if(ds.indexOf(d[i].district) < 0 && d[i].district.trim() !== ''){
            ds = [...ds,d[i].district];
            dsH = [...dsH,{c:d[i].country.replace(/\s+/g, '-').toLowerCase(),p:d[i].province.replace(/\s+/g, '-').toLowerCase(),d:d[i].district}]
          }
          i--;
        }

        if(dsH.length){
          setState(dr=>{dr.districts = dsH})
          setTimeout(()=>{
            $('#as-input-districts').val(s.districts).SumoSelect({search: true, searchText: 'Search here...',nativeOnDevice: []});
            _checkCountry(setApp);_reloadCustomSelect(setApp);
            $('#as-input-districts').css('display','').next().css('display','')
          },10)

        }

      }

    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[activeTool])

  return !state.groupList.length || !aSearchForm ? null : (
    <>
      <table style={{display:aSearchForm ? '' : 'none'}} className="table table-advanced-search">
        <tbody>
          <tr className="hide-when-result">
            <th id="as-th-group">{
              _hasGroup(activeTool) ? 'Company group' :
              ( activeTool.indexOf('luz') !== -1 ? 'Area designation' :
                ( activeTool === 'road' ? 'Road' : activeTool.replace(/_/g,' '))
              )
            }</th>
            <td>

                {
                  state.groupList.length ?
                  (
                    <select style={{display:'none'}} defaultValue={['*']} id="as-input-groupcom" multiple={true} className="as-input" placeholder="All groups">
                    {
                      state.groupList.map((d)=>{
                        return (
                          <option key={d} value={d.indexOf('All ') > -1 || d.indexOf('Semua') > -1 ? '*' : d}>{d}</option>
                        )
                      })
                    }
                    </select>
                  )  : null
                }

            </td>
          </tr>
          {
            _hasGroup(activeTool) ? (
              <tr id="as-tr-name">
                <th id="as-th-name">Concession name</th>
                <td>
                  <TypeAheadComponent
                    data={state.nameList}
                    placeholder={`Enter ${activeTool} name`}
                    id="as-input-concession"
                    className="as-input"
                    minChar={2}
                    value={s.name ? s.name[0] : ''}
                    callback={(e)=>{
                      setApp({type:'_ADVANCED_SEARCH',payload:['name',[e]]})
                    }}
                  />
                </td>
              </tr>
            ) : null
          }
          {
            activeTool === 'protected_area' ? (
              <tr id="as-tr-type">
                <th>Type</th>
                <td>
                  <select style={{display:'none'}} defaultValue={['*']} id="as-input-types" multiple={true} className="as-input" placeholder="All types">
                    <option value="*">All types</option>
                    {
                      state.types.length ? state.types.map((d)=>{
                        return (
                          <option key={d} value={d.indexOf('All ') > -1 || d.indexOf('Semua') > -1 ? '*' : d}>{d}</option>
                        )
                      }) : null
                    }
                  </select>
                </td>
              </tr>
            ) : null
          }

          <tr className="hide-when-result as-tr-boundary">
            <th>{rLang.Country[lang]}</th>
            <td>
              <select style={{display:'none'}} defaultValue={['*']} id="as-input-countries" className="as-input" multiple={true}>
                <option value="*">All countries</option>
                {
                  state.countries.length ? state.countries.map((d)=>{
                    return (
                      <option key={d} value={d}>{d}</option>
                    )
                  }) : null
                }
              </select>
            </td>
          </tr>

          <tr className="as-tr-boundary">
            <th>{rLang.Province[lang]}</th>
            <td>
              <select style={{display:'none'}} defaultValue={['*']} id="as-input-provinces" className="as-input as-input-provinces " multiple={true}>
                <option value="*">All provinces</option>
                {
                  state.provinces.length ? state.provinces.map((d)=>{
                    return (
                      <option key={d.p} value={d.p} className={`province-for province-for-${d.c}`}>{d.p}</option>
                    )
                  }) : null
                }
              </select>
              <input type="hidden" id="choosen-as-input-provinces" value="*"/>
            </td>
          </tr>
          <tr className="as-tr-boundary as-tr-boundary-d">
            <th>{rLang.District[lang]}</th>
            <td>
              <select style={{display:'none'}} defaultValue={['*']} id="as-input-districts" className="as-input as-input-districts " multiple={true}>
                <option value="*">All district</option>
                {
                  state.districts.length ? state.districts.map((d)=>{
                    return (
                      <option key={d.d} value={d.d} className={`district-for district-for-${d.c} district-for district-for-${d.p}`}>{d.d}</option>
                    )
                  }) : null
                }
              </select>
            </td>
          </tr>
          {/*
            activeTool.indexOf('luztest') > -1 ? (
              <tr className="hide-when-result as-tr-luz">
                <th>District</th>
                <td>
                  <select id="as-input-districts-luz" className="as-input as-input-districts-luz" multiple={true}></select>
                </td>
              </tr>
            ) : null
            */
          }

          {
            membershipTool.indexOf(activeTool) === -1 ? null : (
              <tr id="as-input-membership" className="hide-when-result">
                <th>Certified concessions:</th>
                <td>
                  <div className="display-table width-100">
                    <div className="inline-block vertical-top">
                      <div className="checkbox checkbox-search checkbox-inactive">
                        <input type="checkbox" id="as-input-rspo" value={s.rspo} onChange={()=>{}}/>
                        <label htmlFor="as-input-rspo">RSPO<br/><i className="fa fa-info-circle"></i> Under<br/> development&nbsp;&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="inline-block vertical-top">
                      <div className="checkbox checkbox-search checkbox-inactive">
                        <input type="checkbox" id="as-input-ispo" value={s.ispo} onChange={()=>{}}/>
                        <label htmlFor="as-input-ispo">ISPO<br/><i className="fa fa-info-circle"></i> Under<br/> development&nbsp;&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="inline-block vertical-top">
                      <div className="checkbox checkbox-search checkbox-inactive">
                        <input type="checkbox" id="as-input-mspo" value={s.mspo} onChange={()=>{}}/>
                        <label htmlFor="as-input-mspo">MSPO<br/><i className="fa fa-info-circle"></i> Under<br/> development&nbsp;&nbsp;&nbsp;</label>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )
          }

          <tr className="hide-when-result">
            <th>Proportion of peatland area</th>
            <td>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="s-thumb"
                trackClassName="s-track"
                min={0}
                max={100}
                value={s.peatProp}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={t => `Thumb value ${t.valueNow}`}
                renderThumb={(props, t) => <div {...props}></div>}
                pearling
                minDistance={10}
                onChange={(e)=>{
                  setApp({type:'_ADVANCED_SEARCH',payload:['peatProp',e]})
                }}
              />
              <div className="arange-slider">
                <div id="as-input-peat-proportion-bottom">{s.peatProp[0]} %</div>
                <div id="as-input-peat-proportion-top" className="text-right">{s.peatProp[1]} %</div>
              </div>
            </td>
          </tr>
          <tr className="hide-when-result">
            <th>Proportion of remaining forest area</th>
            <td>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="s-thumb"
                trackClassName="s-track"
                min={0}
                max={100}
                value={s.forProp}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={t => `Thumb value ${t.valueNow}`}
                renderThumb={(props, t) => <div {...props}></div>}
                pearling
                minDistance={10}
                onChange={(e)=>{
                  setApp({type:'_ADVANCED_SEARCH',payload:['forProp',e]})
                }}
              />
              <div className="arange-slider">
                <div id="as-input-forest-bottom">{s.forProp[0]} %</div>
                <div id="as-input-forest-top" className="text-right">{s.forProp[1]} %</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div id="as-loader" className={aSearchLoader ? '' : "hide"}>
        <img src={imgRipple} alt="" style={{width: "32px"}}/> Searching...
      </div>
      {
        aSearchForm && !aSearchLoader ? (<>
          <button id="as-filter" onClick={()=>{
            batch(()=>{
              setApp({type:'OPEN_ADVANCED_SEARCH'})
              setApp({type:'_LOADER',payload:['appLoader',true]})
              setApp({type:'_SEARCH',payload:['aSearchLoader',true]})
            })
            getAsearch(s,setApp,apiRPC,apiP8080,activeTool,layers,map)
          }} className="as-btn btn btn-primary"><i className="fa fa-search"></i> SEARCH</button>
          <button id="as-cancel" onClick={()=>{
            batch(()=>{
              setApp({type:'INIT_AVS'})
              setApp({type:'INIT_DATA'})
              setApp({type:'INIT'})
              setApp({type:'SET_START',payload: activeTool})
              setApp({type:"RESET_ADVANCED_SEARCH_FORM"})
            })
          }} className="as-btn btn">CANCEL</button>
        </>) : !aSearchLoader ? (<button onClick={()=>setApp({type:'_SEARCH',payload: ['aSearchForm',1]})} id="as-filter-switch" className="as-btn btn"><img alt="" src={imgFilter}/> OPTIONS</button>) : null
      }
      <br/><br/>

      {
        !searchData.length || !aSearch || !cIDs.length ? null :
        (<SearchedLayer map={map} name={'SearchedLayer'} layer={layers[activeTool]} lstyle={'SelectCyan'} advanced={s} activeTool={activeTool}/>)
      }
    </>
  )
}
export default AdvancedSearch
