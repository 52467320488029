import React,{useEffect} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import $ from 'jquery';
import {Info,Download,Times} from '@styled-icons/fa-solid'

import {_getLayer} from '../../helpers'

const SortableLayer = (props) => {
  const { layers } = useSelector(s=>s.layers)
  const { activeLayers } = useSelector(s=>s.aLayers)
  const { map } = useSelector(s=>s.map)
  const { rLang,lang } = useSelector(s=>s.lang)
  const setApp = useDispatch()

  useEffect(()=>{
    if(map){
      const l = _getLayer(map,props.id);
      if(l){
        l.setZIndex(props.idx)
      }
      if(typeof navigator !== undefined){
        require('jquery-ui/ui/widgets/slider');
        $("#theLayerSlider"+(layers[props.id] ? layers[props.id].id : props.id)).slider({
          min: 0,
          max: 100,
          value: layers[props.id] ? layers[props.id].opacity*100 : 100,
          range: "min",
          slide: function(event, e) {
            _getLayer(map,layers[props.id] ? layers[props.id].id : props.id).setOpacity(e.value / 100);
          }
        });
      }
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[layers,map,props.id,activeLayers])

  if(!map) return null

  if(props.id === 'ClickedLayer'){
    return (
      <div className="layer">
        <div>
          <table>
            <tbody>
              <tr>
                <td>{rLang[props.id][lang]}</td>
                <td className="layer-icons vertical-top text-right">
                  <div className="display-table pull-right">
                    <b role="presentation" className="layer-times" onClick={()=>{
                      // _getLayer(map,props.id).setVisible(!1);
                      batch(()=>{
                        setApp({type:'REMOVE_THIS_LAYER',payload:props.id})
                        setApp({type:'_APP',payload:['single',!1]})
                      })
                    }}><Times size="11"/></b>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div id={`theLayerSlider${props.id}`}/>
          <div id={`legendID-${props.id}`}>
            <div/>
          </div>
        </div>
      </div>
    )
  }
  return props.id === 'SearchedLayer' ? (
    <div className="layer">
      <div>
        <table>
          <tbody>
            <tr>
              <td>{rLang[props.id][lang]}</td>
              <td className="layer-icons vertical-top text-right">
                <div className="display-table pull-right">
                  <b role="presentation" className="layer-times" onClick={()=>{
                    // _getLayer(map,props.id).setVisible(!1);
                    batch(()=>{
                      setApp({type:'REMOVE_THIS_LAYER',payload:props.id})
                      setApp({type:'_APP',payload:['goSearch',!1]})
                      setApp({type:'_APP',payload:['aSearch',!1]})
                    })
                  }}><Times size="11"/></b>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div id={`theLayerSlider${props.id}`}/>
        <div id={`legendID-${props.id}`}>
          <div/>
        </div>
      </div>
    </div>
  )
  : (
    <div className="layer" data-id={layers[props.id].id}>
      <div>
        <table>
          <tbody>
            <tr>
              <td>{layers[props.id].title[lang]}</td>
              <td className="layer-icons vertical-top text-right">
                <div className="display-table pull-right">
                  {
                    layers[props.id].dl ? (
                      <a // eslint-disable-line jsx-a11y/control-has-associated-label
                      href={layers[props.id].dl} target="_blank" rel="noopener noreferrer" className="layer-dl"><Download size="9"/></a>
                    ) : null
                  }
                  <b role="presentation" className="layer-info" onClick={()=>{
                    batch(()=>{
                      setApp({type:'_POPUP_ON_MAP',payload:['title','MAP/LAYER INFORMATION']})
                      setApp({type:'_POPUP_ON_MAP',payload:['body',`<h3 id="layer-info-title">${layers[props.id].title[lang]}</h3>${layers[props.id].info ? layers[props.id].info[lang] : ''}`]})
                    })

                    // setApp({type:'_APP',payload:['toolScreen',{tool:!1,bg:''}]})

                  }}><Info size="9"/></b>
                  <b role="presentation" className="layer-times" onClick={()=>{
                    // _getLayer(map,props.id).setVisible(!1);
                    setApp({type:'REMOVE_THIS_LAYER',payload:props.id})

                  }}><Times size="11"/></b>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div id={`theLayerSlider${layers[props.id].id}`}/>
        <div id={`legendID-${layers[props.id].id}`}>
          {
            layers[props.id].layerType === 'EsriTile' ? null :
            (<img alt="" src={`https://nusantara-atlas.org/geoserver/gwc/service/wms?REQUEST=GetLegendGraphic${layers[props.id].legend ? `&STYLE=${layers[props.id].legend}` : ''}&VERSION=1.0.0&FORMAT=image/png&WIDTH=18&HEIGHT=18&LAYER=${layers[props.id].layer}&LEGEND_OPTIONS=labelMargin:10;fontName:Roboto;fontSize:12;bgColor:0x353941;fontColor:0xDDDDDD;forceTitles:off;fontAntiAliasing:true;`}/>)
          }
        </div>
      </div>
    </div>
  );
}

export default SortableLayer
