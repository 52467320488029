import React from 'react'
import {useSelector} from 'react-redux'

import TSconcessions from './TSconcessions'
import TSMills from './TSMills'
import TSpa from './TSpa'
import TSadmin from './TSadmin'
import TSroad from './TSroad'
import TSluz from './TSluz'
import TSaoi from './TSaoi'

const ToolScreen = () => {

  const {tool,bg} = useSelector(s=>s.toolScreen);
  const {map} = useSelector(s=>s.map);
    return !map ? null : (
      <div id="tool-info" style={{right:0,display: `${tool ? '' : 'none'}`,background: `rgba(0,0,0,0.85)`,backgroundImage:`url(${bg})`,backgroundPosition:'center center',backgroundSize:'cover'}}>
        <div className="display-table width-100 height-100">
          <div className="table-cell vertical-middle">
            <TSconcessions/>
            <TSMills/>
            <TSpa/>
            <TSadmin/>
            <TSroad/>
            <TSluz/>
            <TSaoi/>
          </div>
        </div>
      </div>
    )
}

export default ToolScreen
