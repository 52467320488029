import React,{useEffect,useRef} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import moment from 'moment'
import axios from 'axios'

import {ChevronDown, ChevronUp, Reload} from '@styled-icons/ionicons-outline'
import {WindowRestore,WindowMinimize,Times} from '@styled-icons/fa-solid'

import AdvancedSearch from './AdvancedSearch'

import config from '../../../config'
import {_fNDec,_isABoundary,_hasGroup} from '../../../helpers'

function closeThis(setApp,ref,activeTool){
  batch(()=>{
    setApp({type:'INIT_AVS'})
    setApp({type:'INIT_DATA'})
    setApp({type:'INIT'})
    setApp({type:'SET_START',payload: activeTool})
    setApp({type:'_TAB',payload:['activeTab','map']})
    // setApp({type:'REMOVE_SEARCHED_LAYER',payload:activeTool})
  })
}

const showPerPage = 100

const getSort = (d,url,d2,setApp,id) => {
  axios.post(`${config.apiRPC}${url}`,d).then(function(r){

    batch(()=>{
      setApp({type:'_SEARCH',payload:['percent',d.percent]})
      setApp({type:'_SEARCH',payload:['sortData',r.data]})
      setApp({type:'_SEARCH',payload:['hasData',1]})
      setApp({type:'_SEARCH',payload:['sort',d.sort || d2.sort]})
      setApp({type:'_SEARCH',payload:['sortcolumn',d.sortcolumn || d2.sortcolumn]})
      setApp({type:'_SEARCH',payload:['sortby',d.class || d2.sortby]})
      setApp({type:'_SEARCH',payload:['yr',d.yr || d2.yr]})
      setApp({type:'_SEARCH',payload:['alert',d.alert !== false ? d.alert : (d.alert === false ? false : d2.alert)]})
      setApp({type:'_SEARCH',payload:['astatus',d.astatus || d2.astatus]})
      setApp({type:'_SEARCH',payload:['daterange',d.daterange || d2.daterange]})
      setApp({type:'_SEARCH',payload:['filterid',id]})
      if(d.alert){
        setApp({type:'_SEARCH',payload:['filterText',d.class === 'all' ? `(Total)` : (d.class === 'forest' ? '(Forest)' : '(Non-forest)')]})
      }else{
        setApp({type:'_SEARCH',payload:['filterText',d.yr ? (d.yr === '-1' ? '(Total)' : `(${d.yr})`) : (d2.yr === '-1' ? '(Total)' : `(${d2.yr})`)]})
      }
    })
  });
}

const SearchResult = () => {

  const search = useSelector(d=>d.search)
  const layers = useSelector(d=>d.layers.layers)
  const {activeTool} = useSelector(d=>d.tool)
  const {rLang,lang} = useSelector(s=>s.lang)
  const {goSearch,aSearch,cIDs} = useSelector(s=>s.app)
  const {featData} = useSelector(s=>s.dat)
  const {searchData} = search
  const {gladStartDate,gladEndDate,gladUnconfirm} = useSelector(s=>s.glad)
  const cCats = useSelector(s=>s.ts.cCats)

  const setApp = useDispatch()

  const srRef = useRef()
  const srDRef = useRef()

  const {open,page,showBg,min,sortData,filterid,filterText} = search
  const unit = layers ? layers[activeTool]?.unit : !1
  const total = cIDs ? cIDs.length : 0

  useEffect(()=>{
    if(min){
      setApp({type:'_SEARCH',payload:['showBg',false]})
      setTimeout(()=>{
        if(srRef.current){
          srRef.current.style.width = 'auto'
          srDRef.current.style.display = 'none'
        }
      },260)
    }else{
      if(srRef.current){
        srRef.current.style.width = ''
        srDRef.current.style.display = ''
      }
      setTimeout(()=>{
        setApp({type:'_SEARCH',payload:['showBg',true]})
      },260)
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[min])

  const reSort = (d) => {
    batch(()=>{
      setApp({type:'_SEARCH',payload:['open',0]})
      setApp({type:'_SEARCH',payload:['hasData',0]})
      if(!d.page){
        setApp({type:'_SEARCH',payload:['page',1]})
      }
    })

    if(cIDs && unit) {
      let a = {
        cid:'{'+cIDs.concat()+'}',
        unit,
        class: d.sortby || search.sortby,
        resultno: showPerPage,
        offsetno: d.page ? (d.page - 1) * showPerPage : search.page -1,
        sort: d.sort || search.sort,
        sortcolumn: d.sortcolumn || search.sortcolumn,
        percent: typeof d.percent !== 'undefined' ? d.percent : search.percent
      },url

      if(d.alert !== false && (d.alert || search.alert)){
        a.alert = d.alert || search.alert;
        a.astatus = d.astatus || search.alert ? 'all' : 'confirmed';
        a.daterange = d.daterange || search.daterange;
        url = 'atlas_getsearchresultalertsortingbyid';
      }else{
        a.yr = d.yr;
        url = 'atlas_getsearchresultsortingbyid';
      }

      getSort(a,url,search,setApp,d.id >= 0 ? d.id : search.filterid)
      document.querySelector('#popup-search .popup-inner').scrollTop = 0
    }

  }

  useEffect(()=>{
    if(aSearch){
      setApp({type:'_SEARCH',payload:['min',!1]})
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[aSearch])

  const reset = () => {
    if(searchData && cIDs.length && unit){
      reSort({cIDs,unit,
        sortcolumn: 'areaha',
        sortby: 'polyarea',
        sort: 'desc',
        alert: false,
        yr: '-1',
        percent:false,
        id:0
      })
    }
  }

  useEffect(()=>{
    if(searchData && cIDs.length && unit){
      reset()
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[searchData,unit])

  useEffect(()=>{
    if(searchData && (gladStartDate || gladEndDate || gladUnconfirm)){
      if(cIDs.length && unit && search.alert && !min){
        reSort({cIDs,unit,
          alert:search.alert,astatus: gladUnconfirm,daterange: `{${moment(gladStartDate).format('YYYY-MM-DD')},${moment(gladEndDate).format('YYYY-MM-DD')}}`,id:filterid
        })
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[min])

  const clickTh = (sortcolumn) => {
    const _sort = search.sortcolumn === sortcolumn ? (search.sort === 'asc' ? 'desc' : 'asc') : (sortcolumn === 'areaha' ? 'desc' : 'asc')
    reSort({cIDs,unit,
      sortcolumn,sort:_sort
    })
  }

  const clickFilter = ({sortby,yr,alert,id}) => {
    if(alert){
      reSort({cIDs,unit,
        sortby,yr,sort:'desc',sortcolumn:'areaha',
        alert,astatus: gladUnconfirm,daterange: `{${moment(gladStartDate).format('YYYY-MM-DD')},${moment(gladEndDate).format('YYYY-MM-DD')}}`,id
      })
    }else{
      reSort({cIDs,unit,
        sortby,yr,sort:'desc',sortcolumn:'areaha',alert:false,id
      })
    }

  }

  const clickPercent = (e) => {
    reSort({cIDs,unit,
      percent:e
    })
  }

  const viewMap = (id) => {
    batch(()=>{
      setApp({type:'_SEARCH',payload:['min',true]})
      setApp({type:'_APP',payload:['viewMap',id]})
    })

  }

  return (
    <>
      {
        (goSearch && total) || aSearch ? (
          <div id="popup-search" ref={srRef} className={`popup ${min ? "popup-min" : ''}`} style={{
            zIndex:`${(goSearch && total) || aSearch ? '' : -2}`,
            display:`${(goSearch && total) || aSearch ? '' : 'none'}`,
            opacity:`${(goSearch && total) || aSearch ? 1 : 0}`}}
          >
            <div className="popup-bg" style={{display:`${showBg ? '' : 'none'}`}} ></div>

            <div>
              <div>
                <div className="popup-header">
                  <div role='presentation'onClick={()=>{
                    if(min){
                      batch(()=>{
                        setApp({type:'_SEARCH',payload:['min',!min]})
                        setApp({type:'RESET_SINGLE_DATA'})
                        setApp({type:'_APP',payload:['viewMap',null]})
                        setApp({type:'_APP',payload:['mapClicked',null]})
                        setApp({type:'_APP',payload:['single',null]})
                        setApp({type:'_APP',payload:['sgs',goSearch ? 'group' : 'stat']})
                      })
                    }
                  }}>
                    {
                      aSearch && !min ? rLang.Advancedsearch[lang] :
                      (!aSearch || (total && aSearch) ? `Rank ${total} ${
                        _hasGroup(activeTool) ? (activeTool.indexOf('mill') > -1 ? 'mill': 'concession') :
                        (
                          activeTool.indexOf('luz') > -1 ? 'land use zone' : (
                            activeTool === 'country' ? (total > 1 ? 'countrie' : 'country') : activeTool.replace('_',' ')
                          )
                        )

                      }${total > 1 ? 's' : ''} found` : rLang.Advancedsearch[lang] )

                    }
                  </div>
                  <div>
                    <span role='presentation' onClick={()=>{
                      if(!min){
                        batch(()=>{
                          setApp({type:'_SEARCH',payload:['min',!min]})
                          setApp({type:'RESET_SINGLE_DATA'})
                          setApp({type:'_APP',payload:['viewMap',null]})
                          setApp({type:'_APP',payload:['mapClicked',null]})
                          setApp({type:'_APP',payload:['single',null]})
                          setApp({type:'_APP',payload:['sgs',goSearch ? 'group' : 'stat']})
                        })
                      }else{
                        setApp({type:'_SEARCH',payload:['min',!min]})
                      }
                    }}>{min ? (<WindowRestore size="14"/>) : (<WindowMinimize size="14"/>)}</span>
                    <span role='presentation' onClick={()=>{
                      batch(()=>{
                        setApp({type:'_SEARCH',payload:['min',true]})
                        setApp({type:'_SEARCH',payload:['showBg',!1]})
                      })
                      closeThis(setApp,srRef,activeTool)
                    }}><Times size="14"/></span>
                  </div>
                </div>
                <div ref={srDRef} className="popup-inner">

                  {aSearch && (<AdvancedSearch/>)}
                  {
                    searchData.length ? (<>
                      <div className="table-cell total-search-result" id="total-search-result">{total} result{total>1?'s':''} found</div>
                      <div className="table-cell total-search-result-area" id="total-search-result-area"><span>Total area:</span> <span id="tta">{_fNDec(featData.group?.areaha) || ''}</span> Ha</div>
                    </>) : null
                  }

                  {
                    !searchData.length ? null : (
                      <div id="sort-result">
                        <h4 className="sort-by-label">Rank by:</h4>
                        <ul className="list-inline sort-block">
                          <li>
                            <div id="sort-by-lost" className="sort-select">
                              <h5 role="presentation"
                              onClick={()=>{
                                setApp({type:'_SEARCH',payload:['open',open === 1 ? 0 : 1]})
                              }} id="sort-by-lost-label" className="sort-select-this">Area of forest lost <span>{filterid===1?filterText:''}</span><i className="r-sort-down"></i></h5>
                              <ul className="list-unstyled" style={{display:`${open === 1 ? '' : "none"}`}}>
                                <li role="presentation" onClick={(e)=>{
                                  clickFilter({e,sortby:'forestloss',yr:"-1",id:1})
                                }}><span>TOTAL 2001-2019</span><span><i className="fa fa-circle-o"></i></span></li>
                                {
                                  cCats.map((d)=>{
                                    return (
                                      <li role="presentation" onClick={(e)=>{
                                        clickFilter({e,sortby:'forestloss',yr:d,id:1})
                                      }} key={d}><span>{d}</span><span><i className="fa fa-circle-o"></i></span></li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          </li>
                          <li>
                            <div id="sort-by-cdriven" className="sort-select">
                              <h5 role="presentation" onClick={(e)=>{
                                setApp({type:'_SEARCH',payload:['open',open === 2 ? 0 : 2]})
                              }} id="sort-by-cdriven-label" className="sort-select-this">Company driven deforestation <span>{filterid===2?filterText:''}</span><i className="r-sort-down"></i></h5>
                              <ul className="list-unstyled" style={{display:`${open === 2 ? '' : "none"}`}}>
                                <li role="presentation" onClick={(e)=>{
                                  clickFilter({e,sortby:'compdriven',yr:"-1",id:2})
                                }}><span>TOTAL 2001-2019</span><span><i className="fa fa-circle-o"></i></span></li>
                                {
                                  cCats.map((d)=>{
                                    return (
                                      <li role="presentation" onClick={(e)=>{
                                        clickFilter({e,sortby:'compdriven',yr:d,id:2})
                                      }} key={d}><span>{d}</span><span><i className="fa fa-circle-o"></i></span></li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          </li>
                          <li>
                            <div id="sort-by-planted" className="sort-select">
                              <h5 role="presentation" onClick={()=>{
                                setApp({type:'_SEARCH',payload:['open',open === 3 ? 0 : 3]})
                              }} id="sort-by-planted-label" className="sort-select-this">Planted area added <span>{filterid===3?filterText:''}</span><i className="r-sort-down"></i></h5>
                              <ul className="list-unstyled" style={{display:`${open === 3 ? '' : "none"}`}}>
                                <li role="presentation" onClick={(e)=>{
                                  clickFilter({e,sortby:'plantedarea',yr:"-1",id:3})
                                }}><span>TOTAL 2001-2019</span><span><i className="fa fa-circle-o"></i></span></li>
                                {
                                  cCats.map((d)=>{
                                    return (
                                      <li role="presentation" onClick={(e)=>{
                                        clickFilter({e,sortby:'plantedarea',yr:d,id:3})
                                      }} key={d}><span>{d}</span><span><i className="fa fa-circle-o"></i></span></li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          </li>
                          <li>
                            <div id="sort-by-remaining" className="sort-select">
                              <h5 role="presentation" onClick={()=>{
                                setApp({type:'_SEARCH',payload:['open',open === 4 ? 0 : 4]})
                              }} id="sort-by-remaining-label" className="sort-select-this">Remaining forest <span>{filterid===4?filterText:''}</span><i className="r-sort-down"></i></h5>
                              <ul className="list-unstyled" style={{display:`${open === 4 ? '' : "none"}`}}>
                                {
                                  cCats.map((d)=>{
                                    return (
                                      <li role="presentation" onClick={(e)=>{
                                        clickFilter({e,sortby:'remainingforest',yr:d,id:4})
                                      }} key={d}><span>{d}</span><span><i className="fa fa-circle-o"></i></span></li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          </li>
                          <li>
                            <div id="sort-by-bs" className="sort-select">
                              <h5 role="presentation" onClick={()=>{
                                setApp({type:'_SEARCH',payload:['open',open === 5 ? 0 : 5]})
                              }} id="sort-by-bs-label" className="sort-select-this">Burned area <span>{filterid===5?filterText:''}</span><i className="r-sort-down"></i></h5>
                              <ul className="list-unstyled" style={{display:`${open === 5 ? '' : "none"}`}}>
                                <li role="presentation" onClick={(e)=>{
                                  clickFilter({e,sortby:'burnarea',yr:"2019",id:5})
                                }}><span>2019</span><span><i className="fa fa-circle-o"></i></span></li>
                              </ul>
                            </div>
                          </li>
                          <li>
                            <div id="sort-by-glad" className="sort-select">
                              <h5 role="presentation" onClick={()=>{
                                setApp({type:'_SEARCH',payload:['open',open === 6 ? 0 : 6]})
                              }} id="sort-by-glad-label" className="sort-select-this">Tree loss between <b>{moment(gladStartDate).format('YYYY-MM-DD')} - {moment(gladEndDate).format('YYYY-MM-DD')}</b> <span>{filterid===6?filterText:''}</span><i className="r-sort-down"></i></h5>
                              <ul className="list-unstyled" style={{display:`${open === 6 ? '' : "none"}`}}>
                                <li role="presentation" onClick={()=>{
                                  clickFilter({alert:'glad',sortby:'all',id:6})
                                }}><span>Total</span><span><i className="fa fa-circle-o"></i></span></li>
                                <li role="presentation" onClick={()=>{
                                  clickFilter({alert:'glad',sortby:'forest',id:6})
                                }}><span>Forest loss</span><span><i className="fa fa-circle-o"></i></span></li>
                                <li role="presentation" onClick={()=>{
                                  clickFilter({alert:'glad',sortby:'nonforest',id:6})
                                }}><span>Tree loss in non-forest</span><span><i className="fa fa-circle-o"></i></span></li>
                              </ul>
                            </div>
                          </li>
                          <li>
                            <h4 role="presentation" onClick={()=>{reset()}} id="refilter"><Reload size="12"/> Reset filter</h4>
                          </li>
                        </ul>
                    </div>
                    )
                  }
                  {
                    sortData && (
                      <>
                        <table className="table" id="table-basic-result">
                          <thead>
                            <tr>
                              <th onClick={()=>{clickTh('name')}}>
                                <div className="display-table width-100">
                                  <div className="table-cell vertical-middle">
                                  {
                                    _hasGroup(activeTool) ? 'CONCESSION/SUBSIDIARY' : (_isABoundary(activeTool) ? activeTool : (activeTool.indexOf('luz') > -1 ? 'LAND USE ZONE' : activeTool.replace('_',' ')))}
                                  </div>
                                  <div className="table-cell vertical-middle width-15px">
                                    <ChevronUp size="10"/><ChevronDown size="10"/>
                                  </div>
                                </div>
                              </th>
                              {
                                /* eslint-disable-line jsx-a11y/control-has-associated-label*/ _hasGroup(activeTool) ? (<th onClick={()=>{clickTh('groupcom')}}><div className="display-table width-100"><div className="table-cell vertical-middle">{activeTool.indexOf('mill') > -1 ? 'PARENT COMPANY' : 'COMPANY'}</div><div className="table-cell vertical-middle width-15px"><ChevronUp size="10"/><ChevronDown size="10"/></div></div></th>) : null
                              }
                              {
                                /* eslint-disable-line jsx-a11y/control-has-associated-label*/ activeTool === 'road' || activeTool.indexOf('luz') > -1 ? (<th onClick={()=>{clickTh('district')}}><div className="display-table width-100"><div className="table-cell vertical-middle">{rLang.District[lang]}</div><div className="table-cell vertical-middle width-15px"><ChevronUp size="10"/><ChevronDown size="10"/></div></div></th>) : null
                              }
                              {
                                _hasGroup(activeTool) || activeTool === 'protected_area' ? (<th onClick={()=>{clickTh('type')}} style={{width:_hasGroup(activeTool) ? 71 : ''}}>
                                  <div className="display-table width-100"><div className="table-cell vertical-middle">{lang ? 'TIPE' : 'TYPE'}</div>
                                  <div className="table-cell vertical-middle width-15px"><ChevronUp size="10"/><ChevronDown size="10"/></div></div></th>) : null
                              }
                              <th className="text-center" style={{width:90}}>
                                <ul id="area-percent" className="list-inline" style={{display:`${search.sortby === 'polyarea' ? 'none' : ''}`}}>
                                  <li>Ha&nbsp;</li>
                                  <li role="presentation" style={{marginRight: -9,marginLeft: -9}}> <label className="ap-switch"> <input onChange={(e)=>{
                                    clickPercent(e.target.checked)
                                  }} checked={search.percent} id="ap-val" type="checkbox"/> <span className="ap-slider"></span> </label> </li> <li>%</li>
                                </ul>
                                <div role="presentation" onClick={(e)=>{clickTh('areaha')}} className="display-table width-100">
                                  <div className="table-cell vertical-middle">
                                    {search.percent ? (lang ? 'PROPORSI' : 'PROPORTION') : 'AREA'}
                                  </div>
                                  <div className="table-cell vertical-middle width-15px">
                                    <ChevronUp size="10"/><ChevronDown size="10"/>
                                  </div>
                                </div>
                              </th>
                              <th className="nomobile" style={{width:60,opacity:0}}>..</th>
                            </tr>
                          </thead>
                          {
                            search.hasData ? null : (
                              <tbody><tr><td>Loading...</td></tr></tbody>
                            )
                          }
                          <tbody style={{opacity:search.hasData ? 1 : 0}}>
                            {
                              sortData ? sortData.map((p)=>{
                                return (
                                  <tr data-cid={p.resultid} key={p.resultid}>
                                    <td className="name">
                                      {p.column1}
                                      <div className="m-only">
                                        <span data-id={p.resultid}><i className="fa fa-map-marker"></i> {lang ? 'PETA' : 'MAP'}</span>
                                        <span data-id={p.resultid}><i className="fa fa-ellipsis-v"></i> STAT</span>
                                      </div>
                                    </td>
                                    {activeTool === 'protected_area' ? (<td className="type">{p.column2}</td>) : (!_isABoundary(activeTool) ? (<td className="group">{p.column2}</td>) : null)}
                                    {_hasGroup(activeTool) ? (<td className="type">{p.column3}</td>) : null}
                                    <td className="text-right switch-data">{_fNDec(p.areaha)} {search.percent ? '%' : 'Ha' }</td>
                                    <td className="text-right nomobile"><button onClick={() => {
                                      viewMap(p.resultid)
                                    }} className="search-viewmap">{lang ? 'LIHAT' : 'VIEW'}</button></td>
                                  </tr>
                                )
                              }) : (<tr><td>Loading</td></tr>)
                            }
                          </tbody>
                        </table>
                        {
                          total > sortData.length ? (
                            <div className="pagination">
                              {page > 1 ? (<><span role="presentation" onClick={()=>{
                                setApp({type:'_SEARCH',payload:['page',page-1]})
                                reSort({...search,cIDs,setApp,page:page-1})
                              }} href="#">« Previous</span>&nbsp;&nbsp;</>) : null}
                              {page > 1 && (page * showPerPage) < total ? (<>|&nbsp;&nbsp;</>) : ''}
                              {(page * showPerPage) < total ? (<span role="presentation" href="#" onClick={()=>{
                                setApp({type:'_SEARCH',payload:['page',page+1]})
                                reSort({...search,cIDs,setApp,page:page+1})
                              }} id="np">Next page »</span>) : null}
                            </div>
                          ) : null
                        }
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        ) : null
      }
    </>
  )

}
export default SearchResult
