import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import axios from 'axios'
import {Download} from '@styled-icons/fa-solid'

import config from '../../config'

const downloadBar = ({dispatch,chartID,barColor,lang}) => {
  dispatch({type:'_POPUP',payload:['popup','downloadexcel']})
  document.getElementById(chartID)
  let data = JSON.parse(decodeURIComponent(document.getElementById(chartID).getAttribute('download_data')))
  if(barColor){
    data.barColor = barColor
  }
  data.lang = lang
  axios({
    method: 'post',
    url: `${config.apiLink}excel/${barColor ? 'bar' : 'bar2'}/`,
    data: data,
    withCredentials: true,
    credentials: 'include',
    crossdomain: true,
    headers: {
      'content-type': 'application/json;charset=utf-8',
      "Cache-Control": "no-cache",
    }
  })
  .then(function(r){
    document.getElementById('chart-download-alert').innerHTML = r.data.html;
    document.getElementById('dl-chart').click();
  }).catch((e)=>{
    dispatch({type:'_POPUP',payload:['popup',!1]})
    console.log(e.response);
  });

}

const DownloadBar = ({chartID,barColor}) => {

  const dispatch = useDispatch()
  const {lang} = useSelector(s=>s.lang)

  return (
    <b role="presentation" onClick={()=>{
      downloadBar({dispatch,chartID,barColor,lang});
    }}>{/* eslint-disable-line jsx-a11y/control-has-associated-label */}<Download size="14"/></b>
  )
}
export default DownloadBar
