import React from 'react'
const RiskIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 15">
      <defs>
      </defs>
      <text id="risk-icon" transform="translate(12 14)" style={{fontFamily:'Roboto Condensed',fontSize:14,marginTop:20}}><tspan fill={color} stroke={color} strokeWidth="0.5" x="-10" y="0">RISK</tspan></text>
    </svg>
  )
}
export default RiskIcon
