import React, {useEffect,useState} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import Collapse from "@kunukn/react-collapse";
import DatePicker from "react-datepicker";
import parseISO from 'date-fns/parseISO'
import moment from 'moment'
import $ from 'jquery'

import AvsLayer1 from '../layers/AvsLayer1'
import AvsLayer2 from '../layers/AvsLayer2'

import {Satellite,InfoCircle} from '@styled-icons/fa-solid'
import {ChevronDown,ChevronUp} from '@styled-icons/boxicons-regular'


const AlertVerificationSystem = () => {

  const {activeTab} = useSelector(s=>s.tab)
  const {
    open1,hide1,date1,type1,
    open2,hide2,date2,type2
  } = useSelector(s=>s.avs)
  const setApp = useDispatch()
  const [bandInfo,setBandInfo] = useState(false)

  useEffect(()=>{
    require('jquery-ui/ui/widgets/draggable');

    $( "#swipeAvs" ).draggable({
      axis: "x",
      containment: $('#ol-map'),
      drag: function( e, ui ) {
        // __s.swipe = ui.position.left;
      },
    });
  },[])

  useEffect(()=>{
    if((activeTab === 'glad' && open1) || (activeTab === 'glad' && open2)){
      // setApp({type:'_A_LAYERS_GLAD',payload:activeTool})
    }
  },/* eslint-disable-line react-hooks/exhaustive-deps */[open1,open2])

  return (
    <>
      <div id="swipeAvs" className={activeTab === 'glad' && open1 ? '' : 'hide'}><div className="handle"/></div>

      <div role="presentation" onClick={()=>{
        setApp({type:'_AVS',payload:['open2',!open2]})
      }} id="aversat2" style={{display:activeTab === 'glad' && open1 ? '' : "none"}} className={open2 ? `a` : ``}><Satellite size="20"/></div>

      <div id="aversatpop2" style={{display:activeTab === 'glad' && open2 && open1 ? '' : "none"}}>
        <div className="avhead" role="presentation" onClick={()=>{
          setApp({type:'_AVS',payload:['hide2',!hide2]})
        }} >
          <div className="table-cell inline-top">
            <h5>Alert verification system</h5>
          </div>
          <div className="table-cell inline-top text-right">
            {hide2 ? (<ChevronUp size="20"/>) : (<ChevronDown size="20"/>)}
          </div>
        </div>
        <Collapse isOpen={!hide2}>
          <div className="avbody">
            <h5>Select band combination</h5>
            <div className="form-inline">
              <div className="radio">
                <label htmlFor="av3">
                  <input type="radio" name="avColor2" id="av3" checked={type2} onChange={(e)=>{
                    if(e.target.checked){
                      setApp({type:'_AVS',payload:['type2',true]})
                    }
                  }}/>
                  &nbsp;True color
                </label>
              </div>
              <div className="radio">
                &nbsp;&nbsp;&nbsp;<label htmlFor="av2">
                  <input type="radio" name="avColor2" id="av2" checked={!type2} onChange={(e)=>{
                    if(e.target.checked){
                      setApp({type:'_AVS',payload:['type2',false]})
                    }
                  }}/>
                  &nbsp;False color
                </label>
              </div>
            </div>
            <br/>
            <div id="aversatcal2">
            <DatePicker
              inline={false}
              style={{width:"100%"}}
              selected={date2 ? parseISO(date2) : parseISO(moment().subtract(1, "days").format('YYYY-MM-DD'))}
              onChange={date => {
                setApp({type:'_AVS',payload:['date2',moment(date).format('YYYY-MM-DD')]})
              }}
              value={date2 ? parseISO(date2) : parseISO(moment().subtract(1, "days").format('YYYY-MM-DD'))}
              minDate={parseISO('2019-01-01')}
              maxDate={parseISO(moment().subtract(1, "days").format('YYYY-MM-DD'))}
              dateFormat='yyyy-MM-dd'
              popperPlacement="bottom-start"
              onSelect={()=>{

              }}
              />
            </div>
          </div>
        </Collapse>
      </div>

      <div role="presentation" onClick={()=>{
        batch(()=>{
          if(open1 && open2){
            setApp({type:'_AVS',payload:['open2',!1]})
          }
          setApp({type:'_AVS',payload:['open1',!open1]})
        })
        setApp({type:'_AVS',payload:['open1',!open1]})
      }} id="aversat" style={{display:activeTab === 'glad' ? '' : "none"}} className={open1 ? `a` : ``}><Satellite size="20"/></div>

      <div id="aversatpop" style={{display:activeTab === 'glad' && open1 ? '' : "none"}}>
        <div className="avhead" role="presentation" onClick={()=>{
          setApp({type:'_AVS',payload:['hide1',!hide1]})
        }} >
          <div className="table-cell inline-top">
            <h5>Alert verification system</h5>
          </div>
          <div className="table-cell inline-top text-right">
            {hide1 ? (<ChevronUp size="20"/>) : (<ChevronDown size="20"/>)}
          </div>
        </div>
        <Collapse isOpen={!hide1}>
          <div className="avbody">
            <h5 role="presentation" onClick={()=> setBandInfo(!bandInfo)} style={{cursor:'pointer'}}>Select band combination <InfoCircle size="14" style={{color:'#999'}}/></h5>
            <Collapse isOpen={bandInfo}>
              <div className="alert alert-info">True means:<br/><br/>False means:<br/></div>
            </Collapse>
            <div className="form-inline">
              <div className="radio">
                <label htmlFor="av1">
                  <input type="radio" name="avColor" id="av1" checked={type1} onChange={(e)=>{
                    if(e.target.checked){
                      setApp({type:'_AVS',payload:['type1',true]})
                    }
                  }}/>
                  &nbsp;True color
                </label>
              </div>
              <div className="radio">
                &nbsp;&nbsp;&nbsp;<label htmlFor="av0">
                  <input type="radio" name="avColor" id="av0" checked={!type1} onChange={(e)=>{
                    if(e.target.checked){
                      setApp({type:'_AVS',payload:['type1',false]})
                    }
                  }}/>
                  &nbsp;False color
                </label>
              </div>
            </div>
            <br/>
            <div id="aversatcal">
            <DatePicker
              inline={false}
              style={{width:"100%"}}
              selected={date1 ? parseISO(date1) : parseISO(moment().subtract(1, "days").format('YYYY-MM-DD'))}
              onChange={date => {
                setApp({type:'_AVS',payload:['date1',moment(date).format('YYYY-MM-DD')]})
              }}
              value={date1 ? parseISO(date1) : parseISO(moment().subtract(1, "days").format('YYYY-MM-DD'))}
              minDate={parseISO('2019-01-01')}
              maxDate={parseISO(moment().subtract(1, "days").format('YYYY-MM-DD'))}
              dateFormat='yyyy-MM-dd'
              popperPlacement="bottom-start"
              onSelect={()=>{

              }}
              />
            </div>
          </div>
        </Collapse>
      </div>
      {
        activeTab === 'glad' && open1 ? (
          <AvsLayer1 date={date1 || moment().subtract(1, "days").format('YYYY-MM-DD')} type={type1}/>
        ) : null
      }
      {
        activeTab === 'glad' && open2 && open1 ? (
          <AvsLayer2 date={date2 || moment().subtract(1, "days").format('YYYY-MM-DD')} avSwipe={$("#swipeAvs")} type={type2}/>
        ) : null
      }

    </>
  )
}
export default AlertVerificationSystem
