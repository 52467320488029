import $ from 'jquery'

export function _flyTo(location, zoom, view, done) {
  var duration = 5000;
  var parts = 2;
  var called = false;
  function callback(complete) {
    --parts;
    if (called) {
      return;
    }
    if (parts === 0 || !complete) {
      called = true;
      if(done){
        done(complete);
      }
    }
  }
  view.animate(
    {
      center: location,
      duration: duration,
    },
    callback
  );
  view.animate(
    {
      zoom: 4,
      duration: duration / 2,
    },
    {
      zoom: zoom,
      duration: duration / 2,
    },
    callback
  );
}

const mapUpdate = (map) => {
  if(map && typeof window !== undefined){
    const resize = () => {
      map.updateSize()
    }
    window.addEventListener('resize', resize);
    resize()
    return () => {
      window.removeEventListener('resize', resize);
    }
  }
}
export const isPin = () => {
  if(typeof document !== undefined){
    return document.getElementById('sidebar').className.indexOf("pin") === -1 ? !1 : 1
  }
}
export const togglePin = (map,open) => {
  if(typeof document !== undefined){
    const pin = isPin()
    if(!open){
      document.getElementById('sidebar').classList.toggle("pin");
      document.getElementById('ol-map').classList.toggle("pin");
      if(pin){
        // document.getElementById('ol-map').style.width = 'calc(100vw - 60px)';
        // mapUpdate(map)
        setTimeout(()=>{
          // document.getElementById('ol-map').style.width = 'calc(100vw - 462px)';
          mapUpdate(map)
        },50)
      }else{
        // document.getElementById('ol-map').style.width = 'calc(100vw - 462px)';
        setTimeout(()=>{
          // document.getElementById('ol-map').style.width = 'calc(100vw - 462px)';
          mapUpdate(map)
        },50)
      }
    }else{
      if(!pin){
        document.getElementById('sidebar').classList.toggle("pin");
        document.getElementById('ol-map').classList.toggle("pin");
        // document.getElementById('ol-map').style.width = 'calc(100vw - 462px)';
        setTimeout(()=>{
          // document.getElementById('ol-map').style.width = 'calc(100vw - 462px)';
          mapUpdate(map)
        },50)
      }
    }
    document.getElementById('pinned').style.transform = pin ? '' : 'rotate(180deg)'
  }

}

export function _gcData(o){
  var d = o.d, i = 0, t = d.length,
  loop = typeof o.loop != 'undefined' ? o.loop : !1,
  s = typeof o.s != 'undefined' ? 1 : 0, s1 = 0,
  y = typeof o.y != 'undefined' ? o.y : !1,
  oArr = [].concat(o.yA);
  while (i<t) {
    if( y && o.c){
      if(d[i].year === y && d[i].class === o.c){
        s1 = d[i].areaha;
        break;
      }
    }else if(loop){
      if(d[i].year >= loop[0] && d[i].year <= loop[1] && d[i].class === o.c){
        s1 += d[i].areaha;
      }

    }else{
      if(d[i].class === o.c){
        if(s){
          s1 = d[i].areaha;
          break;
        }else{
          if(d[i].year >= o.sY && d[i].year <= o.eY){
            oArr[parseInt(d[i].year.toString().substr(2,2)) - 1] = d[i].areaha;
          }
        }
      }
    }
    i++;
  }
  return loop || s || y ? s1 : oArr;
}

export function _formatNumber(n){var d = String(n).split('.'),r = parseInt(d[0]) > 999 ? String(d[0]).replace(/(.)(?=(\d{3})+$)/g,'$1,') : d[0];r = typeof d[1] !== 'undefined' && d[1] !== '00' ? r + '.' + d[1] : r;return r;}
export function fDec(v){if(typeof v !== 'number'){return 0;}else if(v % 1 !== 0){if(Math.floor(v) === v || v === 0){return v;}if(v.toString().split(".")[1].length){return Math.round(v);}return v;}else if(v === 0){return 0;}else{return v;}}
export function _fNDec(n){return _formatNumber(fDec(n));}
export function _1Dec(v){if(!$.isNumeric( v )){return 0;}else if(v % 1 !== 0){if(Math.floor(v) === v || v === 0){return v;}if(v.toString().split(".")[1].length && v < 1){return v.toFixed(1);}return Math.floor(v);}else if(v === 0){return 0;  }else{return v;}}
export function _f1Dec(v){return _formatNumber(_1Dec(v))}
export function is_mobile(){
  return window.innerWidth <= 768 || document.documentElement.clientWidth <= 768 || document.body.clientWidth <= 768 ? !0 : !1
}
export function _getFieldName(activeTool){
  switch (activeTool) {
    case 'oilpalm':
    case 'pulpwood':
    case 'mining':
    case 'logging':
    case 'mill':
    case 'millpulpwoodbuffer':
    return 'groupcom';
      case 'country':
      case 'province':
      case 'district':
      return activeTool
    default:
    return 'name';
  }
}
export function DateToJD(date) {
  var jd = Math.floor((new Date(date).getTime()-Date.parse('2014-12-31'))/86400000);
  return jd
}
export function JDtoDate(jd) {
  var date = new Date((16435+jd)*86400000).toISOString().split('T')[0];
  return date;
}
export function _getLayer(map,id){
  var l = !1,
  d = map.getLayers().getArray(),
  i = d.length - 1;
  while(i>=0){
    if(d[i].get('name') === id){
      l = d[i];
      break;
    }
    i--;
  }
  return l;
}
export function _reorderLayer(map) {
  var l = $('#layers-block .layer'),
  i=l.length - 1,
  n=1;
  while(i>=0){
    var id = $(l[i]).data('id').toString(),
    ly = _getLayer(map,id);
    if(ly)ly.setZIndex(n);
    n++;
    i--;
  }
  if(_getLayer(map,'gladLayer')){
    _getLayer(map,'gladLayer').setZIndex(800);
  }
}
export function formatKiloMegaGiga(a,b=1){if(0===a)return"0";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1000));return parseFloat((a/Math.pow(1000,d)).toFixed(c))+" "+["","K","M","G","T","P","E","Z","Y"][d]}
export function _isABoundary(l){return (l === 'country' || l === 'province' || l === 'district') ? true : false}
export function _singleName(l){return (l === 'country' || l === 'province' || l === 'district') ? l : 'name'}
export function _hasGroup(d){
  switch (d) {
    case 'oilpalm':
    case 'pulpwood':
    case 'mining':
    case 'logging':
    case 'mill':
    case 'millpulpwoodbuffer':
    return true;
    default:
    return false;
  }
}
export function _isInt(n) {
  if (typeof n !== 'number')return false;
  return !isNaN(n) && parseInt(Number(n)) === n && !isNaN(parseInt(n, 10));
}
export function _reloadCustomSelect(setApp){
  $('#as-input-groupcom,#as-input-types, #as-input-countries, #as-input-provinces, #as-input-districts, #as-input-districts-luz').parent().find('li.opt').on('click',function(){
    var el = $($(this).parent().parent().prev().prev()),
    y = el.val(),
    fc = el.parent().find('ul > li:first-child'),
    lbl = $(fc).find('label').text();

    if($(this).is(':first-child')){
      el.parent().find('ul > li').not(':first-child').removeClass('selected');
      el.val(['*']);
      el.parent().find('p').attr('title', lbl);
      el.parent().find('p span').text(lbl);

    }else{
      if(el.val() != null){
        if(el.val().indexOf('*') > -1 ){
          $(fc).removeClass('selected');
          const removeItem = '*';
          y = $.grep(y, function(value) {
            return value !== removeItem;
          });
          var p = el.parent().find('p').attr('title').replace(lbl + ',','');
          var span = el.parent().find('p span').text().replace(lbl + ',','');
          el.parent().find('p').attr('title', p);
          el.parent().find('p span').text(span);
          el.val(y);
        }
      }else{
        el.parent().find('p').attr('title', lbl);
        el.parent().find('p span').text(lbl);
      }
    }
    setApp({type:'_ADVANCED_SEARCH',payload:[el.attr('id').replace('as-input-',''),el.val()]})
  });

  return () => {
    $('#as-input-groupcom,#as-input-types, #as-input-countries, #as-input-provinces, #as-input-districts, #as-input-districts-luz').parent().find('ul > li').off("click");
  }
}
export function _checkCountry(setApp){
  $('#as-input-countries').on('sumo:closed', function() {

    var v = $('#as-input-countries').val(),
    i = v.length - 1;
    $('#as-input-provinces').val(['*'])[0].sumo.reload()
    $('#as-input-districts').val(['*'])[0].sumo.reload()

    if(v[0] === '*'){
      $('.province-for,.district-for').show();
    }else{
      $('.province-for,.district-for').hide();
      while(i>=0){
        var c = v[i].replace(/\s+/g, '-').toLowerCase();
        $('.province-for-'+c).show();
        $('.district-for-'+c).show();
        i--;
      }
    }
    _reloadCustomSelect(setApp)

  });
  $('#as-input-provinces').on('sumo:closed', function() {

    var v = $('#as-input-provinces').val(),
    i = v.length - 1;
    $('#as-input-districts').val(['*'])[0].sumo.reload()

    if(v[0] === '*'){
      v = $('#as-input-countries').val();
      i = v.length - 1;
      if(v[0] === '*'){
        $('.district-for').show();
      }else{
        $('.district-for').hide();
        while(i>=0){
          var c = v[i].replace(/\s+/g, '-').toLowerCase();
          $('.district-for-'+c).show();
          i--;
        }
      }

    }else{
      $('.district-for').hide();
      while(i>=0){
        var c2 = v[i].replace(/\s+/g, '-').toLowerCase();
        $('.district-for-'+c2).show();
        i--;
      }
    }
    _reloadCustomSelect(setApp)
  });

  return () => {
    $('#as-input-countries').off('sumo:closed')
    $('#as-input-provinces').off('sumo:closed')
    $('#as-input-districts').off('sumo:closed')
  }
}
export function _validateEmail(m){
  return m.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? true : false;
}
export function _getUrlParameter(n,l) {
  // n = n.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var rgx = new RegExp('[\\?&]' + n + '=([^&#]*)'),
  r = rgx.exec(l.search);
  return r === null ? false : decodeURIComponent(r[1].replace(/\+/g, ' '));
};
