import React,{useState} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'

import Collapse from "@kunukn/react-collapse";
import {InfoCircle} from '@styled-icons/fa-solid'

import TabHead from './TabHead'
import rippleGIF from '../../images/ripple.gif'

import {_fNDec} from '../../helpers'

const showInfo = (setApp,url) => {
  batch(()=>{
    setApp({type:'_POPUP',payload:['popup','popupinfo']})
    setApp({type:'_POPUP',payload:['url',url]})
  })
}

const StatList = ({d}) => {
  const setApp = useDispatch()
  const [open,setOpen] = useState(0)
  return (
    <div className="sbbody-li">
      <h3 className="sbbody-title">
        {d.title}
        {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
          showInfo(setApp,`popupstat/i${d.i}`)
        }}><InfoCircle size="12"/></b>
      </h3>
      <div className="rank-table">
        <div>
          <span className="res-value">{d.data.area}</span>
          <span className="res-ha"> Ha</span>
          {
            d.data.bd && (
              <div className="stat-d">
                <span role="presentation" onClick={()=>{
                  setOpen(open ? 0 : 1)
                }}>
                  <i className="fa fa-angle-right"></i> {d.lang ? 'Detil' : 'Detail'}
                </span>
                <Collapse isOpen={open}>
                  <div className="stat-di">
                    {
                      d.data.bd.map((dt,i)=>{

                        return (
                          <div key={`${dt.t}${i}`}>
                            <div>{dt.t}</div>
                            <span className="res-value-s">{dt.d}</span><span className="res-ha">Ha</span>
                            {
                              dt.bd2 && (
                                <div className="stat-di stat-di2">
                                  {
                                    dt.bd2.map((dt2,i)=>{
                                      return (
                                        <div key={`${dt2.t}2${i}`}>
                                          <div>{dt2.t}</div>
                                          <span className="res-value-s">{dt2.d}</span><span className="res-ha">Ha</span>
                                          {
                                            dt2.bd3 && (
                                              <div className="stat-di stat-di2">
                                                {
                                                  dt2.bd3.map((dt3,i)=>{
                                                    return (
                                                      <div key={`${dt3.t}3${i}`}>
                                                        <div>{dt3.t}</div>
                                                        <span className="res-value-s">{dt3.d}</span><span className="res-ha">Ha</span>
                                                      </div>
                                                    )
                                                  })
                                                }
                                              </div>
                                              )
                                            }
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                )
                              }
                          </div>
                        )
                      })
                    }
                  </div>
                </Collapse>
              </div>
            )
          }
        </div>
        {
          d.data.rank ? (
            <div style={{borderLeft:'1px solid #ccc',paddingTop:5,paddingLeft:10}}>
              <div id={d.id} className="see-rank">{d.data.rank}
                <small role="presentation" onClick={()=>{
                  batch(()=>{
                    d.setApp({type:'_DATA',payload:['rankList',{
                      type: d.id === 'statGba' ? 'ba' : '',
                      rank: d.data.theRank,
                      fields: d.data.fields
                    }]})
                    d.setApp({type:'_POPUP',payload:['popup','ranklist']})
                  })
                }}>{d.lang ? 'Lihat semuanya' : 'View complete list'}</small>
              </div>
            </div>
          ) : null
        }
      </div>
    </div>
  )

}

const StatTab = () => {

  const single = useSelector(s=>s.app.single)
  const {rLang,lang} = useSelector(s=>s.lang)
  const {activeTool} = useSelector(s=>s.tool)
  const {activeTab} = useSelector(s=>s.tab)
  const statTabData = useSelector(s=>s.dat.statTabData)
  const setApp = useDispatch()

  const data = single ? statTabData.single : statTabData.group

  return (
    <div style={{display:`${activeTab === 'statistic' ? 'block' : 'none'}`,height:activeTool.indexOf('aoi') === 0 ? 'calc(100vh - 175px)' : ''}} className="tab-pane" id="stat-panel">
      <h2 className="tab-label">{rLang.TabStatHead[lang]}</h2>
      <TabHead/>
      <div className="sbbody">
        {
          !data ? (<div><img width="30" height="30" alt="" src={rippleGIF}/> <i>Loading . . .</i></div>) : (
            <>
            <StatList d={{
              lang,id: 'statG1',i:0,
              setApp,title:rLang.sbStat1[lang],
              data:{
                area:_fNDec(data.d.forest + data.d.mang + data.d.peat),
                bd: [
                  {
                    t:lang ? 'Hutan di tanah mineral' : 'Forest on mineral soil',
                    d:_fNDec(data.d.forest)
                  },
                  {
                    t:lang ? 'Hutan bakau' : 'Mangrove forest',
                    d:_fNDec(data.d.mang)
                  },
                  {
                    t:lang ? 'Hutan rawa gambut' : 'Peat-swamp forest',
                    d:_fNDec(data.d.peat)
                  }
                ],
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.remforestrank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.remforestrank} dari ${data.rank.totalcount}`,
                theRank: !data.rank ? null : data.rank.remforestrank,
                fields:['remforestrank','remforestha']
              }
            }}/>
            <StatList d={{
              lang,id: 'statGba',i:6,
              setApp,title:rLang.sbStatBa[lang],
              data:{
                area:_fNDec(data.d.burnarea),
                rank: !data.rankba ? null : lang === 0 ? `Top ${data.rankba.arearank} of ${data.rankba.totalcount}` : `Peringkat ${data.rankba.arearank} dari ${data.rankba.totalcount}`,
                theRank: !data.rankba ? null : data.rankba.arearank,
                fields:['arearank','areaha']
              }
            }}/>
            <StatList d={{
              lang,id: 'statG2',i:1,
              setApp,title:rLang.sbStat2[lang],
              data:{
                area:_fNDec(data.d.forestLoss[0] + data.d.forestLoss[1] + data.d.forestLoss[2]),
                bd: [
                  {
                    t:lang ? 'Hutan di tanah mineral' : 'Forest on mineral soil',
                    d:_fNDec(data.d.forestLoss[0])
                  },
                  {
                    t:lang ? 'Hutan bakau' : 'Mangrove forest',
                    d:_fNDec(data.d.forestLoss[1])
                  },
                  {
                    t:lang ? 'Hutan rawa gambut' : 'Peat-swamp forest',
                    d:_fNDec(data.d.forestLoss[2])
                  }
                ],
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.flossfr2000rank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.flossfr2000rank} dari ${data.rank.totalcount}`,
                theRank: !data.rank ? null : data.rank.flossfr2000rank,
                fields:['flossfr2000rank','flossfr2000ha']
              }
            }}/>
            <StatList d={{
              lang,id: 'statG3',i:2,
              setApp,title:rLang.sbStat3[lang],
              data:{
                area:_fNDec(data.d.forestClr[9]),
                bd: [
                  {
                    t:lang ? 'Deforestasi yang didorong oleh kelapa sawit' : 'Oil palm driven deforestation',
                    d:_fNDec(data.d.forestClr[0]+data.d.forestClr[1]+data.d.forestClr[2]+data.d.forestClr[6]+data.d.forestClr[7]+data.d.forestClr[8]),
                    bd2: [
                      {
                        t:lang ? 'Di tanah mineral' : 'On mineral soil',
                        d:_fNDec(data.d.forestClr[0] + data.d.forestClr[6]),
                        bd3: [
                          {
                            t: lang ? 'Industri' : 'Industry',
                            d: _fNDec(data.d.forestClr[0])
                          },
                          {
                            t: lang ? 'Petani kecil' : 'Smallholder',
                            d: _fNDec(data.d.forestClr[6])
                          }
                        ]
                      },
                      {
                        t:lang ? 'Di lahan bakau' : 'On mangrove soil',
                        d:_fNDec(data.d.forestClr[1]+data.d.forestClr[7]),
                        bd3: [
                          {
                            t: lang ? 'Industri' : 'Industry',
                            d: _fNDec(data.d.forestClr[1])
                          },
                          {
                            t: lang ? 'Petani kecil' : 'Smallholder',
                            d: _fNDec(data.d.forestClr[7])
                          }
                        ]
                      },
                      {
                        t:lang ? 'Di lahan gambut' : 'On peat soil',
                        d:_fNDec(data.d.forestClr[2]+data.d.forestClr[8]),
                        bd3: [
                          {
                            t: lang ? 'Industri' : 'Industry',
                            d: _fNDec(data.d.forestClr[2])
                          },
                          {
                            t: lang ? 'Petani kecil' : 'Smallholder',
                            d: _fNDec(data.d.forestClr[8])
                          }
                        ]
                      }
                    ]
                  },
                  {
                    t:lang ? 'Deforestasi yang didorong oleh kayu pulp' : 'Pulpwood driven deforestation',
                    d:_fNDec(data.d.forestClr[3]+data.d.forestClr[4]+data.d.forestClr[5]),
                    bd2: [
                      {
                        t:lang ? 'Di tanah mineral' : 'On mineral soil',
                        d:_fNDec(data.d.forestClr[3])
                      },
                      {
                        t:lang ? 'Di lahan bakau' : 'On mangrove soil',
                        d:_fNDec(data.d.forestClr[4])
                      },
                      {
                        t:lang ? 'Di lahan gambut' : 'On peat soil',
                        d:_fNDec(data.d.forestClr[5])
                      }
                    ]
                  },
                ],
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.comdrivenfr2000rank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.comdrivenfr2000rank} dari ${data.rank.totalcount}`,
                theRank: !data.rank ? null : data.rank.comdrivenfr2000rank,
                fields:['comdrivenfr2000rank','comdrivenfr2000ha']
              }
            }}/>
            <StatList d={{
              lang,id: 'statG4',i:3,
              setApp,title:rLang.sbStat4[lang],
              data:{
                area:_fNDec(data.d.nonForest[0] + data.d.nonForest[1] + data.d.nonForest[2]),
                bd: [
                  {
                    t:rLang.Oilpalm[lang],
                    d:_fNDec(data.d.nonForest[0]+data.d.nonForest[2]),
                    bd2: [
                      {
                        t: lang ? 'Industri' : 'Industry',
                        d: _fNDec(data.d.nonForest[0])
                      },
                      {
                        t: lang ? 'Petani kecil' : 'Smallholder',
                        d: _fNDec(data.d.nonForest[2])
                      }
                    ]
                  },
                  {
                    t:rLang.Pulpwood[lang],
                    d:_fNDec(data.d.nonForest[1])
                  }
                ],
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.nftoplantationfr2000rank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.nftoplantationfr2000rank} dari ${data.rank.totalcount}`,
                theRank: !data.rank ? null : data.rank.nftoplantationfr2000rank,
                fields:['nftoplantationfr2000rank','nftoplantationfr2000ha']
              }
            }}/>
            <StatList d={{
              lang,id: 'statG5',i:4,
              setApp,title:rLang.sbStat5[lang],
              data:{
                area:_fNDec(data.d.planted[0] + data.d.planted[1] + data.d.planted[2]),
                bd: [
                  {
                    t:rLang.Oilpalm[lang],
                    d:_fNDec(data.d.planted[0]+data.d.planted[2]),
                    bd2: [
                      {
                        t: lang ? 'Industri' : 'Industry',
                        d: _fNDec(data.d.planted[0])
                      },
                      {
                        t: lang ? 'Petani kecil' : 'Smallholder',
                        d: _fNDec(data.d.planted[2])
                      }
                    ]
                  },
                  {
                    t:rLang.Pulpwood[lang],
                    d:_fNDec(data.d.planted[1])
                  }
                ],
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.existingiprank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.existingiprank} dari ${data.rank.totalcount}`,
                theRank: !data.rank ? null : data.rank.existingiprank,
                fields:['existingiprank','existingipha']
              }
            }}/>
            <StatList d={{
              lang,id: 'statG6',i:5,
              setApp,title:rLang.sbStat6[lang],
              data:{
                area:_fNDec(data.d._2KPlant[0] + data.d._2KPlant[1] + data.d._2KPlant[2]),
                bd: [
                  {
                    t:rLang.Oilpalm[lang],
                    d:_fNDec(data.d._2KPlant[0]+data.d._2KPlant[2]),
                    bd2: [
                      {
                        t: lang ? 'Industri' : 'Industry',
                        d: _fNDec(data.d._2KPlant[0])
                      },
                      {
                        t: lang ? 'Petani kecil' : 'Smallholder',
                        d: _fNDec(data.d._2KPlant[2])
                      }
                    ]
                  },
                  {
                    t:rLang.Pulpwood[lang],
                    d:_fNDec(data.d._2KPlant[1])
                  }
                ],
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.ipfr2000rank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.ipfr2000rank} dari ${data.rank.totalcount}`,
                theRank: !data.rank ? null : data.rank.ipfr2000rank,
                fields:['ipfr2000rank','ipfr2000ha']
              }
            }}/>
            </>
          )
        }

      </div>
    </div>
  )

}
export default StatTab
