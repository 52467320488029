import React, { useEffect } from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {useImmer} from 'use-immer'

import wImg1 from '../../images/landing/Landing-P2-Ulet-IfansastiGreenpeace.jpg'
import wImg2 from '../../images/landing/Landing-Deforestation.jpg'
import wImg4 from '../../images/landing/Landing-P4-Ulet-Ifansasti-Greenpeace.jpg'
import wImg5 from '../../images/tools/Tools-P13-CIFOR.jpg'
import wImg6 from '../../images/landing/Landing-P6-Ulet-Ifansasti-Greenpeace.jpg'
import wImg7 from '../../images/landing/Landing-P7-P19-CIFOR.jpg'

const WelcomeScreen = () => {

  const setApp = useDispatch()
  const {map} = useSelector(s=>s.map)
  const {welcomeScreen} = useSelector(s=>s.welcome)
  const {rLang,lang} = useSelector(s=>s.lang)
  const [ss,setSs] = useImmer({
    wTimer: null,
    n: 0,
    t: 7
  })

  const clearwTimer = () => {
    if(ss.wTimer){
      clearTimeout(ss.wTimer);
      setSs(d=>{
        d.wTimer = null;
      })
    }
  }

  const setwTimer = (n) => {
    clearwTimer();

    if(ss.n !== -1){
      const wTime = ss.n === 1 ? 4000 : 4000;
      // setWSlide(n);
      setSs(d=>{
        d.wTimer = setTimeout(function(){
          setwTimer(ss.t - 1 === n ? 0 : n + 1);
        }, wTime);
        d.n = n
      })
    }
  }

  useEffect(()=>{

    if(map && welcomeScreen){
      setwTimer(ss.n)
    }else{
      clearwTimer();
    }
    return () => {
      clearwTimer();
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[ss.n,map,welcomeScreen])

  useEffect(()=>{
    if(typeof document !== undefined){
      const element = document.querySelector('#welcome-block')
      const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length ) // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js
      const outsideClickListener = event => {
          if (!element.contains(event.target) && isVisible(element)) { // or use: event.target.closest(selector) === null
            element.remove()
            clearwTimer();
            setSs(d=>{
              d.wTimer = null
            })
            setApp({type:'_WELCOME',payload:['welcomeScreen',false]})
            removeClickListener()
          }
      }

      const removeClickListener = () => {
          document.removeEventListener('click', outsideClickListener)
      }

      document.addEventListener('click', outsideClickListener)

      return () => {
        document.removeEventListener('click', outsideClickListener)
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[])

  return (
    <div id="welcome-block" style={{display:welcomeScreen && map ? '' : 'none'}}>
      <div id="welcome-screen">
        <div style={{opacity: ss.n === 0 ? 1 : 0,background:`url(${wImg1}) center center`,backgroundSize:'cover'}}>
          <div>
            <div className="welcome-screen-title">
              NUSANTARA ATLAS
            </div>
            <div className="welcome-screen-blurb">
              {rLang.W1[lang]}
            </div>
          </div>
        </div>
        <div style={{opacity: ss.n === 1 ? 1 : 0,background:`url(${wImg2}) center center`,backgroundSize:'cover'}}>
          <div>
            <div className="welcome-screen-title">
              NUSANTARA ATLAS
            </div>
            <div className="welcome-screen-blurb">
              {rLang.W2[lang]}
            </div>
          </div>
        </div>
        <div style={{opacity: ss.n === 2 ? 1 : 0,background:`url(${wImg1}) center center`,backgroundSize:'cover'}}>
          <div>
            <div className="welcome-screen-title">
              NUSANTARA ATLAS
            </div>
            <div className="welcome-screen-blurb">
              {rLang.W3[lang]}
            </div>
          </div>
        </div>
        <div style={{opacity: ss.n === 3 ? 1 : 0,background:`url(${wImg4}) center center`,backgroundSize:'cover'}}>
          <div>
            <div className="welcome-screen-title">
              NUSANTARA ATLAS
            </div>
            <div className="welcome-screen-blurb">
              {rLang.W4[lang]}
            </div>
          </div>
        </div>
        <div style={{opacity: ss.n === 4 ? 1 : 0,background:`url(${wImg5}) center center`,backgroundSize:'cover'}}>
          <div>
            <div className="welcome-screen-title">
              NUSANTARA ATLAS
            </div>
            <div className="welcome-screen-blurb">
              {rLang.W5[lang]}
            </div>
          </div>
        </div>
        <div style={{opacity: ss.n === 5 ? 1 : 0,background:`url(${wImg6}) center center`,backgroundSize:'cover'}}>
          <div>
            <div className="welcome-screen-title">
              NUSANTARA ATLAS
            </div>
            <div className="welcome-screen-blurb">
              {rLang.W6[lang]}
            </div>
          </div>
        </div>
        <div style={{opacity: ss.n === 6 ? 1 : 0,background:`url(${wImg7}) center center`,backgroundSize:'cover'}}>
          <div>
            <div className="welcome-screen-title">
              NUSANTARA ATLAS
            </div>
            <div className="welcome-screen-blurb">
              {rLang.W7[lang]}
            </div>
          </div>
        </div>
      </div>
      <div id="welcome-dot">
        <span role="presentation" className={ss.n === 0 ? 'a' : ''} onClick={()=>{
          setwTimer(0);
        }}></span>
        <span role="presentation" className={ss.n === 1 ? 'a' : ''} onClick={()=>{
          setwTimer(1);
        }}></span>
        <span role="presentation" className={ss.n === 2 ? 'a' : ''} onClick={()=>{
          setwTimer(2);
        }}></span>
        <span role="presentation" className={ss.n === 3 ? 'a' : ''} onClick={()=>{
          setwTimer(3);
        }}></span>
        <span role="presentation" className={ss.n === 4 ? 'a' : ''} onClick={()=>{
          setwTimer(4);
        }}></span>
        <span role="presentation" className={ss.n === 5 ? 'a' : ''} onClick={()=>{
          setwTimer(5);
        }}></span>
        <span role="presentation" className={ss.n === 6 ? 'a' : ''} onClick={()=>{
          setwTimer(6);
        }}></span>
      </div>
    </div>
  )
}
export default WelcomeScreen
