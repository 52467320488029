import React,{useEffect} from 'react'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {useImmer} from 'use-immer'
import Collapse from "@kunukn/react-collapse";

import AoiLayer from '../layers/AoiLayer'

import config from '../../config'
import {_validateEmail} from '../../helpers'

import rippleGIF from '../../images/ripple.gif'

const submitDraw = ({d,setState,lang}) => {
  const bodyFormData = new FormData();
  bodyFormData.append('email', d.email);
  bodyFormData.append('geometry', JSON.stringify(d.geometry));
  console.log('bodyFormData',bodyFormData.get('geometry'));

  setState(d=>{
    d.loader = true
  })

  axios.post(`${config.apiP8080}processing/aoiprocessing.py`,bodyFormData)
  .then((r)=>{
    if(r.data){
      if(r.data.status === 'success'){
        setState(d=>{
          d.clear = false
          d.error = false
          d.success = lang === 1 ? 'Terkirim untuk diproses' : 'Task successfully submitted to the server'
          d.geom = []
          d.loader = false
        })
      }else{
        setState(d=>{
          d.error = lang === 1 ? 'Pengiriman gagal!' : 'Submission failed!'
          d.loader = false
        })
      }
    }
    console.log('DRAW_SUBMIT_STATUS',r.data);
  }).catch((e)=>{
    setState(d=>{
      d.error = lang === 1 ? 'Pengiriman gagal!' : 'Submission failed!'
      d.loader = false
    })
    console.log('DRAW_SUBMIT_STATUS',e.response);
  })
}

const AoiDraw = () => {

  const {activeTool} = useSelector(s=>s.tool)
  const {lang} = useSelector(s=>s.lang)
  const [state,setState] = useImmer({
    clear: false,
    open: true,
    error: false,
    success: false,
    loader: false,
    geom: []
  })

  useEffect(()=>{
    if(activeTool === 'aoiDRAW'){

      return () => {
        setState(d=>{
          d.clear = false
          d.error = false
          d.loader = false
          d.geom = []
        })
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[activeTool])

  return (
    <>
    <div id="udraw" className="udraw" style={{display:activeTool === 'aoiDRAW' ? '' : "none"}}>
      <div className="udraw-text">
        <Collapse isOpen={state.open}>
          <p>
            {
              lang === 1 ?
              'Silahkan gambar wilayah perhatian Anda di peta. Setelah selesai, Anda dapat memasukkan email Anda dan mengirimkan ke server. Prosesnya akan memakan waktu sekitar 10 menit atau lebih (tergantung pada luasan wilayah dan beban kerja server). Email dengan tautan ke hasil akan dikirim setelah siap.'
              :
              'Please draw your AoI on the map. Once you are done, you can enter your email and submit the processing to the server. The process will take about 10 minutes or more (depend on the extent of the AoI and server workload). An email with a link to the result will be sent right after its ready.'
            }
          </p>
        </Collapse>
        <button id="udraw-text-toggle" onClick={()=>{
          setState(d=>{d.open = !state.open})
        }}>
          <span>{state.open ? (lang === 1 ? 'SEMBUNYIKAN' : 'HIDE') : (lang === 1 ? 'TAMPILKAN' : 'SHOW')} INFO </span><i className="fa fa-angle-up"></i>
        </button>
      </div>
      <div id="draw-end">
        <div className={`form-group-lg ${state.success ? 'hide' : ''}`}>
          <input type="email" id="udraw-email" placeholder="your@email.com" className="form-control"/>
        </div>
        <div id="udraw-error" style={{display:state.error ? '' : "none"}} className="alert alert-danger">{state.error || ''}</div>
        {
          state.success ? (
            <div className="alert alert-success" style={{marginTop: 15,marginBottom: 0}}>{state.success}</div>
          ) : (
            <>
              {
                state.loader ? (<div style={{marginTop:15}}><img width="30" height="30" alt="" src={rippleGIF}/> <i>{lang ? 'Sedang mengirim' : 'Submitting'} . . .</i></div>) : (
                  <>
                    <button onClick={()=>{
                      setState(d=>{d.error = false})
                      if(document.querySelector('#udraw-email').value.trim()){
                        const v = document.querySelector('#udraw-email').value.trim()
                        if(_validateEmail(v)){
                          console.log('state.geom',state.geom);
                          submitDraw({
                            d: {
                              email: v,
                              geometry: state.geom
                            },
                            setState,
                            lang
                          })
                        }else{
                          setState(d=>{d.error = lang === 1 ? 'Email tidak valid!' : 'Email invalid!'})
                        }
                      }else{
                        setState(d=>{d.error = lang === 1 ? 'Email masih kosong!' : 'Email still empty!'})
                      }
                    }} id="udraw-submit" className={`btn btn-default ${state.success ? 'hide' : ''}`}>
                      {lang === 1 ? 'Kirim utk diproses' : 'Submit for processing'}
                    </button>
                    <button onClick={()=>{
                      setState(d=>{
                        d.clear = true
                        if(state.error) d.error = false
                      })
                    }} id="redraw" className={`btn btn-default ${state.success ? 'hide' : ''}`}>
                      {lang === 1 ? 'Ulangi menggambar' : 'Redraw AoI'}
                    </button>
                  </>
                )
              }
            </>
          )
        }

      </div>
    </div>
    {
      activeTool === 'aoiDRAW' ? (<AoiLayer clear={state.clear} setState={setState} />) : null
    }

    </>
  )
}
export default AoiDraw
