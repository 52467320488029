import { useEffect } from "react";
import {useSelector} from 'react-redux'

import Draw from 'ol/interaction/Draw'
import {Vector as VectorLayer} from 'ol/layer'
import {Vector as VectorSource} from 'ol/source'
import GeoJSON from 'ol/format/GeoJSON'

import {_getLayer} from  '../../helpers'

const AoiLayer = ({clear,setState}) => {

  const map = useSelector(s=>s.map.map)
  const {activeTool} = useSelector(s=>s.tool)

  useEffect(()=>{

    if(map){
      let aoiLayer = _getLayer(map,'aoiDRAW'),
      udraw = !1
      if(activeTool === 'aoiDRAW'){
        if(clear){
          setState(d=>{d.clear = false})
          if(aoiLayer){
            map.removeInteraction(udraw);map.removeLayer(aoiLayer);
            aoiLayer.getSource().clear();
          }
        }

        if(aoiLayer){
          map.removeLayer(aoiLayer)
        }
        aoiLayer = new VectorLayer({
      	  source: new VectorSource({wrapX: false}),
          name: 'aoiDraw'
      	});

        document.querySelector('#draw-end').style.display = 'none'
        // clearDraw();
        map.addLayer(aoiLayer);
        aoiLayer.setZIndex(1000);
        udraw = new Draw({
          source: aoiLayer.getSource(),
          type: 'Polygon',
        });
        map.addInteraction(udraw);
        udraw.on('drawend',function(e){
          setState(d=>{
            d.geom = JSON.parse(new GeoJSON().writeFeatures([e.feature], { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:4326'}))
          })
          map.removeInteraction(udraw)
          document.querySelector('#draw-end').style.display = ''
          // submitJob({
          //   aoi:'udraw',
          //   g:e.feature.getGeometry()
          // });
        });
      }

      return () => {
        if(aoiLayer){
          map.removeLayer(aoiLayer)
          if(udraw){
            map.removeInteraction(udraw)
          }
        }
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[map,activeTool,clear])

  return null
}
export default AoiLayer
