import React,{useRef} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {Times} from '@styled-icons/fa-solid'

function closeThis(setApp,ref){
  ref.current.style.opacity = 0;
  setTimeout(()=>{
    setApp({type:'SET_POPUP_LARGE',payload:{title:null,body:null}})
  },250)

}

const PopupLarge = () => {

  const popupLarge = useSelector(s=>s.popupLarge)
  const setApp = useDispatch()
  const popupLargeRef = useRef()

  return (
    <div ref={popupLargeRef} className="popup full-p" style={{display:`${popupLarge.title ? '' : 'none'}`,opacity:`${popupLarge.title ? 1 : 0}`}}>
      <div role='presentation' className="popup-bg" onClick={()=>closeThis(setApp,popupLargeRef)}/>
      <div>
        <div>
          <div className="popup-header">
            <div>{popupLarge.title}</div>
            <div role='presentation' onClick={()=>closeThis(setApp,popupLargeRef)} className="cursor-pointer"><Times size="14"/></div>
          </div>
          <div className="popup-inner" dangerouslySetInnerHTML={{__html:popupLarge.body}}/>
        </div>
      </div>
    </div>
  )
}
export default PopupLarge
