import React from 'react'
const StatsIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 15">
      <defs>
      </defs>
      <text id="STATS" transform="translate(8 12)" style={{fontFamily:'Roboto Condensed'}}><tspan fill={color} stroke={color} strokeWidth="0.5" x="-8" y="0">STATS</tspan></text>
    </svg>
  )
}
export default StatsIcon
