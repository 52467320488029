import React, {useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {AngleRight} from '@styled-icons/fa-solid'

import axios from 'axios'

import imgN from '../../images/tools/Tools-P15-CIFOR.jpg'
import imgF from '../../images/tools/Tools-P17-CIFOR.jpg'
import imgP from '../../images/tools/Tools-P17-CIFOR.jpg'
import imgPB from '../../images/tools/Tools-P18-CIFOR.jpg'

import {setInitData} from '../../actions/initAction'
import {isPin,togglePin} from '../../helpers'

const createDOMPurify = require('dompurify');
let DOMPurify = null;

const setStart = ({setApp,defaultLayers,layers,tool,map,aoiLayerResult}) => {
  if(aoiLayerResult){
    map.removeLayer(aoiLayerResult)
  }
  setApp(setInitData({defaultLayers,layers,tool}))
  if(!isPin()){
    togglePin(map)
  }
}

const TSluz = () => {
  const {map} = useSelector(s=>s.map)
  const toolScreen = useSelector(s=>s.toolScreen)
  const setApp = useDispatch()
  const { layers, defaultLayers } = useSelector(s=>s.layers);
  const {rLang, lang} = useSelector(s=>s.lang)
  const {aoiLayerResult} = useSelector(s=>s.aoi)

  useEffect(()=>{
    if(typeof window !== undefined){
      DOMPurify = createDOMPurify(window);
    }
    if(layers.length){
      /* IOP */
      // axios.get('https://nusantara-atlas.org/dbrest/' + layers.oilpalm.sLayer + '?select=count')
      // .then(function(r){
      //   console.log('AXIOSAIOOASXIOASIOXS');
      //   document.querySelector('#sum-IOP .sum-t > div').innerText = r.data[0].count;
      // });
      // axios.get('https://nusantara-atlas.org/dbrest/' + layers.oilpalm.gUrl + '?select=count')
      // .then(function(r){
      //   document.querySelector('#sum-IOP .sum-g > div').innerText = r.data[0].count;
      // });
      //
      // /* ITP */
      // axios.get('https://nusantara-atlas.org/dbrest/' + layers.pulpwood.sLayer + '?select=count')
      // .then(function(r){
      //   document.querySelector('#sum-ITP .sum-t > div').innerText = r.data[0].count;
      // });
      // axios.get('https://nusantara-atlas.org/dbrest/' + layers.pulpwood.gUrl + '?select=count')
      // .then(function(r){
      //   document.querySelector('#sum-ITP .sum-g > div').innerText = r.data[0].count;
      // });
      //
      // /*Logging*/
      // axios.get('https://nusantara-atlas.org/dbrest/' + layers.logging.sLayer + '?select=count')
      // .then(function(r){
      //   document.querySelector('#sum-log .sum-t > div').innerText = r.data[0].count;
      // });
      // axios.get('https://nusantara-atlas.org/dbrest/' + layers.logging.gUrl + '?select=count')
      // .then(function(r){
      //   document.querySelector('#sum-log .sum-g > div').innerText = r.data[0].count;
      // });
      //
      // /*Mining*/
      // axios.get('https://nusantara-atlas.org/dbrest/' + layers.mining.sLayer + '?select=count')
      // .then(function(r){
      //   document.querySelector('#sum-mining .sum-t > div').innerText = r.data[0].count;
      // });
      // axios.get('https://nusantara-atlas.org/dbrest/' + layers.mining.gUrl + '?select=count')
      // .then(function(r){
      //   document.querySelector('#sum-mining .sum-g > div').innerText = r.data[0].count;
      // });

    }



  },[layers])

  return (
    <>
      <div id="tool-info-concession" className="tool-info" style={toolScreen.tool !== 'luz' ? css.hide : css.show}>
        <div className="tool-bg"/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">

              {
                DOMPurify && (
                  <>
                    <div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolLUZTitle[lang])}}/>
                    <div className="tool-info-desc" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolLUZDesc[lang])}}/>
                    <div className="tool-info-option-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.AnalyzeIn[lang])}}/>
                  </>
                )
              }


              <button className="btn btn-primary btn-oilpalm go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'luzN',bg:imgN}})} style={{marginLeft:0}}><span>{rLang.National[lang]}</span><span><AngleRight className="d-angle"/></span></button>
              <button className="btn btn-primary btn-pulpwood go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'luzF',bg:imgF}})}><span>{rLang.ForestZone[lang]}</span><span><AngleRight className="d-angle"/></span></button>
              <button className="btn btn-primary btn-logging go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'luzP',bg:imgP}})}><span>{rLang.PapuaProvince[lang]}</span><span><AngleRight className="d-angle"/></span></button>
              <button className="btn btn-primary btn-mining go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'luzPB',bg:imgPB}})}><span>{rLang.WestPapuaProvince[lang]}</span><span><AngleRight className="d-angle"/></span></button>
              <button className="btn btn-primary go-child" onClick={()=>setApp({type:'SET_TOOL_SCREEN',payload:{tool:'luzMora',bg:imgF}})}><span>Moratorium</span><span><AngleRight className="d-angle"/></span></button>

            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-concession-oilpalm" style={toolScreen.tool !== 'luzN' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                {DOMPurify && (<div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolLUZNTitle[lang])}}/>)}
                <div id="sum-luzN" className="sum-d">
                  {/*<div className="sum-t">
                    <div></div>
                    <span>{rLang.Districts[lang]}</span>
                  </div>
                  <div className="sum-g">
                    <div></div>
                    <span>{rLang.Provinces[lang]}</span>
                  </div>*/}
                </div>
                <div id="gl-tc-4" className="tool-info-desc"></div>
                <button onClick={()=>{
                  setStart({setApp,tool:'luzN',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-concession-pulpwood" style={toolScreen.tool !== 'luzF' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                {DOMPurify && (<div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolLUZFTitle[lang])}}/>)}
                <div id="sum-luzF" className="sum-d">
                  {/*<div className="sum-t">
                    <div></div>
                    <span>{rLang.Districts[lang]}</span>
                  </div>
                  <div className="sum-g">
                    <div></div>
                    <span>{rLang.Provinces[lang]}</span>
                  </div>*/}
                </div>
                <div id="gl-tc-6" className="tool-info-desc"></div>
                <button onClick={()=>{
                  setStart({setApp,tool:'luzF',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-concession-logging" style={toolScreen.tool !== 'luzP' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                {DOMPurify && (<div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolLUZPTitle[lang])}}/>)}
                <div id="sum-luzP" className="sum-d">
                  {/*<div className="sum-t">
                    <div></div>
                    <span>{rLang.Districts[lang]}</span>
                  </div>*/}
                </div>
                <div id="gl-tc-8" className="tool-info-desc"></div>
                <button onClick={()=>{
                  setStart({setApp,tool:'luzP',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tool-info-concession-mining" style={toolScreen.tool !== 'luzPB' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                {DOMPurify && (<div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolLUZPBTitle[lang])}}/>)}
                <div id="sum-luzPB" className="sum-d">
                  {/*<div className="sum-t">
                    <div></div>
                    <span>{rLang.Districts[lang]}</span>
                  </div>*/}
                </div>
                <div id="gl-tc-10" className="tool-info-desc"></div>
                <button onClick={()=>{
                  setStart({setApp,tool:'luzPB',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={toolScreen.tool !== 'luzMora' ? css.hide : css.show}>
        <div className="tool-bg"></div>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                {DOMPurify && (<div className="tool-info-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rLang.ToolLUZMoraTitle[lang])}}/>)}
                <div id="sum-luzPB" className="sum-d">
                  {/*<div className="sum-t">
                    <div></div>
                    <span>{rLang.Districts[lang]}</span>
                  </div>*/}
                </div>
                <div id="gl-tc-10" className="tool-info-desc"></div>
                <button onClick={()=>{
                  setStart({setApp,tool:'luzMora',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

const css = {
  show: {
    opacity:1,
    zIndex:1,
    height:'auto',
    overflow:'auto'
  },
  hide: {
    opacity:0,
    zIndex:-1,
    height:0,
    overflow:'hidden'
  }
}

export default TSluz
