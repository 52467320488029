import {useEffect} from 'react'
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';

import config from '../../config.js'
import {_getLayer} from  '../../helpers'

const WMSLayer = ({map, layer, params, style, zIndex}) => {
  useEffect(()=>{
    let tl = _getLayer(map,layer.id)
    if(map && layer){
      if(!tl){
        tl = new TileLayer({
          source: new TileWMS({
            url:`${config.api}${layer.url}`,
            params: {'LAYERS': layer.layer, 'TILED': true, 'VERSION': '1.1.0'},
            serverType: 'geoserver',
            crossOrigin: 'anonymous'
          }),
          opacity: layer.opacity,
          visible:1,
          className: 'l-'+layer.id+' l-search',
          name: layer.id
        })
        if(zIndex){
          tl.setZIndex(zIndex)
        }
        map.addLayer(tl);

      }else{
        tl.setVisible(1);
      }

    }
    return () => {
      if(tl){
        tl.setVisible(!1);
      }
    }

  },[layer,map,params,style,zIndex])
  return null

}
export default WMSLayer
