import React,{useRef,useEffect,useState} from 'react'
import {useImmer} from 'use-immer'
import {useSelector,useDispatch,batch} from 'react-redux'
import axios from 'axios'
import {Times} from '@styled-icons/fa-solid'

import {_fNDec,_hasGroup,_isABoundary} from '../../helpers'

function closeThis(setApp,ref){
  ref.current.style.opacity = 0;
  setTimeout(()=>{
    setApp({type:'_POPUP',payload:['popup',!1]})
  },250)
}

function _sortGladRank(o){
  axios.post(o.apiRPC+'atlas_getrankalertlist',{
    unit: o.unit,
    datatype: o.datatype,
    daterange: `{${document.getElementById('glad-from').value},${document.getElementById('glad-to').value}}`,
    astatus: document.getElementById('glad-check').checked ? 'all' : 'confirmed',
    class:o.c
  }).then(function(r){
    if(r.data){
      if(r.data[0].arearank !== 1){
        r.data.reverse()
      }
      o.setList(r.data)
    }
  });
}

const RankList = () => {

  const {single} = useSelector(s=>s.app)
  const {apiRPC,apiP1} = useSelector(s=>s.static)
  const {activeTool} = useSelector(s=>s.tool)
  const {lang} = useSelector(s=>s.lang)
  const {popup} = useSelector(s=>s.popup)
  const dat = useSelector(s=>s.dat)
  const {layers} = useSelector(s=>s.layers)
  const setApp = useDispatch()

  const [theList,setList] = useState([])
  const [gladSort,setGladSort] = useImmer({
    open: false,
    active: 'Total'
  })
  const [pagination,setPagination] = useImmer({
    list: [],
    page: 1,
    perPage: 200,
    total: 0
  })

  const rankListRef = useRef()
  const innerRef = useRef()

  const viewMap = (id) => {
    batch(()=>{
      setApp({type:'_SEARCH',payload:['min',true]})
      setApp({type:'_APP',payload:['viewMap',id]})
      setApp({type:'_POPUP',payload:['popup',!1]})
    })
  }

  const TheRankList = ({i}) => {
    const name = theList[i].name || theList[i].country || theList[i].province || theList[i].district || theList[i].groupcom
    return name ? (
      <tr className={
        dat.rankList.fields && dat.rankList.rank === theList[i][dat.rankList.fields[0]] ? 'hl-this' : (
          dat.rankList.rank === theList[i].arearank ? 'hl-this' : ''
        )
      }>
        <td className="text-center">{dat.rankList.fields ? theList[i][dat.rankList.fields[0]] : theList[i].arearank}</td>
        <td className="search-this">
          {name }
          <div className="m-only">
            <button className="search-showmap" data-type="oilpalm">
              <i className="fa fa-map-marker"></i> MAP
            </button>
            <button className="search-showstat" data-type="oilpalm">
              <i className="fa fa-ellipsis-v"></i> STAT
            </button>
          </div>
        </td>
        <td className="text-right">{_fNDec(dat.rankList.fields ? theList[i][dat.rankList.fields[1]] : theList[i].areaha)} Ha</td>
        <td className="text-right nomobile">
          <button onClick={() => {
            single || !_hasGroup(activeTool) ? viewMap(theList[i].cid) :
            batch(()=>{
              setApp({type:'_APP',payload:['goSearch',name]})
              setApp({type:'_POPUP',payload:['popup',null]})
            })

          }} className="search-viewmap">{lang ? 'LIHAT' : 'VIEW'}</button>
        </td>
      </tr>
    ) : null
  }

  useEffect(()=>{
    if(dat.rankList && layers && popup === 'ranklist'){
      if(dat.rankList.type === 'alert'){
        axios.post(`${apiRPC}atlas_getrankalertlist`,{
          unit: layers[single?.id || activeTool].unit,
          datatype: single || !layers[activeTool].gUrl ? 'single' : 'group',
          daterange: `{${document.getElementById('glad-from').value},${document.getElementById('glad-to').value}}`,
          astatus: document.getElementById('glad-check').checked ? 'all' : 'confirmed'
        })
        .then((r)=>{
          if(r.data){
            if(r.data[0].arearank !== 1){
              r.data.reverse()
            }
            setList(r.data)
          }
        })
        .catch((e)=>{

        })
      }else if(dat.rankList.type === 'risk'){
        axios.post(apiRPC+'atlas_getrankprobdflist',{
          unit: layers[single?.id || activeTool].unit,
          datatype: single || !layers[activeTool].gUrl ? 'single' : 'group',
          probrange: `{${document.querySelector('#risk-slider .s-thumb-0').getAttribute('aria-valuenow')},${document.querySelector('#risk-slider .s-thumb-1').getAttribute('aria-valuenow')}}`,
        }).then(function(r){
          if(r.data){
            if(r.data[0].arearank !== 1){
              r.data.reverse()
            }
            setList(r.data)
          }
        });
      }else if(dat.rankList.type === 'ba'){
        axios.post(apiRPC+'atlas_getrankbalist',{
          unit: layers[single?.id || activeTool].unit,
          datatype: single || !layers[activeTool].gUrl ? 'single' : 'group',
          year: 2019
        }).then(function(r){
          if(r.data){
            if(r.data[0].arearank !== 1){
              r.data.reverse()
            }
            setList(r.data)
          }
        });
      }else{

        axios.get(
          `${apiP1}${ single ? layers[single.id].sRankT : (layers[activeTool].gRankT || layers[activeTool].sRankT)}?select=${
            single || !_hasGroup(activeTool) ? `cid,${_isABoundary(activeTool) ? activeTool : 'name'},` : 'groupcom,'
          }${dat.rankList.fields.concat()}&order=${dat.rankList.fields[0]}.desc`
        )
        .then(function(r){
          if(r.data){
            if(r.data[0][dat.rankList.fields[0]]!== 1){
              r.data.reverse()
            }
            setList(r.data)
          }
        });
      }
    }

    return () => {
      setPagination(d=>{
        d.page = 1
        d.list = []
        d.total = 0
      })
      setGladSort(d=>{
        d.open = false
        d.active = 'Total'
      })
    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[popup])

  useEffect(()=>{
    const total = theList.length
    if(total){
      setPagination(d=>{
        let i = (pagination.page - 1) * d.perPage,
        set = [], t = d.perPage * pagination.page
        while(i<t){
          if(i < total){
            set = [...set,i]
          }
          i++;
        }
        d.list = set
        d.total = total
      })
      if(innerRef.current){
        innerRef.current.scrollTop = 0
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[theList,pagination.page])

  return popup === 'ranklist' ? (
    <div ref={rankListRef} className="popup" style={{display:`${popup === 'ranklist' ? '' : 'none'}`,opacity:`${popup === 'ranklist' ? 1 : 0}`}}>
      <div role='presentation' className="popup-bg" onClick={()=>{
        closeThis(setApp,rankListRef)
      }}></div>
      <div>
        <div>
          <div className="popup-header">
            <div>Complete list</div>
            <div role='presentation' onClick={()=>closeThis(setApp,rankListRef)} className="cursor-pointer"><Times size="14"/></div>
          </div>
          <div ref={innerRef} className="popup-inner">

            {
              dat.rankList.type === 'alert' ? (
                <div className="sortable-rank">
                  <div id="sort-by-rank" className="sort-select">
                    <h5 role="presentation" id="sort-by-rank-label" onClick={()=>{
                      setGladSort(d=>{d.open = !gladSort.open})
                    }}>
                      Rank by:<span> {gladSort.active}</span><i className="r-sort-down"></i>
                    </h5>
                    {
                      gladSort.open ? (
                        <ul className="list-unstyled">
                          <li role="presentation" onClick={()=>{
                            setGladSort(d=>{
                              d.active = 'Total'
                              d.open = false
                            })
                            _sortGladRank({
                              unit: layers[single?.id || activeTool].unit,
                              datatype: single || !layers[activeTool].gUrl ? 'single' : 'group',
                              c:'all',
                              setList,apiRPC
                            })
                          }} className="r-sort active">
                            <span>Total</span><span><i className="fa fa-dot-circle-o"></i></span>
                          </li>
                          <li role="presentation" onClick={()=>{
                            setGladSort(d=>{
                              d.active = 'Forest loss'
                              d.open = false
                            })
                            _sortGladRank({
                              unit: layers[single?.id || activeTool].unit,
                              datatype: single || !layers[activeTool].gUrl ? 'single' : 'group',
                              c:'forest',
                              setList,apiRPC
                            })
                          }} className="r-sort">
                            <span>Forest loss</span><span><i className="fa fa-circle-o"></i></span>
                          </li>
                          <li role="presentation" onClick={()=>{
                            setGladSort(d=>{
                              d.active = 'Tree loss in non-forest'
                              d.open = false
                            })
                            _sortGladRank({
                              unit: layers[single?.id || activeTool].unit,
                              datatype: single || !layers[activeTool].gUrl ? 'single' : 'group',
                              c:'nonforest',
                              setList,apiRPC
                            })
                          }} className="r-sort">
                            <span>Tree loss in non-forest</span><span><i className="fa fa-circle-o"></i></span>
                          </li>
                        </ul>
                      ) : null
                    }
                  </div>
                </div>
              ) : null
            }

            {
              pagination.list.length ? (
                <>
                <table className="table table-ranklist">
                  <thead>
                    <tr>
                      <th className="text-center" style={{width:50}}>Rank</th><th>Name</th><th className="text-right" style={{width:125}}>Area</th>
                      <th style={{width:62}} className="text-right nomobile"></th>{/*eslint-disable-line jsx-a11y/control-has-associated-label*/}
                    </tr>
                  </thead>
                  <tbody>
                    { pagination.list.map(i=>{
                      return <TheRankList key={i} i={i} page={pagination.page} total={theList.length}/>
                    })}
                  </tbody>
                </table>
                {
                  pagination.list.length < pagination.total ? (
                    <div className="pagination">
                      {pagination.page > 1 ? (<><span role="presentation" onClick={()=>{
                        setPagination(d=>{
                          d.page = pagination.page - 1
                        })
                      }}>« Previous</span>&nbsp;&nbsp;</>) : null}
                      {pagination.page > 1 && (pagination.page * pagination.perPage) < pagination.total ? (<>&nbsp;&nbsp;</>) : ''}
                      {(pagination.page * pagination.perPage) < pagination.total ? (<span role="presentation" onClick={()=>{
                        setPagination(d=>{
                          d.page = pagination.page + 1
                        })
                      }} id="np">Next page »</span>) : null}
                    </div>
                  ) : null
                }
                </>
              ) : null
            }
          </div>
        </div>
      </div>
    </div>
  ) : null
}
export default RankList
