const inits = {
  aoiLayerResult: !1,
}

const AoiReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_AOI':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default AoiReducer
