import React from 'react'
import {useSelector} from 'react-redux'

const AppLoader = () => {

  const appLoader = useSelector(s=>s.loader.appLoader)
  const {lang} = useSelector(s=>s.lang)

  return (
    <div id="loading" style={{display:`${appLoader ? '' : 'none'}`}}><table className="width-100 height-100"><tbody><tr><td className="vertical-middle text-center">
      {
        appLoader ? (
          <>
            <div className="lds-ripple"><div></div><div></div></div><h3 id="gl-loading">{lang ? 'SEDANG MEMUAT' : 'LOADING'}</h3>
          </>
        ) : null
      }
    </td></tr></tbody></table></div>
  )
}

export default AppLoader
