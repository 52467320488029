import React, {useEffect} from 'react'
import {useImmer} from 'use-immer'
import {useSelector,useDispatch,batch} from 'react-redux'
import axios from 'axios'
import ReactSlider from 'react-slider'
import {InfoCircle} from '@styled-icons/fa-solid'
import TabHead from './TabHead'
import rippleGIF from '../../images/ripple.gif'

import {_f1Dec} from '../../helpers'
import config from '../../config'

const showInfo = (setApp,url) => {
  batch(()=>{
    setApp({type:'_POPUP',payload:['popup','popupinfo']})
    setApp({type:'_POPUP',payload:['url',url]})
  })
}

function getRiskRank({app,layers,setRiskRank,activeTool}) {
  if(typeof document !== undefined){

    axios.post(config.apiRPC+'atlas_getrankprobdf',{
      unit: layers[app.single ? app.single.id : activeTool].unit,
      datatype: app.single || !layers[activeTool].gUrl? 'single' : 'group',
      probrange: `{${document.querySelector('#risk-slider .s-thumb-0').getAttribute('aria-valuenow')},${document.querySelector('#risk-slider .s-thumb-1').getAttribute('aria-valuenow')}}`,
      id: app.single ? app.single.properties.cid : (layers[activeTool].gUrl ? app.goSearch : app.cIDs[0])
    }).then(function(r){
      if(r.data.length){
        setRiskRank(r.data[0]);
      }else{
        setRiskRank(0)
      }
    }).catch(()=>{
      setRiskRank(0)
    });

  }
}

function thisArea(d) {
  const from = document.querySelector('#risk-slider .s-thumb-0').getAttribute('aria-valuenow')
  const to = document.querySelector('#risk-slider .s-thumb-1').getAttribute('aria-valuenow')
  let i = d.length - 1,
  rArea = 0;
  while (i>=0) {
    if( d[i].prob >= from && d[i].prob <= to){
      rArea = rArea + d[i].areaha;
    }
    i--;
  }
  return _f1Dec(rArea)
}

const RiskSum = ({props}) => {

  const {sumT,riskRank,lang,setApp} = props

  return (
    <div className="rank-table">
      <div style={{width:"65%"}}>
        <span className="res-value">{sumT}</span><span className="res-ha"> Ha</span>
      </div>
      {
        riskRank ? (
          <div style={{borderLeft:'1px solid #ccc',paddingLeft:10}}>
            <div className="see-rank">Top {riskRank.arearank} of {riskRank.totalcount}
              <small role="presentation" onClick={()=>{
                batch(()=>{
                  setApp({type:'_DATA',payload:['rankList',{
                    type:'risk',
                    rank: riskRank.arearank
                  }]})
                  setApp({type:'_POPUP',payload:['popup','ranklist']})
                })
              }}>{lang ? 'Lihat semuanya' : 'View complete list'}</small>
            </div>
          </div>
        ) : null
      }

    </div>
  )
}

const RiskTab = () => {

  const setApp = useDispatch()
  const app = useSelector(s=>s.app)
  const {mapLoader} = useSelector(s=>s.loader)
  const {activeTool} = useSelector(s=>s.tool)
  const {activeTab} = useSelector(s=>s.tab)
  const {rLang,lang} = useSelector(s=>s.lang)
  const {riskTabData,featData} = useSelector(s=>s.dat)
  const {layers,riskRaster} = useSelector(s=>s.layers)
  const risk = useSelector(s=>s.risk)
  let show = activeTab === 'risk' ? 1 : !1

  const [sumT,setSumT] = useImmer(0)
  const [riskRank,setRiskRank] = useImmer(0)

  useEffect(()=>{
    if(riskTabData.group || riskTabData.single){
      setSumT(thisArea(riskTabData.single?.data || riskTabData.group.data))
      if(app.single || app.goSearch){
        getRiskRank({app,layers,setRiskRank,activeTool})
      }
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[riskTabData.group,riskTabData.single])

  return (!featData.single && !featData.group) ? null : (
    <div className="tab-pane" style={{display:`${show && !mapLoader ? 'block' : 'none'}`,height:activeTool.indexOf('aoi') === 0 ? 'calc(100vh - 175px)' : ''}} id="risk-panel">
      <h2 className="tab-label">{rLang.TabRiskHead[lang]}</h2>
      <TabHead/>
      <div className="sbbody">
        {
          !riskTabData.group && !riskTabData.single ? (<div><img width="30" height="30" alt="" src={rippleGIF}/> <i>Loading . . .</i></div>) : (
            <>
            <h3 className="sbbody-title">
              {lang === 0 ? 'Probability of deforestation' : 'Probabilitas kehilangan hutan'}
              {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                showInfo(setApp,`popuprisk/i0`)
              }}><InfoCircle size="12"/></b>
            </h3>
            <div id="risk-slider">
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="s-thumb"
                trackClassName="s-track"
                min={0}
                max={100}
                value={[risk.from,risk.to]}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={t => `Thumb value ${t.valueNow}`}
                renderThumb={(props, t) => <div {...props}><b>{t.valueNow/100}</b></div>}
                pearling
                minDistance={1}
                onChange={(e)=>{
                  riskRaster.changed()
                }}
                onAfterChange={(d)=>{
                  setApp({type:'SET_RISK_STATE',payload:{
                    from: d[0],
                    to: d[1]
                  }})
                  if(app.single || app.goSearch){
                    getRiskRank({app,layers,setRiskRank,activeTool})
                  }
                  setSumT(thisArea(riskTabData.single?.data || riskTabData.group.data))
                }}
              />
            </div>

            <div id="risk-sum" className="concession-summary">
              <RiskSum props={{sumT,riskRank,lang,setApp}}/>
            </div>
            </>
          )
        }
      </div>
    </div>
  )
}
export default RiskTab
