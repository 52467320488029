const inits = {
  featData: {
    group: null,
    single: null,
  },
  gladTabData: {
    group: null,
    single: null
  },
  tsTabData: {
    group: null,
    single: null
  },
  statTabData: {
    group: null,
    single: null
  },
  riskTabData: {
    group: null,
    single: null
  },
  _73TabData: {
    group: null,
    single: null
  }
}

const DataReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_DATA':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_FEAT_DATA':
      return (payload.single) ? { ...s,
        featData: {...s.featData,single:payload.single}
      }:{ ...s,
        featData: {...s.featData,group:payload.group}
      }

    case 'SET_GLAD_DATA':
      return (payload.single) ? { ...s,
        gladTabData: {...s.gladTabData,single:payload.single}
      }:{ ...s,
        gladTabData: {...s.gladTabData,group:payload.group}
      }

    case 'SET_TS_DATA':
      return (payload.single) ? { ...s,
        tsTabData: {...s.tsTabData,single:payload.single}
      }:{ ...s,
        tsTabData: {...s.tsTabData,group:payload.group}
      }

    case 'SET_STAT_DATA':
      return (payload.single) ? { ...s,
        statTabData: {...s.statTabData,single:payload.single}
      }:{ ...s,
        statTabData: {...s.statTabData,group:payload.group}
      }

    case 'SET_73_DATA':
      return (payload.single) ? { ...s,
        _73TabData: {...s._73TabData,single:payload.single}
      }:{ ...s,
        _73TabData: {...s._73TabData,group:payload.group}
      }

    case 'SET_RISK_DATA':
      return (payload.single) ? { ...s,
        riskTabData: {...s.riskTabData,single:payload.single,rank:payload.rank}
      }:{ ...s,
        riskTabData: {...s.riskTabData,group:payload.group,rank:payload.rank}
      }

    case 'SET_SORT_DATA':
      return { ...s,
        sortData: {...s.sortData,data:payload[0],state:payload[1]}
      }

    case 'SET_SORT_STATE':
      return { ...s,
        sortData: {...s.sortData,state:payload}
      }

    case 'RESET_SINGLE_DATA':
    case 'INIT_SINGLE_DATA':
      return { ...s,
        featData: {...s.featData,single:null},
        gladTabData: {...s.gladTabData,single:null},
        tsTabData: {...s.tsTabData,single:null},
        statTabData: {...s.statTabData,single:null},
        riskTabData: {...s.riskTabData,single:null},
        _73TabData: {...s._73TabData,single:null}
      }

    case 'OPEN_ADVANCED_SEARCH':
     return { ...s,
       featData: {group: null,single: null},
       gladTabData: {group: null,single: null},
       tsTabData: {group: null,single: null},
       statTabData: {group: null,single: null},
       riskTabData: {group: null,single: null},
       _73TabData: {group: null,single: null},
     }

    case 'INIT_DATA':
      return {...s,
        featData: { ...s.featData,
          group: null,single: null
        },
        gladTabData: { ...s.gladTabData,
          group: null,single: null
        },
        tsTabData: { ...s.tsTabData,
          group: null,single: null
        },
        statTabData: { ...s.statTabData,
          group: null,single: null
        },
        riskTabData: { ...s.riskTabData,
          group: null,single: null
        },
        _73TabData: { ...s._73TabData,
          group: null,single: null
        }
      }
    default:
      return s;
  }
};
export default DataReducer
