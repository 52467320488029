import React from 'react'

import TabMenu from '../menus/TabMenu'
import LayersTab from './LayersTab'
import GladTab from './GladTab'
import TsTab from './TsTab'
import StatTab from './StatTab'
import S73Tab from './S73Tab'
import RiskTab from './RiskTab'

const Tabs = () => {

    return (
      <div id="sb-tabs-block">
        <div id="sb-tabs">
          <TabMenu/>
        </div>

        <div id="sb-tabs-panel">
          <div id="tab-content" className="tab-content">
            <LayersTab/>
            <GladTab/>
            <TsTab/>
            <StatTab/>
            <RiskTab/>
            <S73Tab/>
          </div>
        </div>
      </div>
    )
}
export default Tabs
