import GeoJSON from 'ol/format/GeoJSON';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import {Fill, Stroke, Style} from 'ol/style';
import * as extent from 'ol/extent'

import {getStatData} from './getStatData'
import config from '../config'
import {togglePin} from '../helpers'

const styles = {
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'yellow',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0.1)',
    }),
  }),
  'Polygon': new Style({
    fill: new Fill({color: 'rgba(255, 255, 255, 0)'}),
    stroke: new Stroke({color: 'rgba(255, 247, 0, 1)',width: 3})
  })
};

const styleFunction = function (feature) {
  return styles[feature.getGeometry().getType()];
};

export default function aoiResult({defaultLayers,layers,map,aoi}){
  return (dispatch) => {
    dispatch({type:'_TOOL',payload:['activeTool','aoi']})
    dispatch({type:'_TAB',payload:['activeTab','glad']})
    dispatch({type:'SET_START_LAYERS',payload: defaultLayers.default.concat(defaultLayers['aoi'])})
    dispatch({type:"_APP",payload:['sgs','stat']})

    dispatch({type:'SET_FEAT_DATA',payload:{
      group : {
        areaha: 0,
        id:'aoi',
        featTitle:'AOI',
        sProp: ''
      }
    }})

    dispatch({type:'SET_GLAD_DATA',payload: {group:{data:aoi.glad,title:''}}})
    dispatch(getStatData({apiRPC:config.apiRPC,single:!1,sgs:'stat',aoi}))
    dispatch({type:'SET_RISK_DATA',payload: {group:{data:aoi.probdf}}})
    dispatch({type:'SET_TS_DATA',payload:{group:{
      df:{data:aoi.df},ip:{data:aoi.ip},rd:{data:aoi.rd}
    }}})


    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(aoi.sp),
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: styleFunction,
      name: 'aoiResult'
    });

    map.addLayer(vectorLayer)
    vectorLayer.setZIndex(800)

    dispatch({type:'_AOI',payload:['aoiLayerResult',vectorLayer]})
    dispatch({type:'_LOADER',payload:['appLoader',false]})
    togglePin(map,1)

    setTimeout(()=>{
      map.getView().animate({
        center: extent.getCenter(vectorSource.getExtent()),
        zoom: map.getView().getZoomForResolution(map.getView().getResolutionForExtent(vectorSource.getExtent())) - 0.5,
        duration: 1500
      });
    },260)

  }



}
