const inits = {
  map: null
}

const MapReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_MAP':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default MapReducer
