const inits = {
  from:60,
  to:100
}

const RiskReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_RISK':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_RISK_STATE':
      return { ...s,...payload}

    default:
      return s;
  }
};
export default RiskReducer
