const inits = {
  bMap: 'World_Dark_Gray_Base',
  list: [
    'World_Dark_Gray_Base',
    'World_Light_Gray_Base',
    'World_Imagery',
    'World_Topo_Map',
    'NatGeo_World_Map',
    'World_Terrain_Base',
    'World_Street_Map',
    'OSM'
  ],
  baseMaps: {
    World_Dark_Gray_Base: {
      a: "Tiles © <a rel='noopener' href='https://services.arcgisonline.com/ArcGIS/Canvas/World_Dark_Gray_Base/MapServer'>ArcGIS</a>",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}",
    },
    World_Light_Gray_Base: {
      a: "Tiles © <a rel='noopener' href='https://services.arcgisonline.com/ArcGIS/Canvas/World_Light_Gray_Base/MapServer'>ArcGIS</a>",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
    },
    World_Imagery: {
      a: "Tiles © <a rel='noopener' href='https://services.arcgisonline.com/ArcGIS/World_Imagery/MapServer'>ArcGIS</a>",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    },
    World_Topo_Map: {
      a: "Tiles © <a rel='noopener' href='https://services.arcgisonline.com/ArcGIS/World_Topo_Map/MapServer'>ArcGIS</a>",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
    },
    NatGeo_World_Map: {
      a: "Tiles © <a rel='noopener' href='https://services.arcgisonline.com/ArcGIS/NatGeo_World_Map/MapServer'>ArcGIS</a>",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
    },
    World_Terrain_Base: {
      a: "Tiles © <a rel='noopener' href='https://services.arcgisonline.com/ArcGIS/World_Terrain_Base/MapServer'>ArcGIS</a>",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}"
    },
    World_Street_Map: {
      a: "Tiles © <a rel='noopener' href='https://services.arcgisonline.com/ArcGIS/World_Street_Map/MapServer'>ArcGIS</a>",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
    },
    OSM: {
      type: "OSM",
    }
  }
}

const BaseMapReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_BASEMAP':
      return { ...s, bMap: payload }

    default:
      return s;
  }
};
export default BaseMapReducer
