const inits = {
  tool:null,
  bg: ''
}

const ToolScreenReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_TOOL_SCREEN':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_TOOL_SCREEN':
      return { ...s, ...payload }

    default:
      return s;
  }
};
export default ToolScreenReducer
