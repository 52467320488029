import React,{useEffect} from 'react'
import {useSelector} from 'react-redux'
import $ from 'jquery'
import ReactDOM from 'react-dom'
import C3Chart from 'react-c3js'

import {_f1Dec,_gcData} from '../../helpers'

export const SbBarChart = ({d,chartID,unit,stacked,dl}) => {

  const {y,startYear,endYear,ycArray,cCats} = useSelector(s=>s.ts)
  const {lang} = useSelector(s=>s.lang)
  const f = dl.featData.single || dl.featData.group

  useEffect(() => {
    if(d && typeof document !== undefined){
      const yA = ycArray
      const sY = startYear
      const eY = endYear
      let barID = parseInt(y.toString().substr(2,2)) - 1,
      dsoil = 0,
      dmang = 0,
      dpeat = 0,
      dnonf = 0,
      def = [].concat(yA),
      cols = [],
      gc = !1

      const colors = {
        "Total forest lost": 'rgba(170,255,0,0.4)',
        "Forest on mineral soils lost": 'rgba(38,115,0,0.4)',
        "Mangrove forest lost": 'rgba(48,255,169,0.4)',
        "Peat-swamp forest lost": 'rgba(68,137,112,0.4)',
        "Expansion of other roads": 'rgba(255,255,255,0.4)',
        "Expansion of main roads": 'rgba(255,255,255,0.4)',
        "Expansion of plantation roads": 'rgba(255,255,255,0.4)',
        "Expansion of logging roads": 'rgba(255,255,255,0.4)',
        "Forest in mineral soil": 'rgba(38,115,0,0.4)',
        "Mangrove forest": 'rgba(48,255,169,0.4)',
        "Peat-swamp forest": 'rgba(68,137,112,0.4)',
        "Non-forest": 'rgba(215,215,158,0.4)'
      }

      if(chartID === 'sb-total-forest'){

        let i = 0,
        t = d.df.data.length - 1;
        while(i<t){
          var j = endYear;
          while (j >= startYear ) {
            if(j === d.df.data[i].year){
              if(d.df.data[i].class === 'Forest on mineral soil loss' || d.df.data[i].class === 'Mangrove forest loss' || d.df.data[i].class === 'Peat swamp forest loss'){
                def[parseInt(j.toString().substr(2,2)) - 1] += d.df.data[i].areaha;
              }
            }
            j--;
          }
          i++;
        }
        cols = ["Total forest lost"].concat(def)
      }

      if(chartID === 'sb-soil'){
        gc = _gcData({d:d.df.data,yA,sY,eY,c:'Forest on mineral soil loss'});
        cols = ['Forest on mineral soils lost'].concat(gc)
      }
      if(chartID === 'sb-mangrove'){
        gc = _gcData({d:d.df.data,yA,sY,eY,c:'Mangrove forest lost'});
        cols = ['Mangrove forest lost'].concat(gc)
      }
      if(chartID === 'sb-peat'){
        gc = _gcData({d:d.df.data,yA,sY,eY,c:'Peat swamp forest loss'});
        cols = ['Peat-swamp forest lost'].concat(gc)
      }

      if(chartID === 'sb-oilpalm'){
        let soil = _gcData({d:d.ip.data,yA,sY,eY,c:'Forest on mineral soil to oilpalm'}),
        mang = _gcData({d:d.ip.data,yA,sY,eY,c:'Mangrove forest to oilpalm'}),
        peat = _gcData({d:d.ip.data,yA,sY,eY,c:'Peat swamp forest to oilpalm'}),
        nonf = _gcData({d:d.ip.data,yA,sY,eY,c:'Non-forest to oilpalm'})
        dsoil= ["Forest in mineral soil"].concat(soil)
        dmang= ["Mangrove forest"].concat(mang)
        dpeat= ["Peat-swamp forest"].concat(peat)
        dnonf= ["Non-forest"].concat(nonf)
      }

      if(chartID === 'sb-pulpwood'){
        let soil = _gcData({d:d.ip.data,yA,sY,eY,c:'Forest on mineral soil to pulpwood'}),
        mang = _gcData({d:d.ip.data,yA,sY,eY,c:'Mangrove forest to pulpwood'}),
        peat = _gcData({d:d.ip.data,yA,sY,eY,c:'Peat swamp forest to pulpwood'}),
        nonf = _gcData({d:d.ip.data,yA,sY,eY,c:'Non-forest to pulpwood'})
        dsoil= ["Forest in mineral soil"].concat(soil)
        dmang= ["Mangrove forest"].concat(mang)
        dpeat= ["Peat-swamp forest"].concat(peat)
        dnonf= ["Non-forest"].concat(nonf)
      }

      if(chartID === 'sb-smallholder'){
        let soil = _gcData({d:d.ip.data,yA,sY,eY,c:'Forest on mineral soil to smallholder'}),
        mang = _gcData({d:d.ip.data,yA,sY,eY,c:'Mangrove forest to smallholder'}),
        peat = _gcData({d:d.ip.data,yA,sY,eY,c:'Peat swamp forest to smallholder'}),
        nonf = _gcData({d:d.ip.data,yA,sY,eY,c:'Non-forest to smallholder'})
        dsoil= ["Forest in mineral soil"].concat(soil)
        dmang= ["Mangrove forest"].concat(mang)
        dpeat= ["Peat-swamp forest"].concat(peat)
        dnonf= ["Non-forest"].concat(nonf)
      }

      if(chartID === 'sb-mining'){
        let soil = _gcData({d:d.ip.data,yA,sY,eY,c:'Forest on mineral soil to mining'}),
        mang = _gcData({d:d.ip.data,yA,sY,eY,c:'Mangrove forest to mining'}),
        peat = _gcData({d:d.ip.data,yA,sY,eY,c:'Peat swamp forest to mining'}),
        nonf = _gcData({d:d.ip.data,yA,sY,eY,c:'Non-forest to mining'})
        dsoil= ["Forest in mineral soil"].concat(soil)
        dmang= ["Mangrove forest"].concat(mang)
        dpeat= ["Peat-swamp forest"].concat(peat)
        dnonf= ["Non-forest"].concat(nonf)
      }

      if(chartID === 'sb-mainRoad'){
        gc = _gcData({d:d.rd.data,yA,sY,eY,c:'Main road'});
        cols = ['Expansion of main roads'].concat(gc)
      }

      if(chartID === 'sb-otherRoad'){
        gc = _gcData({d:d.rd.data,yA,sY,eY,c:'Other road'});
        cols = ['Expansion of other roads'].concat(gc)
      }

      if(chartID === 'sb-plaRoad'){
        gc = _gcData({d:d.rd.data,yA,sY,eY,c:'Plantation road'});
        cols = ['Expansion of plantation roads'].concat(gc)
      }

      if(chartID === 'sb-logRoad'){
        gc = _gcData({d:d.rd.data,yA,sY,eY,c:'Logging road'});
        cols = ['Expansion of logging roads'].concat(gc)
      }


      let data=!1
      if(!stacked){
        data = {
          columns: [cols],
          type: 'bar',
          colors: colors,
          color: function (color, d) {
            return d.x === barID ? color.replace(',0.5)',',1)') : color;
          },
          order: null
        }
      }else{
        cols = [dsoil,dmang,dpeat,dnonf]
        const grp = ["Forest in mineral soil","Mangrove forest","Peat-swamp forest","Non-forest"];

        data = {
          columns: cols,
          type: 'bar',groups: [grp],
          order: null,
          colors: colors
        }
      }

      const grid = {y: {show: true}}

      const tooltip = {
        format: {
          value: function (v, ratio, id, index) {
            return (_f1Dec(v) + (unit ? ` ${unit}` : ' Ha'));
            // return (value > 1 ? f(Math.round(value)) : f(value.toFixed(2))) + (o.unit ? ' ' + o.unit : ' Ha');
          }
        }
      }
      const axis = {
        x: {
          type: 'category',
          categories: cCats,
          tick: {
            color: '#fff',
            multiline: false
          },
          height: 50
        },
        /*y: {tick:{format: d3.format(".1s")}}*/
        y: {show: false}
      }

      const legend = {
        position: 'bottom'
      }

      // const size = {width: is_mobile() ? "100%" : 332, height:200}
      const size = {height:280}

      ReactDOM.render(<C3Chart
        data={data}
        tooltip={tooltip}
        axis={axis}
        grid={grid}
        legend={legend}
        size={size}
        padding={{
          right: 24,
          left:24
        }}
        />, document.getElementById(chartID));

      setTimeout(() => {
        $('#sb-slide-chart .c3-target-Total-forest-lost path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(170,255,0,1)'});
        $('#sb-slide-chart .c3-target-Forest-on-mineral-soils-lost path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(38,115,0,1)'});
        $('#sb-slide-chart .c3-target-Mangrove-forest-lost path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(48,255,169,1)'});
        $('#sb-slide-chart .c3-target-Peat-swamp-forest-lost path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(68,137,112,1)'});
        $('#sb-slide-chart .c3-target-Expansion-of-other-roads path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(255,255,255,1)'});
        $('#sb-slide-chart .c3-target-Expansion-of-main-roads path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(255,255,255,1)'});
        $('#sb-slide-chart .c3-target-Expansion-of-plantation-roads path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(255,255,255,1)'});
        $('#sb-slide-chart .c3-target-Expansion-of-logging-roads path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(255,255,255,1)'});
        $('#sb-slide-chart .c3-target-Forest-in-mineral-soil path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(38,115,0,1)'});
        $('#sb-slide-chart .c3-target-Non-forest path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(225,225,225,1)'});
        $('#sb-slide-chart .c3-target-Mangrove-forest path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(48,255,169,1)'});
        $('#sb-slide-chart .c3-target-Peat-swamp-forest path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(68,137,112,1)'});

        $('#sb-slide-chart .c3-legend-item-tile').each(function(){
          $(this).css('stroke',$(this).css('stroke').replace('0.4)','1)'))
        })
        $('#sb-slide-chart .c3-tooltip .name > span').each(function(){
          $(this).css('background-color',$(this).css('background-color').replace(')','1)'))
        })
      },450)
      $(window).resize(()=>{
        setTimeout(() => {
          $('#sb-slide-chart .c3-target-Total-forest-lost path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(170,255,0,1)'});
          $('#sb-slide-chart .c3-target-Forest-on-mineral-soils-lost path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(38,115,0,1)'});
          $('#sb-slide-chart .c3-target-Mangrove-forest-lost path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(48,255,169,1)'});
          $('#sb-slide-chart .c3-target-Peat-swamp-forest-lost path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(68,137,112,1)'});
          $('#sb-slide-chart .c3-target-Expansion-of-other-roads path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(255,255,255,1)'});
          $('#sb-slide-chart .c3-target-Expansion-of-main-roads path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(255,255,255,1)'});
          $('#sb-slide-chart .c3-target-Expansion-of-plantation-roads path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(255,255,255,1)'});
          $('#sb-slide-chart .c3-target-Expansion-of-logging-roads path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(255,255,255,1)'});
          $('#sb-slide-chart .c3-target-Forest-in-mineral-soil path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(38,115,0,1)'});
          $('#sb-slide-chart .c3-target-Non-forest path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(225,225,225,1)'});
          $('#sb-slide-chart .c3-target-Mangrove-forest path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(48,255,169,1)'});
          $('#sb-slide-chart .c3-target-Peat-swamp-forest path.c3-shape.c3-bar.c3-bar-'+barID).css({fill:'rgba(68,137,112,1)'});

          $('#sb-slide-chart .c3-legend-item-tile').each(function(){
            $(this).css('stroke',$(this).css('stroke').replace('0.4)','1)'))
          })
          $('#sb-slide-chart .c3-tooltip .name span').each(function(){
            $(this).css('background-color',$(this).css('background-color').replace('0.4)','1)'))
          })
        },450)
      })

      let dataDl = {
        "data": [
            [dl.sgs === 'stat' ?  'Statistics of result' : f.featTitle[lang]],
            [(f.sProp ? f.sProp : (dl.sgs === 'stat' ? 'Statistics of result' : (dl.single ? f.name : dl.goSearch)))],
            [],
            [dl.cInfo],
            [],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]
        ],
        "title": dl.sgs === 'stat' ?  'Statistics of result' : f.featTitle[lang],
        "chartTitle": dl.sgs === 'stat' ? 'Statistics of result' : (dl.single ? f.name : dl.goSearch),
        "sProp": f.sProp ? f.sProp : (dl.sgs === 'stat' ? 'Statistics of result' : (dl.single ? f.name : dl.goSearch))
      },
      ii = startYear,
      n = 1

      if(stacked){
        dataDl.data = [...dataDl.data,['',"Forest in mineral soil (Ha)","Mangrove forest (Ha)","Peat-swamp forest (Ha)","Non-forest (Ha)"]]
        while(ii <= endYear){
          dataDl.data = [...dataDl.data,[`${ii}`,dsoil[n],dmang[n],dpeat[n],dnonf[n]]]
          ii++;
          n++
        }

      }else{
        dataDl.data = [...dataDl.data,['',cols[0]+' (Ha)']]
        ii = startYear
        n = 1
        while(ii <= endYear){
          dataDl.data = [...dataDl.data,[`${ii}`,cols[n]]]

          ii++;
          n++
        }
      }
      document.getElementById(chartID).setAttribute('download_data',encodeURIComponent(JSON.stringify(dataDl)))
    }

    return () => {
      if(typeof document !== undefined){

      }

    }

  },/*eslint-disable-line react-hooks/exhaustive-deps*/[d,y])

  return (<div id={chartID}/>)
}
export default SbBarChart
