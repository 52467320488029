import React, {useEffect,useState} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import ReactDOM from 'react-dom'
import C3Chart from 'react-c3js';
import axios from 'axios'
import moment from 'moment'
import Slider from "react-slick";
import {ChevronForward, ChevronBack} from '@styled-icons/ionicons-outline'
import {InfoCircle} from '@styled-icons/fa-solid'
import ReactSlider from 'react-slider'
import DatePicker from "react-datepicker";
import parseISO from 'date-fns/parseISO'
import Collapse from "@kunukn/react-collapse";

import TabHead from './TabHead'

import rippleGIF from '../../images/ripple.gif'

import {DateToJD,JDtoDate,_f1Dec,_1Dec} from '../../helpers'
import config from '../../config'

const showInfo = (setApp,url) => {
  batch(()=>{
    setApp({type:'_POPUP',payload:['popup','popupinfo']})
    setApp({type:'_POPUP',payload:['url',url]})
  })
}

function getGladRank({app,layers,setGladRank,activeTool}) {
  if(typeof document !== undefined){
    axios.post(config.apiRPC+'atlas_getrankalert',{
      unit: layers[app.single ? app.single.id : activeTool].unit,
      datatype: app.single || !layers[activeTool].gUrl? 'single' : 'group',
      alert:'glad',
      daterange: '{'+document.getElementById('glad-from').value+','+document.getElementById('glad-to').value+'}',
      astatus: document.getElementById('glad-check').checked ? 'all' : 'confirmed',
      id: app.single ? app.single.properties.cid : (layers[activeTool].gUrl ? app.goSearch : app.cIDs[0])
    }).then(function(r){
      if(r.data.length){
        setGladRank(r.data[0]);
      }else{
        setGladRank(0)
      }
    }).catch(()=>{
      setGladRank(0)
    });
  }
}

const setGladChart = ({app,gladStartDate,gladEndDate,layers,setGladRank,activeTool,setSumT,gladTabData}) =>{
  if(gladTabData.single || gladTabData.group){
      let allData = [],
      weeks = [],
      d = gladTabData.single?.data || gladTabData.group.data,
      forest = ['Forest'],
      nonForest = ['Non-forest'],
      mineral = ['Forest on mineral'],
      mangrove = ['Mangrove forest'],
      peat = ['Peat-swamp forest'],
      totalAlerts = [0,0,0,0,0,0,0,0,0,0,0];

      const unconfirmed = document.getElementById('glad-check').checked;


      /* get & set category */
      let dt = new Date(gladStartDate)
      const dte = new Date(gladEndDate)

      for (dt; dt <= dte; dt.setDate(dt.getDate() + 1)) {
        var m = moment(dt);
        if(m.format('dddd') === 'Monday'){
          weeks.push(m.format('YYYY-MM-DD'));
        }
      }

      /* allData Chart*/
      var z = 8;
      while(z>=0){

        let i = 0, t = weeks.length,
        lC = ''

        switch(z){
          case 8:
            lC = 'IOPP'
            break;
          case 7:
            lC = 'ITP'
            break;
          case 6:
            lC = 'Smallholder'
            break;
          case 5:
            lC = 'Mining'
            break;
          case 4:
            lC = 'Transmigration'
            break;
          case 3:
            lC = 'Non-forest'
            break;
          case 2:
            lC = 'Forest on mineral'
            break;
          case 1:
            lC = 'Mangrove forest'
            break;
          case 0:
            lC = 'Peat swamp forest'
            break;
          default:
            lC = '';break;
        }

        while (i<t) {
          var ii = d.length - 1,
          sumForest = 0,
          sumNonForest = 0;
          while(ii>=0){
            var a = d[ii];

              if(z === 3){
                if(a.lctype !== 'Non-forest'
                  && a.lctype !== 'IOPP'
                  && a.lctype !== 'ITP'
                  && a.lctype !== 'Smallholder'
                  && a.lctype !== 'Mining'
                  && a.lctype !== 'Transmigration'
                ){
                  if(moment(a.wdate).format('YYYY-MM-DD') === weeks[i]){
                    if(!unconfirmed && a.astatus === 3){
                      sumForest += a.areaha;
                    }else if(unconfirmed){
                      sumForest += a.areaha;
                    }
                  }
                }
              }
              if(z >= 3){

                if(a.lctype === lC){
                  if(moment(a.wdate).format('YYYY-MM-DD') === weeks[i]){
                    let index = 5
                    if(lC === 'IOPP'){index = 5}
                    if(lC === 'ITP'){index = 6}
                    if(lC === 'Smallholder'){index = 7}
                    if(lC === 'Mining'){index = 8}
                    if(lC === 'Transmigration'){index = 9}
                    if(lC === 'Non-forest'){index = 10}

                    if(!unconfirmed && a.astatus === 3){
                      sumNonForest += a.areaha;

                      totalAlerts[index] += a.areaha;

                    }else if(unconfirmed){
                      sumNonForest += a.areaha;
                      totalAlerts[index] += a.areaha;
                    }
                  }
                }
              }else{

                if(a.lctype === lC){
                  if(moment(a.wdate).format('YYYY-MM-DD') === weeks[i]){
                    if(!unconfirmed && a.astatus === 3){
                      sumNonForest += a.areaha;
                    }else if(unconfirmed){
                      sumNonForest += a.areaha;
                    }
                  }
                }
              }

            ii--;
          }

          if(z >= 3){
            forest[i+1] = forest[i+1] ? forest[i+1] + sumForest : sumForest;
            nonForest[i+1] = nonForest[i+1] ? nonForest[i+1] + sumNonForest : sumNonForest;
            allData[0] = {
              cols: [forest,nonForest],
              grp: [forest[0],nonForest[0]],
              cats: weeks
            };
          }
          if(z===2){
            mineral[i+1] = sumNonForest;
            allData[1] = {
              cols: [mineral],
              grp: [mineral[0]],
              cats: weeks
            };
            totalAlerts[1] = totalAlerts[1] + sumNonForest;
          }
          if(z===1){
            mangrove[i+1] = sumNonForest;
            allData[2] = {
              cols: [mangrove],
              grp: [mangrove[0]],
              cats: weeks
            };
            totalAlerts[2] = totalAlerts[2] + sumNonForest;
          }
          if(z===0){
            peat[i+1] = sumNonForest;
            allData[3] = {
              cols: [peat],
              grp: [peat[0]],
              cats: weeks
            };
            totalAlerts[3] = totalAlerts[3] + sumNonForest;
          }

          i++;
        }

        z--;
      }
      setSumT([
        _f1Dec(
          parseFloat(_1Dec(totalAlerts[1]))
          + parseFloat(_1Dec(totalAlerts[2]))
          + parseFloat(_1Dec(totalAlerts[3]))
          + parseFloat(_1Dec(totalAlerts[5]))
          + parseFloat(_1Dec(totalAlerts[6]))
          + parseFloat(_1Dec(totalAlerts[7]))
          + parseFloat(_1Dec(totalAlerts[8]))
          + parseFloat(_1Dec(totalAlerts[9]))
          + parseFloat(_1Dec(totalAlerts[10]))),
        _f1Dec(totalAlerts[1]),
        _f1Dec(totalAlerts[2]),
        _f1Dec(totalAlerts[3]),
        _f1Dec(parseFloat(_1Dec(totalAlerts[5])) + parseFloat(_1Dec(totalAlerts[6])) + parseFloat(_1Dec(totalAlerts[7])) + parseFloat(_1Dec(totalAlerts[8])) + parseFloat(_1Dec(totalAlerts[9])) + parseFloat(_1Dec(totalAlerts[10]))),
        (totalAlerts[5]),
        _f1Dec(totalAlerts[6]),
        (totalAlerts[7]),
        _f1Dec(totalAlerts[8]),
        _f1Dec(totalAlerts[9]),
        _f1Dec(totalAlerts[10])
      ])

      z = allData.length - 1
      if(app.sgs !== 'stat'){

        var isAll = !1;
        if(app.sgs === 'group'){
          if(app.goSearch){
            if(app.goSearch.indexOf('All ') >= 0){
              isAll = 1;
            }
          }else{
            if(app.sgs !== 'single' && app.sgs === 'stat'){
              isAll = 1;
            }
          }
        }

        if(!isAll){
          getGladRank({app,layers,setGladRank,activeTool})
        }
      }
      while (z>=0) {
        const data = {
          columns: allData[z].cols,
          type: 'bar',groups: [allData[z].grp],
          order: null,
          colors: {
            "Forest": 'rgba(38,115,0,1)',
            "Forest on mineral": 'rgba(38,115,0,1)',
            "Mangrove forest": 'rgba(48,255,169,1)',
            "Peat-swamp forest": 'rgba(68,137,112,1)',
            "Non-forest": 'rgba(215,215,158,1)',
          },
        };

        const axis = {x: {type: 'category',categories: allData[z].cats,
                    tick: {
                            rotate: -90,
                            color: '#fff',
                            multiline: false
                        },
                        height: 50
                      },
                    y: {show: false}
                    }

        const tooltip = {format:
                        {
                            value: function (v) {
                              return (_f1Dec(v) + ' Ha');
                            }
                        }
                    }

        const grid = {y: {show: true}}
        ReactDOM.render(<C3Chart
          data={data}
          axis={axis}
          tooltip={tooltip}
          grid={grid}
          size={{height:280}}
          />, document.getElementById(`sbgc-${z}`));

        z--;
      }


  }

}

const GladSum = ({props}) => {

  const {rLang,lang} = useSelector(s=>s.lang)
  const setApp = useDispatch()
  const [open,setOpen] = useState(!1)
  const [open2,setOpen2] = useState(!1)
  const {sumT,gladRank} = props

  return (
    <div className="rank-table">
      <div style={{width:"65%"}}>
        <span className="res-value">{sumT[0]}</span><span className="res-ha"> Ha</span>
        <div className="stat-d">
          <span className="toggle-sd" role="presentation" onClick={()=>{
            setOpen(!open)
          }}>
            <i className="fa fa-angle-right"></i> {lang ? 'Detil' : 'Detail'}
          </span>
          <Collapse isOpen={open}>
            <div className="stat-di">
              <div>
                <div>{lang ? 'Kehilangan hutan di tanah mineral' : 'Forest loss on mineral soil'}</div>
                <span className="res-value-s">{sumT[1]}</span><span className="res-ha">Ha</span>
              </div>
              <div>
                <div>{lang ? 'Kehilangan hutan bakau' : 'Mangrove forest loss'}</div>
                <span className="res-value-s">{sumT[2]}</span><span className="res-ha">Ha</span>
              </div>
              <div>
                <div>{lang ? 'Kehilangan hutan rawa gambut' : 'Peat-swamp forest loss'}</div>
                <span className="res-value-s">{sumT[3]}</span><span className="res-ha">Ha</span>
              </div>
              <div>
                <div>{lang ? 'Kehilangan pohon di-non-hutan' : 'Tree loss in non-forest'}</div>
                <span className="res-value-s">{sumT[4]}</span><span className="res-ha">Ha</span>
                <div className="stat-d">
                  <span className="toggle-sd" role="presentation" onClick={()=>{
                    setOpen2(!open2)
                  }}>
                    <i className="fa fa-angle-right"></i> {lang ? 'Detil' : 'Detail'}
                  </span>
                  <Collapse isOpen={open2}>
                    <div className="stat-di">
                      <div>
                        <div>{rLang.Oilpalm[lang]}</div>
                        <span className="res-value-s">{_f1Dec(sumT[5] + sumT[7])}</span><span className="res-ha">Ha</span>
                        <div className="stat-di stat-di2">
                          <div>
                            <div>{lang ? 'Industri' : 'Industry'}</div>
                            <span className="res-value-s">{_f1Dec(sumT[5])}</span><span className="res-ha">Ha</span>
                          </div>
                          <div>
                            <div>{lang ? 'Petani kecil' : 'Smallholder'}</div>
                            <span className="res-value-s">{_f1Dec(sumT[7])}</span><span className="res-ha">Ha</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>{rLang.Pulpwood[lang]}</div>
                        <span className="res-value-s">{sumT[6]}</span><span className="res-ha">Ha</span>
                      </div>
                      <div>
                        <div>{rLang.Mining[lang]}</div>
                        <span className="res-value-s">{sumT[8]}</span><span className="res-ha">Ha</span>
                      </div>
                      <div>
                        <div>{rLang.Transmigration[lang]}</div>
                        <span className="res-value-s">{sumT[9]}</span><span className="res-ha">Ha</span>
                      </div>
                      <div>
                        <div>{lang ? 'Selainnya' : 'Other non-forest'}</div>
                        <span className="res-value-s">{sumT[10]}</span><span className="res-ha">Ha</span>
                      </div>
                    </div>
                  </Collapse>
                </div>

              </div>
            </div>
          </Collapse>
        </div>
      </div>

      {
        gladRank ? (
          <div style={{borderLeft:'1px solid #ccc',paddingLeft:10}}>
            <div className="see-rank">Top {gladRank.arearank} of {gladRank.totalcount}
              <small role="presentation" onClick={()=>{
                setApp({type:'_DATA',payload:['rankList',{
                  type:'alert',
                  rank: gladRank.arearank
                }]})
                setApp({type:'_POPUP',payload:['popup','ranklist']})
              }}>{lang ? 'Lihat semuanya' : 'View complete list'}</small>
            </div>
          </div>
        ) : null
      }

    </div>
  )
}

const GladTab = () => {

  const app = useSelector(s=>s.app)
  const {rLang,lang} = useSelector(s=>s.lang)
  const {mapLoader} = useSelector(s=>s.loader)
  const {activeTool} = useSelector(s=>s.tool)
  const {activeTab} = useSelector(s=>s.tab)
  const {gladTabData,featData} = useSelector(s=>s.dat)
  const {dpS,dpE,gladStartDate,gladEndDate,gladUnconfirm} = useSelector(s=>s.glad)
  const {layers,gladRaster} = useSelector(s=>s.layers)
  const setApp = useDispatch()
  const slick = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ChevronBack size="34"/>,
    nextArrow: <ChevronForward size="34"/>,
  };
  let show = activeTab === 'glad' ? 1 : !1

  const [sumT,setSumT] = useState([0,0,0,0,0,0,0,0,0,0,0])
  const [gladRank,setGladRank] = useState(0)

  useEffect(()=>{
    if(gladTabData.single || gladTabData.group){
      setGladChart({app,gladStartDate,gladEndDate,layers,setGladRank,activeTool,setSumT,gladTabData})
    }
    return () => {
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[gladTabData.single,gladTabData.group])

  useEffect(()=>{
    if(show){
      if(document.querySelector('.ol-overviewmap')){
        if(document.querySelector('.ol-overviewmap').className.indexOf('ol-collapsed') === -1 ){
          document.querySelector('.ol-overviewmap button').click()
        }
      }
    }
  },[show])

  return (featData.single || featData.group) && show ? (
    <div className="tab-pane" style={{display:`${show ? 'block' : 'none'}`,height:activeTool.indexOf('aoi') === 0 ? 'calc(100vh - 175px)' : ''}} id="glad-panel">
      <h2 className="tab-label">{rLang.TabGladHead[lang]}</h2>
      <TabHead/>
      <div className="sbbody">
        {/* eslint-disable-line jsx-a11y/control-has-associated-label */}<h3 className="sbbody-title">Tree loss {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
          showInfo(setApp,'popupglad/i0')
        }}><InfoCircle size="12"/></b></h3>

        {
          (gladTabData.single || gladTabData.group) ? (
            <>
            <div className="row">
              <div className="col-xs-6" style={{paddingRight: 0}}>
                <table style={{width:'100%'}}>
                  <tbody><tr>
                    <td style={{paddingRight: 10,paddingTop: 3}}><label htmlFor="glad-from">From</label></td>
                    <td><DatePicker
                    selected={parseISO(gladStartDate)}
                    onChange={date => {
                      setApp({type:'_GLAD',payload:['gladStartDate',moment(date).format('YYYY-MM-DD')]})
                      gladRaster.changed()
                    }}
                    minDate={parseISO(dpS)}
                    maxDate={parseISO(dpE)}
                    dateFormat='yyyy-MM-dd'
                    popperPlacement="bottom-start"
                    id="glad-from"
                    onBlur={()=>{
                      setTimeout(()=>{
                        if(gladTabData.single || gladTabData.group)setGladChart({app,gladStartDate,gladEndDate,layers,setGladRank,activeTool,setSumT,gladTabData})
                      },50)
                    }}
                    onSelect={()=>{
                      setTimeout(()=>{
                        if(gladTabData.single || gladTabData.group)setGladChart({app,gladStartDate,gladEndDate,layers,setGladRank,activeTool,setSumT,gladTabData})
                      },50)
                    }}
                    /></td>
                  </tr></tbody>
                </table>
              </div>
              <div className="col-xs-6">
                <table style={{width:'100%'}}>
                  <tbody><tr>
                    <td style={{paddingRight: 10,paddingTop: 3}}><label htmlFor="glad-to">To</label></td>
                    <td><DatePicker
                    selected={parseISO(gladEndDate)}
                    onChange={date => {
                      setApp({type:'_GLAD',payload:['gladEndDate',moment(date).format('YYYY-MM-DD')]})
                      gladRaster.changed()
                    }}
                    minDate={parseISO(dpS)}
                    maxDate={parseISO(dpE)}
                    dateFormat='yyyy-MM-dd'
                    popperPlacement="bottom-end"
                    id="glad-to"
                    onSelect={()=>{
                      setTimeout(()=>{
                        if(gladTabData.single || gladTabData.group)setGladChart({app,gladStartDate,gladEndDate,layers,setGladRank,activeTool,setSumT,gladTabData})
                      },50)
                    }}
                    onBlur={()=>{
                      setTimeout(()=>{
                        if(gladTabData.single || gladTabData.group)setGladChart({app,gladStartDate,gladEndDate,layers,setGladRank,activeTool,setSumT,gladTabData})
                      },50)
                    }}
                    /></td>
                  </tr></tbody>
                </table>
              </div>
            </div>

            <ReactSlider
              className="horizontal-slider"
              thumbClassName="s-thumb"
              trackClassName="s-track"
              min={DateToJD(dpS)}
              max={DateToJD(dpE)}
              value={[DateToJD(gladStartDate), DateToJD(gladEndDate)]}
              ariaLabel={['Lower thumb', 'Upper thumb']}
              ariaValuetext={t => `Thumb value ${t.valueNow}`}
              renderThumb={(props, t) => <div {...props}></div>}
              pearling
              minDistance={10}
              onChange={(e)=>{
                batch(()=>{
                  setApp({type:'_GLAD',payload:['gladStartDate',moment(JDtoDate(e[0])).format('YYYY-MM-DD')]})
                  setApp({type:'_GLAD',payload:['gladEndDate',moment(JDtoDate(e[1])).format('YYYY-MM-DD')]})
                })
                gladRaster.changed()
              }}
              onAfterChange={(e)=>{
                if(gladTabData.single || gladTabData.group){
                  setGladChart({
                    app,gladStartDate:moment(JDtoDate(e[0])).format('YYYY-MM-DD'),
                    gladEndDate:moment(JDtoDate(e[1])).format('YYYY-MM-DD'),
                    layers,setGladRank,activeTool,setSumT,gladTabData})
                }
              }}
            />

            <div id="pixelVal" className="concession-popup-title"></div>
            <div id="sidebar-glad-slider">
              <div data-dojo-type="dijit/form/HorizontalRule"></div>
              <div id="pixelLabels"></div>
              <div id="sidebar-glad-slider-date"></div>
            </div>

            <div id="glad-sum" className="concession-summary">
              <GladSum props={{sumT,gladRank}}/>
            </div>

            <div id="glad-checkbox" className="form-group form-check checkbox">
              <input type="checkbox" className="form-check-input" id="glad-check" checked={gladUnconfirm} onChange={(e)=>{
                setApp({type:'_GLAD',payload:['gladUnconfirm',!gladUnconfirm]})
                setGladChart({app,gladStartDate,gladEndDate,layers,setGladRank,activeTool,setSumT,gladTabData})
                gladRaster.changed()
              }}/>
              <label className="form-check-label" htmlFor="glad-check">Include unconfirmed clearing?</label>
            </div>

            <Slider {...slick}>
              <div>
                <h5 className="concession-popup-title"><b>Tree loss</b>{/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                  showInfo(setApp,'popupglad/i1')
                }}><InfoCircle size="12"/></b></h5>
                <div id="sbgc-0"></div>
              </div>
              <div>
                <h5 className="concession-popup-title"><b>Forest loss on mineral soil</b>{/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                  showInfo(setApp,'popupglad/i2')
                }}><InfoCircle size="12"/></b></h5>
                <div id="sbgc-1"></div>
              </div>
              <div>
                <h5 className="concession-popup-title"><b>Mangrove forest loss</b>{/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                  showInfo(setApp,'popupglad/i3')
                }}><InfoCircle size="12"/></b></h5>
                <div id="sbgc-2"></div>
              </div>
              <div>
                <h5 className="concession-popup-title"><b>Peat-swamp forest loss</b>{/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                  showInfo(setApp,'popupglad/i4')
                }}><InfoCircle size="12"/></b></h5>
                <div id="sbgc-3"></div>
              </div>
            </Slider>

          </>) : (<div><img width="30" height="30" alt="" src={rippleGIF}/> <i>Loading . . .</i></div>)
        }
      </div>
    </div>
  ) : null

}
export default GladTab
