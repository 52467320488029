import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import LangReducer from './reducers/langReducer';
import AppReducer from './reducers/appReducer';
import MapReducer from './reducers/mapReducer';
import BaseMapReducer from './reducers/baseMapReducer';
import ALayersReducer from './reducers/aLayersReducer';
import LayersReducer from './reducers/layersReducer';
import ToolReducer from './reducers/toolReducer';
import ToolScreenReducer from './reducers/toolScreenReducer';
import TabReducer from './reducers/tabReducer';
import DataReducer from './reducers/dataReducer';
import PopupReducer from './reducers/popupReducer';
import PopupLargeReducer from './reducers/popupLargeReducer';
import PopupOnMapReducer from './reducers/popupOnMapReducer';
import StaticReducer from './reducers/staticReducer';
import LoaderReducer from './reducers/loaderReducer';
import TsReducer from './reducers/tsReducer';
import GladReducer from './reducers/gladReducer';
import RiskReducer from './reducers/riskReducer';
import SearchReducer from './reducers/searchReducer';
import ASearchReducer from './reducers/aSearchReducer';
import AvsReducer from './reducers/avsReducer';
import WelcomeReducer from './reducers/welcomeReducer';
import ShareReducer from './reducers/shareReducer';
import DashboardReducer from './reducers/dashboardReducer';
import AuthReducer from './reducers/authReducer';
import AoiReducer from './reducers/aoiReducer';

// preloadedState will be passed in by the plugin
const Store = createStore(
    combineReducers({
      lang: LangReducer,
      app: AppReducer,
      map: MapReducer,
      bMap: BaseMapReducer,
      aLayers: ALayersReducer,
      layers: LayersReducer,
      tool: ToolReducer,
      toolScreen: ToolScreenReducer,
      tab: TabReducer,
      dat: DataReducer,
      popup: PopupReducer,
      popupLarge: PopupLargeReducer,
      popupOnMap: PopupOnMapReducer,
      static: StaticReducer,
      loader: LoaderReducer,
      ts: TsReducer,
      glad: GladReducer,
      risk: RiskReducer,
      search: SearchReducer,
      aSearch: ASearchReducer,
      avs: AvsReducer,
      welcome: WelcomeReducer,
      share: ShareReducer,
      dashboard: DashboardReducer,
      auth: AuthReducer,
      aoi: AoiReducer
    }),
    applyMiddleware(thunk)
  );
export default Store
