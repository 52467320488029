import React, {useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import axios from 'axios'

import {setInitData} from '../../actions/initAction'
import {isPin,togglePin} from '../../helpers'
import config from '../../config'

const setStart = ({setApp,defaultLayers,layers,tool,map,aoiLayerResult}) => {
  if(aoiLayerResult){
    map.removeLayer(aoiLayerResult)
  }
  setApp(setInitData({defaultLayers,layers,tool}))
  if(!isPin()){
    togglePin(map)
  }
}

const TSroad = () => {
  const {map} = useSelector(s=>s.map)
  const toolScreen = useSelector(s=>s.toolScreen)
  const setApp = useDispatch()
  const { layers, defaultLayers } = useSelector(s=>s.layers);
  const {rLang, lang} = useSelector(s=>s.lang)
  const {aoiLayerResult} = useSelector(s=>s.aoi)

  useEffect(()=>{
    if(map && typeof document !== undefined){
      /*Road*/
      axios.get(`${config.apiP1}${layers.road.sLayer}?select=count'`)
      .then(function(r){
        document.querySelector('#sum-r .sum-t > div').innerText = r.data[0].count;
      });
    }
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[map])

  return (
    <>
      <div id="tool-info-mill" className="tool-info" style={toolScreen.tool !== 'road' ? css.hide : css.show}>
        <div className="tool-bg"/>
        <div className="position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                <div className="tool-info-title">
                  {rLang.ToolRTitle[lang]}
                </div>

                <div id="sum-r" className="sum-d">
                  <div className="sum-t">
                    <div></div>
                    <span>{rLang.Roads[lang]}</span>
                  </div>
                </div>

                <div className="tool-info-desc">
                  {rLang.ToolRDesc[lang]}
                </div>
                <button onClick={()=>{
                  setStart({setApp,tool:'road',layers,defaultLayers,map,aoiLayerResult})
                }} className="start">{rLang.StartTool[lang]}</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const css = {
  show: {
    opacity:1,
    zIndex:1,
    height:'auto',
    overflow:'auto'
  },
  hide: {
    opacity:0,
    zIndex:-1,
    height:0,
    overflow:'hidden'
  }
}

export default TSroad
