import React from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import {Sliders} from '@styled-icons/bootstrap'
import {Dashboard2} from '@styled-icons/remix-line'
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import conIcon from '../../images/icons/01.svg';
import millIcon from '../../images/icons/04.svg';
import paIcon from '../../images/icons/02.svg';
import adminIcon from '../../images/icons/06.svg';
import roadIcon from '../../images/icons/05.svg';
import luzIcon from '../../images/icons/LUZ.svg';
import aoiIcon from '../../images/icons/AoI.svg';
import addIcon from '../../images/icons/icon-addlayer.png';
import bmapIcon from '../../images/icons/icon-basemap.png';

import imgToolCon from '../../images/tools/Tools-P2-CIFOR.jpg'
import imgToolMill from '../../images/tools/Tools-P9-CIFOR.jpg'
import imgToolPa from '../../images/tools/Tools-P13-CIFOR.jpg'
import imgToolAdmin from '../../images/tools/Tools-P15-CIFOR.jpg'
import imgToolRoad from '../../images/landing/Landing-P7-P19-CIFOR.jpg'
import imgToolLuz from '../../images/tools/Tools-P15-CIFOR.jpg'
import imgToolAoi from '../../images/landing/Landing-P7-P19-CIFOR.jpg'

import {togglePin,isPin} from '../../helpers'

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#000',
  },
  tooltip: {
    backgroundColor: '#000',
    fontSize: 12
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const setToolScreen = ({tool,bg,map,app,setApp,toolScreen}) => {
  if(isPin() && toolScreen.tool !== tool){
    togglePin(map,0)
  }
  if(tool === 'dashboard'){
    setApp({type:'_DASHBOARD',payload: ['show',1]})
    batch(()=>{
      if(app.single || app.goSearch || app.aSearch){
        setApp({type:'INIT_AVS'})
        setApp({type:'INIT_DATA'})
        setApp({type:'INIT'})
      }
      setApp({type:'SET_TOOL_SCREEN',payload: {tool: null,bg:''}})
    })
  }else{
    batch(()=>{
      if(app.single || app.goSearch || app.aSearch){
        setApp({type:'INIT_AVS'})
        setApp({type:'INIT_DATA'})
        setApp({type:'INIT'})
      }
      setApp({type:'SET_TOOL_SCREEN',payload: {tool:tool === toolScreen.tool ? null : tool,bg}})
      setApp({type:'_DASHBOARD',payload: ['show',!1]})
    })
  }

}

const ToolMenu = () => {

  const app = useSelector(s=>s.app)
  const {rLang,lang} = useSelector(s=>s.lang)
  const toolScreen = useSelector(s=>s.toolScreen)
  const {map} = useSelector(s=>s.map)
  const setApp = useDispatch()

  return (
    <div id="sb-menu">
      <div id="sb-menu-inner">
        <div id="sb-menu-top">
          <BootstrapTooltip title={`Dashboard`} placement="right">
            {/*<div role="presentation" id="menu-dashboard" onClick={() => {
              setToolScreen({tool:'dashboard',bg:'',
              map,app,setApp,toolScreen})
            }} className="sb-menu sb-menu-tools" style={{background:'#1f3642',paddingTop:8,height:50}}>
              <Dashboard2 size="36" style={{color:'#fff'}}/>
            </div>*/}
            <div role="presentation" id="menu-dashboard" onClick={() => {
              setToolScreen({tool:'dashboard',bg:'',
              map,app,setApp,toolScreen})
            }} className="sb-menu sb-menu-tools" style={{paddingTop:8}}>
              <Dashboard2 size="36" style={{color:'#fff'}}/>
            </div>
          </BootstrapTooltip>
          <h6>{rLang.TOOLS[lang]}</h6>
          <BootstrapTooltip title={rLang.Concessions[lang]} placement="right">
            <div role="presentation" id="menu-land-use" onClick={() => {
              setToolScreen({tool:'concession',bg:imgToolCon,
              map,app,setApp,toolScreen})
            }} className="sb-menu sb-menu-tools">
              <img alt="" src={conIcon}/>
            </div>
          </BootstrapTooltip>
          <BootstrapTooltip title={rLang.NearMills[lang]} placement="right">
            <div role="presentation" id="menu-near-mill" onClick={() => {
              setToolScreen({tool:'mill',bg:imgToolMill,
              map,app,setApp,toolScreen})
            }} className="sb-menu sb-menu-tools">
              <img alt="" src={millIcon}/>
            </div>
          </BootstrapTooltip>
          <BootstrapTooltip title={rLang.ProtectedArea[lang]} placement="right">
            <div role="presentation" id="menu-protected-area" onClick={() => {
              setToolScreen({tool:'protected_area',bg:imgToolPa,
              map,app,setApp,toolScreen})
            }} className="sb-menu sb-menu-tools">
              <img alt="" src={paIcon}/>
            </div>
          </BootstrapTooltip>
          <BootstrapTooltip title={rLang.AdministrativeBoundary[lang]} placement="right">
            <div role="presentation" id="menu-boundary" onClick={() => {
              setToolScreen({tool:'admin',bg:imgToolAdmin,
              map,app,setApp,toolScreen})
            }} className="sb-menu sb-menu-tools">
              <img alt="" src={adminIcon}/>
            </div>
          </BootstrapTooltip>
          <BootstrapTooltip title={rLang.NearRoads[lang]} placement="right">
            <div role="presentation" id="menu-road" onClick={() => {
              setToolScreen({tool:'road',bg:imgToolRoad,
              map,app,setApp,toolScreen})
            }} className="sb-menu sb-menu-tools">
              <img alt="" src={roadIcon}/>
            </div>
          </BootstrapTooltip>
          <BootstrapTooltip title={rLang.LandUseZone[lang]} placement="right">
            <div role="presentation" id="menu-luz" onClick={() => {
              setToolScreen({tool:'luz',bg:imgToolLuz,
              map,app,setApp,toolScreen})
            }} className="sb-menu sb-menu-tools">
              <img alt="" src={luzIcon}/>
            </div>
          </BootstrapTooltip>
          <BootstrapTooltip title={rLang.AreaofInterest[lang]} placement="right">
            <div role="presentation" id="menu-udraw" onClick={() => {
              setToolScreen({tool:'aoi',bg:imgToolAoi,
              map,app,setApp,toolScreen})
            }} className="sb-menu sb-menu-tools">
              <img alt="" src={aoiIcon}/>
            </div>
          </BootstrapTooltip>

        </div>
        <div id="sb-menu-bottom">
          <div id="menu-show-sb" className="sb-menu-tools text-center m-only" onClick={()=>{
            togglePin(map)
          }}>
            <Sliders size="26" style={{color:'#fff'}}/>
          </div>
          <div role="presentation" id="menu-add-layer" className="sb-menu bottom-menu" onClick={()=>{
            batch(()=>{
              setApp({type:'_POPUP',payload:['popup','layers']})
              setApp({type:'_DASHBOARD',payload: ['show',!1]})
            })
          }}>
            <img alt="" src={addIcon}/>
            <h5>{rLang.ADD_MAP[lang]}</h5>
          </div>
          <div role="presentation" id="menu-base-map" className="sb-menu bottom-menu" onClick={()=>{
            batch(()=>{
              setApp({type:'_POPUP',payload:['popup','basemaps']})
              setApp({type:'_DASHBOARD',payload: ['show',!1]})
            })
          }}>
            <img alt="" src={bmapIcon} className="width-100"/>
            <h5>{rLang.BASE_MAP[lang]}</h5>
          </div>
          {/*
          <div id="cifor-link">
            <a href="https://www.cifor.org/" target="_blank">
              <img src="<?php echo base_url();?>assets/images/CIFOR-white.png"/>
            </a>
          </div>
          */}
        </div>
      </div>
    </div>
  )
}
export default ToolMenu
