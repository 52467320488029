import React,{useEffect,useRef,useState} from 'react'
import {useSelector,useDispatch,batch} from 'react-redux'
import Slider from "react-slick";
import {ChevronForward, ChevronBack} from '@styled-icons/ionicons-outline'
import {InfoCircle} from '@styled-icons/fa-solid'

import SbPieChart from './SbPieChart'
import SbBarChart from './SbBarChart'
import DownloadBar from './DownloadBar'

import rippleGIF from '../../images/ripple.gif'

const showInfo = (setApp,url) => {
  batch(()=>{
    setApp({type:'_POPUP',payload:['popup','popupinfo']})
    setApp({type:'_POPUP',payload:['url',url]})
  })
}

const TsCharts = () => {

  const setApp = useDispatch()
  const {featData,tsTabData} = useSelector(s=>s.dat)
  const {y} = useSelector(s=>s.ts)
  const {activeTab} = useSelector(s=>s.tab)
  const {rLang,lang} = useSelector(s=>s.lang)
  const {goSearch,single,sgs} = useSelector(s=>s.app)
  const [showSlide,setShowSlide] = useState(1)
  const sli = useRef()
  const slick = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: <ChevronBack size="34"/>,
    nextArrow: <ChevronForward size="34"/>,
  };

  useEffect(()=>{
    setTimeout(()=>{
      setShowSlide(1)
    },10)
    return () => {setShowSlide(0)}
  },/*eslint-disable-line react-hooks/exhaustive-deps*/[activeTab])

  const f = featData.single || featData.group
  // return useMemo(()=>{
    return f && activeTab === 'timelapse' ? (
      <>
        {showSlide ? (<SbPieChart d={tsTabData.single || tsTabData.group} area={f.areaha}/>) : (<div style={{marginLeft:15}}><img width="30" height="30" alt="" src={rippleGIF}/> <i>Loading . . .</i></div>)}
        <div id="sb-slide-chart">
          {
            !showSlide ? null : (
              <>
              <SbPieChart d={tsTabData.single || tsTabData.group} area={f.areaha}/>
              <Slider ref={sli} {...slick}>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc2[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i1')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-total-forest'} barColor="aaff00"/>
                  </h4>
                  <div className="pad">
                    <SbBarChart chartID={'sb-total-forest'} d={tsTabData.single || tsTabData.group} dl={{single,sgs,goSearch,featData,cInfo:0}}/>
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc3[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i2')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-soil'} barColor="267300"/>
                  </h4>
                  <div className="pad">
                    <SbBarChart chartID={'sb-soil'} d={tsTabData.single || tsTabData.group} dl={{single,sgs,goSearch,featData,cInfo:1}}/>
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc4[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i3')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-mangrove'} barColor="30ffa9"/>
                  </h4>
                  <div className="pad">
                    <SbBarChart chartID={'sb-mangrove'} d={tsTabData.single || tsTabData.group} dl={{single,sgs,goSearch,featData,cInfo:2}}/>
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc5[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i4')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-peat'} barColor="448970"/>
                  </h4>
                  <div className="pad">
                    <SbBarChart chartID={'sb-peat'} d={tsTabData.single || tsTabData.group} dl={{single,sgs,goSearch,featData,cInfo:3}}/>
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc6[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i5')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-oilpalm'}/>
                  </h4>
                  <div className="pad">
                    {<SbBarChart chartID={'sb-oilpalm'} d={tsTabData.single || tsTabData.group} stacked={1} dl={{single,sgs,goSearch,featData,cInfo:4}}/>}
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc13[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i12')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-smallholder'}/>
                  </h4>
                  <div className="pad">
                    {<SbBarChart chartID={'sb-smallholder'} d={tsTabData.single || tsTabData.group} stacked={1} dl={{single,sgs,goSearch,featData,cInfo:5}}/>}
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc7[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i6')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-pulpwood'}/>
                  </h4>
                  <div className="pad">
                    {<SbBarChart chartID={'sb-pulpwood'} d={tsTabData.single || tsTabData.group} stacked={1} dl={{single,sgs,goSearch,featData,cInfo:5}}/>}
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc8[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i7')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-mining'}/>
                  </h4>
                  <div className="pad">
                    {<SbBarChart chartID={'sb-mining'} d={tsTabData.single || tsTabData.group} stacked={1} dl={{single,sgs,goSearch,featData,cInfo:6}}/>}
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc9[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i8')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-mainRoad'} barColor="000000"/>
                  </h4>
                  <div className="pad">
                  <SbBarChart chartID={'sb-mainRoad'} d={tsTabData.single || tsTabData.group} dl={{single,sgs,goSearch,featData,cInfo:0}}/>
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc10[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i9')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-otherRoad'} barColor="000000"/>
                  </h4>
                  <div className="pad">
                    <SbBarChart chartID={'sb-otherRoad'} d={tsTabData.single || tsTabData.group} dl={{single,sgs,goSearch,featData,cInfo:8}}/>
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc11[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i10')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-plaRoad'} barColor="000000"/>
                  </h4>
                  <div className="pad">
                    <SbBarChart chartID={'sb-plaRoad'} d={tsTabData.single || tsTabData.group} dl={{single,sgs,goSearch,featData,cInfo:9}}/>
                  </div>
                </div>
                <div>
                  <h4 className="sb-chart-title">
                    <b>{rLang.sbc12[lang]} {y} (Ha) [{single ? f.name : goSearch || 'Statistics of result'}]</b>
                    {/*eslint-disable-line jsx-a11y/control-has-associated-label*/}<b role="presentation" className="tab-info" onClick={()=>{
                      showInfo(setApp,'popupts/i11')
                    }}><InfoCircle size="14"/></b>
                    <DownloadBar chartID={'sb-logRoad'} barColor="000000"/>
                  </h4>
                  <div className="pad">
                    <SbBarChart chartID={'sb-logRoad'} d={tsTabData.single || tsTabData.group} dl={{single,sgs,goSearch,featData,cInfo:10}}/>
                  </div>
                </div>
              </Slider>
              </>
            )
          }
        </div>


      </>
    ) : null
  // },/*eslint-disable-line react-hooks/exhaustive-deps*/[tsTabData.single,tsTabData.group,activeTab,y])

}

export default TsCharts
