import React,{useState} from 'react'
import {useSelector} from 'react-redux'

import Collapse from "@kunukn/react-collapse";

import TabHead from './TabHead'
import rippleGIF from '../../images/ripple.gif'

import {_fNDec} from '../../helpers'

const S73List = ({d}) => {
  const [open,setOpen] = useState(0)
  return (
    <div className="sbbody-li">
      <h3 className="sbbody-title">{d.title}</h3>
      <div className="rank-table">
        <div>
          <span className="res-value">{d.data.area}</span>
          <span className="res-ha"> Ha</span>
          {
            d.data.bd && (
              <div className="stat-d">
                <span role="presentation" onClick={()=>{
                  setOpen(open ? 0 : 1)
                }}>
                  <i className="fa fa-angle-right"></i> {d.lang ? 'Detil' : 'Detail'}
                </span>
                <Collapse isOpen={open}>
                  <div className="stat-di">
                    {
                      d.data.bd.map((dt,i)=>{

                        return (
                          <div key={`${dt.t}${i}`}>
                            <div>{dt.t}</div>
                            <span className="res-value-s">{dt.d}</span><span className="res-ha">Ha</span>
                            {
                              dt.bd2 && (
                                <div className="stat-di stat-di2">
                                  {
                                    dt.bd2.map((dt2,i)=>{
                                      return (
                                        <div key={`${dt2.t}2${i}`}>
                                          <div>{dt2.t}</div>
                                          <span className="res-value-s">{dt2.d}</span><span className="res-ha">Ha</span>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                )
                              }
                          </div>
                        )
                      })
                    }
                  </div>
                </Collapse>
              </div>
            )
          }
        </div>
        {
          d.data.rank && (
            <div style={{borderLeft:'1px solid #ccc',paddingTop:5,addingLeft:10}}>
              <div id={d.id} className="see-rank">{d.data.rank}
                <small>View complete list</small>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )

}

const S73Tab = () => {

  const {single} = useSelector(s=>s.app)
  const {rLang,lang} = useSelector(s=>s.lang)
  const {activeTool} = useSelector(s=>s.tool)
  const {activeTab} = useSelector(s=>s.tab)
  const {_73TabData} = useSelector(s=>s.dat)

  const data = single ? _73TabData.single : _73TabData.group

  return (
    <div style={{display:`${activeTab === 'deforestation' ? 'block' : 'none'}`,height:activeTool.indexOf('aoi') === 0 ? 'calc(100vh - 175px)' : ''}} className="tab-pane" id="deforestation-panel">
      <h2 className="tab-label">{rLang.Tab73Head[lang]}</h2>
      <TabHead/>
      <div className="sbbody">
        {
          !data ? (<div><img width="30" height="30" alt="" src={rippleGIF}/> <i>Loading . . .</i></div>) : (
            <>
            <S73List d={{
              lang,id: 's731',
              title:rLang.sbStat1[lang],
              data:{
                area:_fNDec(data.d[0][0]),
                bd: [
                  {
                    t:lang ? 'Hutan di tanah mineral' : 'Forest on mineral soil',
                    d:_fNDec(data.d[0][1])
                  },
                  {
                    t:lang ? 'Hutan bakau' : 'Mangrove forest',
                    d:_fNDec(data.d[0][2])
                  },
                  {
                    t:lang ? 'Hutan rawa gambut' : 'Peat-swamp forest',
                    d:_fNDec(data.d[0][3])
                  }
                ],
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.remforestrank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.remforestrank} dari ${data.rank.totalcount}`
              }
            }}/>
            <S73List d={{
              lang,id: 's732',
              title:rLang.sbStat5[lang],
              data:{
                area:_fNDec(data.d[1][0]),
                bd: [
                  {
                    t:rLang.Oilpalm[lang],
                    d:_fNDec(data.d[1][1]+data.d[1][3]),
                    bd2: [
                      {
                        t: lang ? 'Industri' : 'Industry',
                        d:_fNDec(data.d[1][1]),
                      },
                      {
                        t: lang ? 'Petani kecil' : 'Smallholder',
                        d:_fNDec(data.d[1][3]),
                      }
                    ]
                  },
                  {
                    t:rLang.Pulpwood[lang],
                    d:_fNDec(data.d[1][2]),
                  }
                ],
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.existingiprank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.existingiprank} dari ${data.rank.totalcount}`
              }
            }}/>
            <S73List d={{
              lang,id: 's733',
              title:rLang.sb733[lang],
              data:{
                area:_fNDec(data.d[2]),
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.ipfr2000rank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.ipfr2000rank} dari ${data.rank.totalcount}`
              }
            }}/>
            <S73List d={{
              lang,id: 's734',
              title:rLang.sb734[lang],
              data:{
                area:_fNDec(data.d[3]),
                rank: !data.rank ? null : lang === 0 ? `Top ${data.rank.ipfr2000rank} of ${data.rank.totalcount}` : `Peringkat ${data.rank.ipfr2000rank} dari ${data.rank.totalcount}`
              }
            }}/>
            </>
          )
        }
      </div>
    </div>
  )

}
export default S73Tab
