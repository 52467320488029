import React from 'react'
import {useSelector} from 'react-redux'
import {AngleRight} from '@styled-icons/fa-solid'

import {togglePin} from '../../helpers'

const PinIcon = () => {
  const {map} = useSelector(s=>s.map)
  return (
    <span role="presentation" id="pinned" className="pinned" onClick={
      () => {
        togglePin(map)
      }
    }><AngleRight className="d-angle"/></span>
  )
}
export default PinIcon
